import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import DatFromDatTo from '../../datFromDatTo';
import { NumberBox } from 'devextreme-react/number-box';
import { SelectBox } from 'devextreme-react';

const typeSelectStore = [
  {
    id: 1,
    name: "загальне максимальне"
  },
  {
    id: 2,
    name: "загальне середнє"
  },
  {
    id: 3,
    name: "середнє від 3-х максимальних"
  }
];

class OfficeFuelReportFuelFeedRate extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          typeSelect : 1,
          minAmount : 30,
          minSeconds : 60
        }              
    }

    onChangeDates(e) {
      this.setState({
          dateFrom : e.dateFrom,
          dateTo : e.dateTo             
      });
    }

  onChangeMinAmount(e) {
      this.setState({
        minAmount : e.value
      });
  }

  onChangeMinSeconds(e) {
    this.setState({
      minSeconds : e.value
    });
}

  onChangeTypeSelect(e) {
    this.setState({
      typeSelect : e.value
    });
  }

    onExecute() {

        let _dateFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _dateTo = Moment(this.state.dateTo).format('YYYY-MM-DD');        
        let _fileName = 'ReportFuelFeedRate_' + _dateFrom + '_' + _dateTo + '.xlsx';

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelFeedRate?dateFrom=' + _dateFrom + '&dateTo=' + _dateTo +
            '&selectedIndex=' + this.state.typeSelect + '&minAmount=' + this.state.minAmount + '&minSec=' + this.state.minSeconds,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {         
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    render() { 
    
      return (
          
        <div style={{paddingTop : "10px"}}>
            <div className="container-with-date" >
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
                <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>тип підрахунку</span>                                      
                      <SelectBox
                        ref={item => this.selbox = item}        
                        value={this.state.typeSelect}                        
                        valueExpr="id"                                        
                        deferRendering={false}     
                        width="12rem"               
                        displayExpr="name"
                        dataSource={typeSelectStore}
                        onValueChanged={this.onChangeTypeSelect.bind(this)} />      
                  </div>
                </div>
                <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>к-ть сек, від</span>
                      <NumberBox
                        ref={(ref) => this.tbMinSeconds = ref} width="4rem"
                        defaultValue={this.state.minSeconds}
                        showSpinButtons={true}
                        placeholder="вкажіть..."
                        onValueChanged={this.onChangeMinSeconds.bind(this)} />                      
                  </div>
                </div>
                <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>к-ть літрів, від</span>
                      <NumberBox
                        ref={(ref) => this.tbMinAmount = ref} width="4rem"
                        defaultValue={this.state.minAmount}
                        showSpinButtons={true}
                        placeholder="вкажіть..."
                        onValueChanged={this.onChangeMinAmount.bind(this)} />                      
                  </div>
                </div>   
            </div>
            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
            </div>

        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeFuelReportFuelFeedRate);