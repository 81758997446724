import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth';
import user from './user';
import settings from './settings';
import userTabs from './user-tabs';
import profileTabs from './profile-tabs';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  user,
  settings,
  userTabs,
  profileTabs
})
export default createRootReducer