import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, Editing, Popup, Form, ColumnChooser } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import Moment from 'moment';
import { Button } from 'devextreme-react/button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { CheckBox } from 'devextreme-react/check-box';

class OfficeGoodsReportSalesWine extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : [],
          isShowListWines : false,
          dataGridWines: [],
          onlyLoyalty : 0
        }
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoyaltyValueChanged(e) {
      this.setState({
        onlyLoyalty: e.value ? 1 : 0,
      });
    }

    loadListWines() {
      this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/WineFilter',
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
            dataGridWines: response.data
          });
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {         
        let _datFrom = Moment(this.state.dateFrom).format('yyyy-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('yyyy-MM-DD');
        let _sel = this.state.onlyLoyalty;

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGoods/ReportSalesWine?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&sel=' + _sel,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onShowListWines() {
      this.setState({
        isShowListWines : true
      });
      this.loadListWines();
    }

    onCloseListWines() {
      this.setState({
        isShowListWines : false
      });
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }}, {
            location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'edit',
              text: 'Редагувати список',            
              onClick: this.onShowListWines.bind(this),
              hint: 'Редагувати список'
            }});
      }

    toggleModal() {
      if(this.state.isShowListWines) {
        this.onCloseListWines();
      }
    }

    onRowInserting(e) {
      this.props.onLoading(true);
    
      const isCanceled = new Promise((resolve, reject) => {
        axios.put(configApp.API_CORE + '/WineFilter', e.data,
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.loadListWines();
        resolve(false);
        })
      .catch((error) => {
        this.props.onLoading(false);
          if (error.response && error.response.data.err_descr) {   
              notify(error.response.data.err_descr, 'error');
            }
          else {
              notify('Не вдалося зберегти дані', 'error');               
          }
          resolve(true);
        });
      });
    
      e.cancel = isCanceled;
    }

    onRowUpdating(e) {

      let data = e.oldData;
      if(e.newData.articleId) { data.articleId = e.newData.articleId; }
      if(e.newData.bottle) { data.bottle = e.newData.bottle; }

      this.props.onLoading(true);
    
      const isCanceled = new Promise((resolve, reject) => {
        axios.post(configApp.API_CORE + '/WineFilter', data,
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.loadListWines();
        resolve(false);
        })
      .catch((error) => {
        this.props.onLoading(false);
          if (error.response && error.response.data.err_descr) {   
              notify(error.response.data.err_descr, 'error');
            }
          else {
              notify('Не вдалося зберегти дані', 'error');               
          }
          resolve(true);
        });
      });
    
      e.cancel = isCanceled;

    }

    onRowRemoving(e) {
      this.props.onLoading(true);

      const isCanceled = new Promise((resolve) => {
        axios.delete(configApp.API_CORE + '/WineFilter?wineId=' + e.data.id,    
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.props.onLoading(false);
        resolve(false);
        })
      .catch((error) => {
        this.props.onLoading(false);
          if (error.response && error.response.data.err_descr) {   
              notify(error.response.data.err_descr, 'error');
            }
          else {
              notify('Не вдалося видалити рядок', 'error');               
          }
          resolve(true);
        });
      });
      e.cancel = isCanceled;
    }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
              <div id="datFromdatTo" style={{marginBottom: "5px", marginLeft: "15px"}}>
                <CheckBox defaultValue={false} onValueChanged={this.onLoyaltyValueChanged.bind(this)} text="продажі тільки з КЛ" />
              </div>
            </div>
            
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportSalesWines"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportSalesWines_' + _dateFrom + '_' + _datTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportSalesWines" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <ColumnChooser enabled={true} /> 
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="terminalName" caption="Назва АЗК" />   
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="articleId" caption="Код" dataType="number" />
              <Column dataField="articleName" caption="Найменування" />   
              <Column dataField="amount" caption="Кількість" dataType="number" />
              <Column dataField="bottle" caption="К-ть бутилок" dataType="number" />   
              <Column dataField="price" caption="Ціна, грн." dataType="number" />
              <Column dataField="summa" caption="Сума, грн." dataType="number" />  
              <Column dataField="discount" caption="Знижка, грн." dataType="number" />                      
              <Column dataField="userName" caption="Оператор" />
              <Column dataField="taxId" caption="Код податку" />
              <Column dataField="taxName" caption="Податок" />              
              
              <Summary>
                <TotalItem column="amount" displayFormat="Всього: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="bottle" displayFormat="Всього: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discount" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

            <Modal isOpen={this.state.isShowListWines} size="lg" style={{maxWidth: '1000px', width: '100%'}}>
              <ModalHeader toggle={this.toggleModal.bind(this)}>Редагування списку</ModalHeader>
              <ModalBody>

              <DataGrid
                ref={(ref) => this.dataGridSetList = ref}
                id="gridSalesWinesList"
                dataSource={this.state.dataGridWines}
                allowColumnReordering={true}
                allowColumnResizing={true}
                keyExpr="id"
                onRowUpdating={this.onRowUpdating.bind(this)} 
                onRowRemoving={this.onRowRemoving.bind(this)} 
                onRowInserting={this.onRowInserting.bind(this)}
                columnAutoWidth={true}                
                showBorders={true}        
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
                filterRow={{applyFilter : true, visible : true}} >
                <Paging defaultPageSize={10} />
                <Editing
                mode="popup"
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}>
                <Popup 
                  title="Редагування" 
                  showTitle={true}
                  showCloseButton={false} 
                  width={300} 
                  height={200} />
                <Form>
                  <Item itemType="group" colCount={1} colSpan={2}>
                    <Item dataField="articleId" isRequired={true} />
                    <Item dataField="bottle" isRequired={true} />
                  </Item>  
                </Form>
              </Editing> 
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 15, 25]}
                  showInfo={true} />
                <StateStoring enabled={true} type="localStorage" storageKey="SalesWinesList" />
                <Column dataField="id" caption="ID" width={60} allowEditing={false} visible={false} />                  
                <Column dataField="articleId" caption="Артикул" dataType="number" />
                <Column dataField="articleName" caption="Найменування" allowEditing={false} />                
                <Column dataField="bottle" caption="К-ть бутилок" dataType="number" />
                <Column type="buttons" caption="Дії" buttons={[{
                    hint: 'Редагувати',
                    name: 'edit',
                    icon: 'edit',
                    visible: true},
                    {
                      hint: 'Видалити',
                      name: 'delete',
                      icon: 'trash',
                      visible: true}]} />
              </DataGrid>

              <div style={{width: '100%', textAlign: 'right', marginTop: '10px'}}>
                        <Button                            
                            text="Відміна"
                            type="normal"
                            style={{marginRight: '0px'}}
                            stylingMode="contained" 
                            onClick={this.onCloseListWines.bind(this)}
                        />
               </div>      
              </ModalBody>                    
              </Modal>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeGoodsReportSalesWine);