import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatFromDatTo from '../../datFromDatTo';
import SelectTerminalOffice from '../../selectTerminalOffice';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Pager, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';
import fileDownload from 'js-file-download';

class OfficeGeneralReportDocsTaxes extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            terminalId : 0,
            isSuperUser : false,
            dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : []
        }
    }

    onTerminalSelect(e) {
      this.setState({
          terminalId : e
      });
  }

    onChangeDates(e) {
      this.setState({
          dateFrom : e.dateFrom,
          dateTo : e.dateTo             
      });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    componentDidUpdate() {
      let _isSuperUser = [1,10,50].includes(this.props.user.userTypeId);
      if(_isSuperUser !== this.state.isSuperUser) {
        this.setState({
          isSuperUser : _isSuperUser
        });
      }
    }

    onExecute() {       
        let _term = this.state.terminalId;         
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        if(!_term || _term === 0) {
          notify('Виберіть термінал зі списку','error');
          return;
        }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/DocTaxes?terminalId=' + _term + '&dateFrom=' + _datFrom + '&dateTo=' + _datTo, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
            this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
            this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    onSaveSB(e) {
      if(!this.state.isSuperUser) {
        return;
      }

      let _term = this.state.terminalId;         
      let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
      let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');
      let _fileName = `DocTaxesListSB_${_term}_${_datFrom}_${_datTo}.zip`;

        if(!_term || _term === 0) {
          notify('Виберіть термінал зі списку','error');
          return;
        }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/DocTaxes/ToExcelSB?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&terminalId=' + this.state.terminalId,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {         
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });

    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        },{
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        }, 
        // {
        //   location: 'before',
        //   widget: 'dxButton',
        //   options: {
        //     icon: 'save',
        //     text: 'Зберегти ПН',            
        //     onClick: this.onLoad.bind(this),
        //     hint: 'Зберегти ПН'
        //   }
        // },
        this.state.isSuperUser ? {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'export',
            text: 'Зберегти реєстр СБ',            
            onClick: this.onSaveSB.bind(this),
            hint: 'Зберегти реєстр СБ'
        }} : {} );
      }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
         <div style={{float: 'left', minWidth: '250px', marginRight: '10px'}}>
            <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} visibleFields={['terminalId','name']} />
         </div>
            <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
         <div style={{marginTop: "10px"}}>
         <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridOfficeGeneralReportDocsTaxes"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}                    
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportDocsTaxes_' + this.state.terminalId + '_' + _dateFrom + '_' + _datTo} />              
           <StateStoring enabled={true} type="localStorage" storageKey="OfficeReportDocsTaxes" />           
           <Paging defaultPageSize={50} />
           <Pager
             showInfo={true}
             showPageSizeSelector={true}
             allowedPageSizes={[50,100]}
           />
           <ColumnChooser enabled={true} />
           <Column dataField="dat" caption="Дата виписки ПН" dataType="datetime" />
           <Column dataField="docNum" caption="№ ПН" dataType="number" />
           <Column dataField="docDate" caption="Дата чеку" dataType="datetime" />
           <Column dataField="checkNum" caption="№ чеку" dataType="number" />
           <Column dataField="lsfVisibleNum" caption="Зовніш. № карт.лоял." visible={this.state.isSuperUser}  />
           <Column dataField="lsfInternalNum" caption="Внутріш. № карт.лоял." visible={this.state.isSuperUser}  />
           <Column dataField="fuelName" caption="Паливо" />  
           <Column dataField="paytypeName" caption="Вид оплати" />           
           <Column dataField="amount" caption="К-ть, л." dataType="number" />           
           <Column dataField="summa" caption="Сума, грн." dataType="number" /> 
           <Column dataField="clientRegistryNum" caption="ЭДРПОУ" /> 
           <Column dataField="vat" caption="ПДВ, грн." dataType="number" />
           <Column dataField="clientInn" caption="ІПН" />
           <Column dataField="price" caption="Ціна, грн." dataType="number" />  
           <Column dataField="clientName" caption="Клієнт" />
           <Column dataField="userName" caption="Оператор" />         

         </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(OfficeGeneralReportDocsTaxes);