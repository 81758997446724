import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { logOut } from '../../actions/auth';
import { routesStore } from '../../stores/initStores';
import './navMenu.css';
import { push } from 'connected-react-router';

import MenuManager from './menuManager';
import MenuOffice from './menuOffice';

class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
      super(props);

      this.toggleNavbar = this.toggleNavbar.bind(this);
      this.state = {
        collapsed: true
      };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  onRoute(item) {
    this.props.onRoute(item);
  }

  render () {
    // логика, какое меню показывать
    let menuView = <></>;
    let departmentText = "";
    let userType = this.props.auth.UserType;
    let userProfile = this.props.user.userProfile;

    // если админ или рут или СБ или рег.дир
    if([1,10,50,150].includes(userType)) {
      if(this.props.settings.params.adminProfileView 
        && this.props.settings.params.adminProfileView === 1) {
        menuView = <MenuManager />;
        departmentText = "АЗК";
      }
      else {
        menuView = <MenuOffice />;
        departmentText = "Офіс";
      }
    }
    else if(userType === 20 || userType >= 200) // если кассовик, или АЗС - жестко отправляем на Менеджер
    {
        menuView = <MenuManager />;
        departmentText = "АЗК";
    }
    else {
      // в остальных случаях смотрим чуть детальнее
      if(userProfile && [1,23].includes(userProfile.id)) {
        // если явно указан профиль Менеджер
        menuView = <MenuManager />;
        departmentText = "АЗК";
      }
      else if(userProfile && userProfile.isBlocked === 0) {
        // если профиль указан и не заблочен - уходим на Офис
        menuView = <MenuOffice />;
        departmentText = "Офіс";
      }
      else {
        // в остальных случаях ничего не возвращаем. непонятно что...
        menuView = <></>;
        departmentText = "";
      }
    }    

    return (
      <header>
        <Navbar className="navbar-custom navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand className='nav-link-custom' onClick={this.onRoute.bind(this, routesStore[0].path)}>
              <div className="title-box">
                <div>Smart Reports</div>
                {this.props.auth.isAuth && departmentText !== "" ? <div className='department'>{departmentText}</div> : <></>}
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />            
            <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
            
            <ul className="navbar-nav flex-grow-1" hidden={!this.props.auth.isAuth}>

            {menuView}

            {[1,10,20].includes(this.props.auth.UserType) ? <NavItem><NavLink onClick={this.onRoute.bind(this, routesStore[2].path)} className="text-dark nav-link-custom">{routesStore[2].title}</NavLink></NavItem> : <></>}                          
            </ul>                                                         
            
            <ul className="navbar-nav" hidden={!this.props.auth.isAuth}>
              <UncontrolledDropdown nav inNavbar active>
                <DropdownToggle nav style={{paddingRight: "0px"}}>{this.props.user.displayName ? this.props.user.displayName : this.props.auth.User }</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={this.onRoute.bind(this, routesStore[1].path)}><i className="dx-icon-user nav-icon"></i> <span>{routesStore[1].title}</span></DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.props.onLogOut}><i className="dx-icon-export nav-icon"></i> <span>Вийти</span></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>                                 
            </ul>                 
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    user: state.user,
    settings : state.settings    
  }),
  dispatch => ({
    onRoute: (item) => {
      dispatch(push(item))
    },
    onLogOut: () => {
      dispatch(logOut())
    }}
))(NavMenu);
