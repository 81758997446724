import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import PivotGrid, { Export, FieldChooser, FieldPanel, StateStoring, Scrolling } from 'devextreme-react/pivot-grid';
import Moment from 'moment';
import { Button } from 'devextreme-react/button';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import DatFromDatTo from '../../datFromDatTo';

class OfficeFuelReportReportTechSpillsWithSumDiscounts extends Component {

  constructor(props) {
    super(props);        
    this.state = {
      dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
      dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        pivotGrid : []
    }
}

onChangeDates(e) {
    this.setState({
        dateFrom : e.dateFrom,
        dateTo : e.dateTo             
    });
}

onExecute() {           
    let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
    let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsFuel/ReportTechSpillsWithSumDiscounts?dateFrom=' + _datFrom + '&dateTo=' + _datTo,
     {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
      this.props.onLoading(false);
      this.setState({
        pivotGrid : response.data
      });  
   })
   .catch((error) => {
      this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

render() {

  const dataSource = new PivotGridDataSource({
    fields: [{
      caption: 'Регіон',
      dataField: 'RegionId',
      area: 'filter'
    }, {
      caption: 'ID АЗК',
      dataField: 'TerminalId',
      area: 'filter'
    }, {
      caption: 'Назва АЗК',
      dataField: 'TerminalName',
      area: 'row',
      width: 100,
      expanded: true
    }, {
      caption: 'Тип проливу',
      dataField: 'TypeName',
      area: 'filter',
    }, {
      caption: 'Дата',
      dataField: 'DateStr',
      area: 'filter'
    }, {
      caption: 'Паливо',
      dataField: 'FuelName',
      area: 'row',
      width: 100,
      expanded: true
    }, {
      caption: 'Пролито, л.',
      dataField: 'Spilled',
      dataType: 'number',
      summaryType: 'sum',
      format: { type: 'fixedPoint', precision: 2 },
      area: 'data'
    }, {
      caption: 'Ціна, грн.',
      dataField: 'Price',
      dataType: 'number',
      summaryType: 'avg',
      format: { type: 'fixedPoint', precision: 2 },
      area: 'data'
    }, {
      caption: 'Знижка, грн/л',
      dataField: 'Discount',
      dataType: 'number',
      summaryType: 'avg',
      format: { type: 'fixedPoint', precision: 2 },
      area: 'data'
    }, {
      caption: 'Сума, грн.',
      dataField: 'Total',
      dataType: 'number',
      summaryType: 'sum',
      format: { type: 'fixedPoint', precision: 2 },
      area: 'data'
    }, {
      caption: 'Сума зі знижкою, грн.',
      dataField: 'TotalWithDiscount',
      dataType: 'number',
      summaryType: 'sum',
      format: { type: 'fixedPoint', precision: 2 },
      area: 'data'
    }],
    store: this.state.pivotGrid
  });

  let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
  let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

  return (

    <div id="pivot-grid" style={{marginTop : "10px"}}>
        <div style={{float: 'left'}}>
          <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} /> 
        </div>
        <br style={{clear: 'both'}} />                      
        <Button                            
            text="Завантажити"
            style={{marginTop: '10px'}}
            type="normal"
            icon="download"
            stylingMode="outlined" 
            onClick={this.onExecute.bind(this)}                        
        />
        <div style={{marginTop: "10px"}}>
        <PivotGrid
            id="pivotgrid"
            dataSource={dataSource}
            allowFiltering={true}
            height={window.innerHeight - 250}
            allowSorting={true}
            allowSortingBySummary={true}
            showBorders={true}
            allowExpandAll={true}                
            showColumnTotals={false}
            showColumnGrandTotals={true}
            showRowTotals={false}
            showRowGrandTotals={true}
            ref={(ref) => this._pivotGrid = ref} >
      <Export enabled={true} fileName={'ReportTechSpillsWithSumDiscounts_' + _dateFrom + '_' + _dateTo} />
      <StateStoring enabled={true} type="localStorage" storageKey="pvGridReportTechSpillsWithSumDiscounts" />
      <FieldPanel
        showColumnFields={true}
        showDataFields={true}
        showFilterFields={true}
        showRowFields={true}
        allowFieldDragging={true}
        visible={true}
      />     
      <FieldChooser enabled={true} height={400} />
      <Scrolling mode="virtual" />
    </PivotGrid>
        </div>

      </div>
    )
}
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportReportTechSpillsWithSumDiscounts);