import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatFromDatTo from '../../datFromDatTo';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Pager, Summary, TotalItem, ValueFormat, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeGeneralReportBankTransactionsDiff extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : []
        }
    }

    onChangeDates(e) {
      this.setState({
          dateFrom : e.dateFrom,
          dateTo : e.dateTo             
      });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {             
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/BankTransactionsDiff?dateFrom=' + _datFrom + '&dateTo=' + _datTo, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
            this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
            this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        },{
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

      onCellPrepared(e) {
        if(e.rowType === 'data') {
          if(e.columnIndex === 11) {
            if(e.value !== 0) {
              e.cellElement.classList.add('data-grid-error');
            }
          }
        }        
      }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
         <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
         <div style={{marginTop: "10px"}}>
         <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridOfficeGeneralReportBankTransactionsDiff"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}                    
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}
           onCellPrepared={this.onCellPrepared.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportBankTransactionsDiff_' + _dateFrom + '_' + _datTo} />              
           <StateStoring enabled={true} type="localStorage" storageKey="OfficeReportBankTransactionsDiff" />           
           <Paging defaultPageSize={50} />
           <Pager
             showInfo={true}
             showPageSizeSelector={true}
             allowedPageSizes={[50,100,200,300]}
           />
           <ColumnChooser enabled={true} />
           <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
           <Column dataField="terminalName" caption="Назва АЗК" />           
           <Column dataField="shiftId" caption="Зміна №" dataType="number" />           
           <Column dataField="saleType" caption="Тип продажу" />      
           <Column dataField="datCheck" caption="Дата чеку" dataType="datetime" />
           <Column dataField="datBank" caption="Дата банк.транз." dataType="datetime" />
           <Column dataField="checkNum" caption="Чек №" dataType="number" />           
           <Column dataField="transRcpt" caption="Банк.транз. №" dataType="number" />           
           <Column dataField="cardPan" caption="Карта №" />
           <Column dataField="summaKassa" caption="Сума каса, грн." dataType="number" />
           <Column dataField="summaBank" caption="Сума банк, грн." dataType="number" />
           <Column dataField="diffSumma" caption="Різниця, грн." dataType="number" />
           <Column dataField="bankAcquirer" caption="Банк назва" />
           <Column dataField="posTerminalId" caption="Банк термінал" />

           <Summary>
                <TotalItem column="summaKassa" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summaBank" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="diffSumma" displayFormat="Різниця: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>

         </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(OfficeGeneralReportBankTransactionsDiff);