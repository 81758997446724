import React, { Component } from "react";
import { Card, CardHeader } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import { AvForm, AvField} from 'availity-reactstrap-validation-safe';
import { connect } from 'react-redux';
import { logIn, logInWithCaptcha } from '../../actions/auth';
import GoogleReCaptcha from "../other/googleReCaptcha";
import { configApp } from "../../config";
import './login.css';
// import Loading from '../loading';

class Login extends Component {

    constructor (props) {
        super(props);

        this.state = {
          tokenCaptcha: undefined
        };

        this.form = React.createRef();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.captcha = React.createRef();
      }

    //   onKeyPress(event) {
    //     if (event.which === 13 /* Enter */) {
    //       event.preventDefault();          
    //     }
    // }

    componentDidMount() {
      if(!configApp.USE_GOOGLE_RECAPTCHA) {
        this.setState({
          tokenCaptcha: true
        });
      }
    }

      handleValidSubmit() {
         let userData = {
           login: this.form.current._inputs.login.value,
           password: this.form.current._inputs.password.value,
           tokenCaptcha: this.state.tokenCaptcha
         }

         if(configApp.USE_GOOGLE_RECAPTCHA) {
          this.props.onLoginWithCaptcha(userData);
          this.captcha.updateToken();
         }
         else {
           this.props.onLogin(userData);
         }
      }

      setTokenCaptcha(e) {
        if(this.state.tokenCaptcha !== e) {
          this.setState({
            tokenCaptcha: e
          });
        }
    }

    render () {
        return (
            <div className="col-12" id="loginContainer">
                <div className="col-sm-9 col-md-7 mx-auto p-0 pb-3" style={{maxWidth: "350px", marginTop: "50px"}}>                
                <Card className="card-login">
                    <CardHeader tag="h4">Авторизація</CardHeader>
                    <AvForm onValidSubmit={this.handleValidSubmit} className="form-login" ref={this.form}>                        
                    <AvField 
                        name="login" 
                        id="login" 
                        label="Логін"
                        required
                        autoFocus
                        validate={{
                            required: {value: true, errorMessage: 'Вкажіть свій логін'}                            
                          }} />
                    <AvField 
                        name="password" 
                        id="password" 
                        label="Пароль"
                        required
                        type="password"
                        validate={{
                            required: {value: true, errorMessage: 'Вкажіть свій пароль'}                            
                          }} />                          
                    {/* <Button block type="submit">Увійти</Button>                     */}
                    <Button
                            style={{width: "100%"}}
                            text="Увійти"
                            type="default"
                            stylingMode="contained"
                            disabled={!this.state.tokenCaptcha} 
                            useSubmitBehavior={true}                           
                        />
                    {/* <Loading visible={this.props.settings.isLoadingLoginShow} type="login" style={{marginTop: "15px"}} /> */}
                    </AvForm>
                </Card>      
                {configApp.USE_GOOGLE_RECAPTCHA ? <GoogleReCaptcha ref={ref => this.captcha = ref} setParentToken={this.setTokenCaptcha.bind(this)} /> : <></>}          
                </div>                        
            </div>                        
        );
      }      
}

export default connect(
    state => ({
      settings : state.settings
    }),
    dispatch => ({
        onLogin: (userData) => {
            dispatch(logIn(userData))
          },
        onLoginWithCaptcha: (userData) => {
            dispatch(logInWithCaptcha(userData))
          }
    })
  )(Login);