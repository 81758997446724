import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatOne from '../../datOne';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, Summary, TotalItem, ValueFormat, MasterDetail, Selection } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeFuelReportFuelCompareSales extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          date : Moment(new Date()).add(-1,'days').set({hour:0,minute:0,second:0,millisecond:0}),
          captionPrev : 'Продано, л.',
          captionCurr : 'Продано, л.',
          dataGrid : [],
          mdGrid : []
        }

        this.selectionChanged = this.selectionChanged.bind(this);
    }

    onChangeDate(e) {
        this.setState({
            date : e.date            
        });
    }

    onLoad() {
      this.dataGrid.instance.collapseAll(-1);
      this.dataGrid.instance.deselectAll();
      this.clearFilterDataGrid();      
      this.onExecute();
    }

    onRefresh() {
      this.dataGrid.instance.collapseAll(-1);
      this.dataGrid.instance.deselectAll();
      this.onExecute();
    }

    onExecute(term) {
        let _date = Moment(this.state.date).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelCompareSales?date=' + _date + (term > 0 ? '&terminalId=' + term : ''),
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(term) {
            this.setState({
              mdGrid : response.data
            });
          }
          else {
            this.setState({
              dataGrid : response.data,
              captionPrev : Moment(this.state.date).add(-7,'days').format('DD.MM.YYYY'),
              captionCurr : Moment(this.state.date).format('DD.MM.YYYY')
           });
          }
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onRefresh.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }


      onCellPrepared(e) {
        if(e.rowType === 'data') {
          if(e.columnIndex === 4 || e.columnIndex === 5) {
            if(e.value < 0) {
              e.cellElement.classList.add('data-grid-error');
            }
            else if(e.value > 0) {
              e.cellElement.classList.add('data-grid-success');
            }        
          }
        }        
      }

    onCellPreparedMD(e) {
      if(e.rowType === 'data') {
        if(e.columnIndex === 3) {
          if(e.value < 0) {
            e.cellElement.classList.add('data-grid-error');
          }
          else if(e.value > 0) {
            e.cellElement.classList.add('data-grid-success');
          }
        }
      }
    }

    masterDetRender() {
      return (
        <DataGrid
                dataSource={this.state.mdGrid}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                onCellPrepared={this.onCellPreparedMD.bind(this)}  
                columnAutoWidth={true}>
                <Paging defaultPageSize={100} />    
                <Column dataField="fuelName" caption="Паливо" />
                <Column dataField="prevAmount" caption={this.state.captionPrev} dataType="number" />    
                <Column dataField="curAmount"caption={this.state.captionCurr} dataType="number" />                
                <Column dataField="diff" caption="Різниця, л." dataType="number" sortIndex="0" sortOrder="desc" />
        </DataGrid>
        );
    } 
    
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
      this.setState({
        mdGrid : []
      });

      this.onExecute(e.selectedRowKeys[0]);

    }

    calculateProc(e) {
      return e.diffProc * 100;
    }

    render() {

      let _date = Moment(this.state.date).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatOne defaultDate={this.state.date} maxDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />                        
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelCompareSales"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              keyExpr="terminalId"
              onSelectionChanged={this.selectionChanged}
              columnAutoWidth={true}
              showBorders={true}              
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}
              onCellPrepared={this.onCellPrepared.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelCompareSales_' + _date} />           
              {/* <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelSalesGrid" /> */}
              <Selection mode="single" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} /> 
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="terminal" caption="Назва АЗК" />    
              <Column dataField="prevAmount" caption={this.state.captionPrev} dataType="number" />   
              <Column dataField="curAmount" caption={this.state.captionCurr} dataType="number" /> 
              <Column dataField="diff" caption="Різниця, л." dataType="number" sortIndex="0" sortOrder="desc" /> 
              <Column dataField="diffProc" caption="Різниця, %" dataType="number" format="#0.##" calculateCellValue={this.calculateProc} />
              <MasterDetail enabled={false} component={this.masterDetRender.bind(this)} />                          
              <Summary>                
                <TotalItem column="prevAmount" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="curAmount" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="diff" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelCompareSales);