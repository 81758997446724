import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import SelectTerminalOffice from '../../selectTerminalOffice';
import DatFromDatTo from '../../datFromDatTo';
import SelectEnterpriseOffice from '../../selectEnterpriseOffice';


class OfficeFuelReportFiscalRegister extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            terminalId : 0,
            dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            enterprise : 0
        }              
    }

    onTerminalSelect(e) {
        this.setState({
            terminalId : e
        });
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onChangeEnterprise(e) {
        this.setState({
          enterprise : e
        });
      }

    onExecute(e) {

        let _term = this.state.terminalId;
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');
        let _datFromZip = Moment(this.state.dateFrom).format('DD.MM.YYYY');
        let _datToZip = Moment(this.state.dateTo).format('DD.MM.YYYY');        
        let _enpIdx = this.state.enterprise;

        if(e === 0) {
            if(!_term || _term === 0) {
                notify('Виберіть термінал зі списку','error');
                return;
            }
        }
        else if(e === 1) {
            _term = 0;
        }

        let _fileName = 'ReportFiscalRegister_' + _datFrom + '_' + _datTo + '_';
        let _fileNameZip = 'ФискальныеОтчёты_' + _datFromZip + '_' + _datToZip + '.zip';
        
        if(_term === 0) {
            _fileName = _fileNameZip;
        }
        else {
            _fileName = _fileName + _term + '.xlsx';
        }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFiscalRegister?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&selectedIndex=' + _enpIdx + `${_term > 0 ? '&terminalId=' + _term : ''}`,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {         
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    render() { 
    
      return (
          
        <div style={{paddingTop : "10px"}}>
            <div style={{maxWidth: '350px', minWidth: '350px', float: 'left', marginRight: '10px'}}>
                <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} />
            </div>
            <div style={{float: 'left', marginRight: '10px'}}>
                <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} /> 
            </div>      
            <div style={{float: 'left', marginRight: '10px'}}>
                <SelectEnterpriseOffice defaultValue={this.state.enterprise} onChangeEnterprise={this.onChangeEnterprise.bind(this)} />
            </div>      

            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this, 0)}                        
              />     
            </div>
            <div style={{float: 'left', paddingTop: "10px", marginLeft: '15px'}} className="container-custom-button">            
            <Button                            
                  text="Завантажити всі АЗК"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this, 1)}                        
              />     
            </div>

        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeFuelReportFiscalRegister);