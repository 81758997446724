import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkAccessReport } from '../../../actions/auth';
import { routesStore } from '../../../stores/initStores';
import { setAdminProfView } from '../../../actions/settings';

// Отчеты Менеджер
import ManagerReportCheckFuelsWithZReport from './fuels/reportCheckFuelsWithZReport';
import ManagerReportSalesFuelsChecks from './fuels/reportSalesFuelsChecks';
import ManagerReportFuelsDiscounts from './fuels/reportFuelsDiscounts';
import ManagerReportFuelsPayTypes from './fuels/reportFuelsPayTypes';
import ManagerReportFuelsAmountBalance from './fuels/reportFuelsAmountBalance';
import ManagerReportFuelsCounters from './fuels/reportFuelsCounters';
import ManagerReportFuelsNonFiscalSpill from './fuels/reportFuelsNonFiscalSpill';
import ManagerReportFuelsSalesByHour from './fuels/reportFuelsSalesByHour';
import ManagerReportMoneyMovement from './other/reportMoneyMovement';
import ManagerReportFuelsSalesByTalonNumbers from './fuels/reportFuelsSalesByTalonNumbers';
import ManagerReportCashDocs from './other/reportCashDocs';
import ManagerReport17NP from './fuels/report17NP';
import ManagerReportFounder from './fuels/reportFounder';
import ManagerReportFuelsCardJournal from './fuels/reportFuelsCardJournal';
import ManagerReportFuelsPrices from './fuels/reportFuelsPrices';
import ManagerReportFuelsIncome from './fuels/reportFuelsIncome';
import ManagerReportFuelsFullTanks from './fuels/reportFuelsFullTanks';
import ManagerReportSalesGoods from './goods/reportSalesGoods';
import ManagerReportGoodsChecks from './goods/reportGoodsChecks';
import ManagerReportGoodsCancelChecks from './goods/reportGoodsCancelChecks';
import ManagerReportSalesGoodsDocMove from './goods/reportSalesGoodsDocMove';
import ManagerReportMonoTransactions from './fuels/reportMonoTransactions';
import ManagerReportTaxiTransactions from './fuels/reportTaxiTransactions';
import ManagerReportNPList from './fuels/reportNPList';
import ManagerReportCashbackTransactions from './other/reportCashbackTransactions';
import ManagerReportsGoodsAutoReturn from './goods/reportGoodsAutoreturn';
import ManagerReportsFuelsTechSpillsNormed from './fuels/reportTechSpillsNormed';
import ManagerReportsFiscalRegister from './fuels/reportFiscalRegister';


class ManagerReports extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isAccess : false
        }
    }

    onAccess() {
        let _isAccess = checkAccessReport(this.props);
        if(_isAccess !== this.state.isAccess) {
          this.setState({
            isAccess : _isAccess
          })
        }

        // переключення adminProfileView, якщо не було переключено раніше
        if(_isAccess && [1,10,50,150].includes(this.props.auth.UserType)) {
            if(this.props.settings.params.adminProfileView === 0) {
                console.log(this.props.settings.params);
                this.props.onChangeAdminProfView(1);
            }        
        }
      }
  
    componentDidMount() {
        this.onAccess();
      }
  
    componentDidUpdate() {
        this.onAccess(); 
    }

    onManagerReports() {

        const _path = this.props.location.pathname;
        const _route = routesStore.find(item => item.path === _path);
        
        if(_route) {
            switch(_route.id) {
                case 6: return <ManagerReportCheckFuelsWithZReport />;
                case 7: return <ManagerReportSalesFuelsChecks />;
                case 8: return <ManagerReportFuelsDiscounts />;
                case 9: return <ManagerReportFuelsPayTypes />;
                case 10: return <ManagerReportFuelsAmountBalance />;
                case 11: return <ManagerReportFuelsCounters />;
                case 12: return <ManagerReportFuelsNonFiscalSpill />;
                case 13: return <ManagerReportFuelsSalesByHour />;
                case 14: return <ManagerReportMoneyMovement />;
                case 15: return <ManagerReportFuelsSalesByTalonNumbers />;
                case 16: return <ManagerReportCashDocs />;
                case 17: return <ManagerReport17NP />;
                case 18: return <ManagerReportFounder />;
                case 19: return <ManagerReportFuelsCardJournal />;
                case 20: return <ManagerReportFuelsPrices />;
                case 21: return <ManagerReportFuelsIncome />;
                case 22: return <ManagerReportFuelsFullTanks />;
                case 23: return <ManagerReportSalesGoods />;
                case 24: return <ManagerReportGoodsChecks />;
                case 25: return <ManagerReportGoodsCancelChecks />;
                case 26: return <ManagerReportSalesGoodsDocMove />;
                case 27: return <ManagerReportMonoTransactions />;
                case 28: return <ManagerReportTaxiTransactions />;
                case 29: return <ManagerReportNPList />;
                case 30: return <ManagerReportCashbackTransactions />;
                case 67: return <ManagerReportsGoodsAutoReturn />
                case 109: return <ManagerReportsFuelsTechSpillsNormed/>;
                case 110: return <ManagerReportsFiscalRegister />;
                default:
                    break;
            }            
        }
        
        return window.location.replace('/404');
    }

    render() {        
        const { currTerminal } = this.props.settings.params;
        return(            
            <div hidden={!this.state.isAccess}>

                {!currTerminal || currTerminal === 0 ?  

                    <div id="manager-reports-default">
                        <span>виберіть АЗК зі списку</span>
                    </div>
                    :
                    this.onManagerReports() }
            </div>
        );
    }

}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user      
    }),
    dispatch => ({
        onChangeAdminProfView: (view) => {
            dispatch(setAdminProfView(view));
        }
    })
  )(ManagerReports);