import { userTabsStore } from '../stores/initStores';

export default function userTabs(state = userTabsStore, action) {
    if (action.type === 'SET_USER_TABS_DATA') { 
      return action.payload;
    }     
    else if (action.type === 'CLEAR_USER_TABS_DATA') {
      return state = userTabsStore;
    }
    return state;
  }