
import notify from 'devextreme/ui/notify';
import { configApp } from '../config';
import axios from 'axios';
import { authStore, routesStore, reportsStore, settingsStore, userStore } from '../stores/initStores';
import { getUserData } from './user';

export function checkAccessReport(props) {

    let userType = props.auth.UserType;
    let routeData = routesStore.find((item) => item.path === props.location.pathname);
    let managerActions = reportsStore.filter((item) => item.type === 'Manager');
    let isManagerRoute = managerActions.find((item) => item.routeId === routeData.id);

    // рут, админ, сб
    if([1,10,50].includes(userType)) { return true; }    
    
    if(!routeData) { return true; }

    let reportAction = reportsStore.find((item) => item.routeId === routeData.id);
    if(!reportAction) { return true; }

    if(userType === 150) // рег.дир
    {
        if(isManagerRoute) { return true; }
    }

    if(props.user.userProfile === undefined) { return false; }    
    
    let action = props.user.userProfile != null ? props.user.userProfile.profilesActions : false;    
    if(!action) { window.location.replace('/403'); }
    else {
         if(props.user.userProfile.isBlocked === 1) { return false; }
         if(!action.find((item) => item.actionName === reportAction.actionName)) { window.location.replace('/403'); }
    }

    return true;
}

export function checkAccessRoute(props) {

    let userType = props.auth.UserType;
    let routeData = routesStore.find((item) => item.path === props.location.pathname);

    if(!userType) { window.location.replace('/403'); return false; }
    if(!routeData) { return true; }
    if(!routeData.access) { return true; }

    let userTypeAllow = routeData.access.allow;
    let userTypeDisAllow = routeData.access.disallow;

    if(userTypeAllow) {
        if(userTypeAllow.includes(userType)) {
            return true;
        }
        else {
            window.location.replace('/403'); return false;
        }
    } 

    if(userTypeDisAllow) {
        if(userTypeDisAllow.includes(userType)) {
            window.location.replace('/403'); return false;
        }
        else {
            return true;
        }
    } 

    return true;
}

export const logInWithCaptcha = (loginData) => dispatch => {
    dispatch({type: 'LOADING_SHOW', payload : true});
    axios.post(configApp.API_AUTH + '/token/CheckCaptcha', {
        token: loginData.tokenCaptcha
    })
    .then(function(response) {
        if(response.data === true) {
            dispatch(logIn(loginData));            
            return;
        }
        notify('Перевірка ReCaptcha невдала', 'error', 1000);
        dispatch({type: 'LOADING_SHOW', payload : false}); 
    })
    .catch(function (error) {
        notify('Не вдалося виконати перевірку ReCaptcha', 'error', 1000);
        dispatch({type: 'LOADING_SHOW', payload : false});        
      }); 

}

export const logIn = (loginData) => dispatch => {

    dispatch({type: 'LOADING_SHOW', payload : true});
    axios.post(configApp.API_AUTH + '/token/PasswordLogin', {
        User: loginData.login,
        Password: loginData.password
    })
    .then(function(response) {
        localStorage.setItem(configApp.LOCAL_STORE_CURR_USER, JSON.stringify(response.data));        
        let payload = response.data;
        payload.isAuth = true;
        payload.isLoginShow = false;                
        dispatch(getUserData(payload.AccessToken));
        dispatch({type: 'LOADING_SHOW', payload : false});
        return dispatch({ type: 'LOGIN', payload });
    })
    .catch(function (error) {
        if (error.response && error.response.data.message) {            
            notify(error.response.data.message, 'error', 1000);
          }
        else if(error.response && error.response.data.err_code === 801) {
            notify('Логін або пароль невірні', 'error', 1000); 
        }  
        else {
            notify('Не вдалося виконати авторизацію', 'error', 1000);            
        }
        dispatch({type: 'LOADING_SHOW', payload : false});
        //console.log(error);
        //  console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);        
      });    
  };  

  export const checkTokenExpr = () => (dispatch, getState) => {    
    if(getState().auth.Expires && getState().auth.Expires != null) {
        let _dateExpr = getState().auth.Expires;   
        if((Math.floor(new Date(_dateExpr) - new Date()) / 1000) < 60) {        
           dispatch(refreshTokenLogin());
        }
    }
  };

  export const refreshTokenLogin = (token) => (dispatch, getState) => {
    
    let _refreshToken = token ? token : getState().auth.RefreshToken ? getState().auth.RefreshToken : '';

    axios.post(configApp.API_AUTH + '/token/RefreshTokenLogin', {
        refreshToken: _refreshToken
    })
    .then(function(response) {    
        localStorage.setItem(configApp.LOCAL_STORE_CURR_USER, JSON.stringify(response.data));    
        let payload = response.data;
        payload.isAuth = true;
        payload.isLoginShow = false;
        dispatch(getUserData(payload.AccessToken));        
        return dispatch({ type: 'REFRESH_TOKEN_LOGIN', payload });
    })
    .catch(function (error) {        
        return dispatch(logOut());
      });    
 };  

export const checkAuthStart = () => dispatch => {
    let _currUser = localStorage.getItem(configApp.LOCAL_STORE_CURR_USER);    

    if(_currUser) { 
        let payload = JSON.parse(_currUser);
        dispatch({type: 'LOADING_SHOW', payload : true});
        axios.get(configApp.API_AUTH + '/ping/auth-ping', {headers : { Authorization: 'Bearer ' + payload.AccessToken }})        
        .then(function(response) {
            if(response.status === 200) {
                payload.isAuth = true;
                payload.isLoginShow = false;
                dispatch(getUserData(payload.AccessToken));                
                dispatch({type: 'LOADING_SHOW', payload : false})
                return dispatch({ type: 'CHECK_AUTH_START', payload });
            }            
        })
        .catch(function(error) {
            dispatch(refreshTokenLogin(payload.RefreshToken));
            dispatch({type: 'LOADING_SHOW', payload : false})
            return dispatch({ type: 'CHECK_AUTH_START', payload: authStore });
        })
     }    
     else {
        dispatch({type: 'LOADING_SHOW', payload : false});
        return dispatch({ type: 'CHECK_AUTH_START', payload: {...authStore, isLoginShow : true}});
     }
  };

export const logOut = () => dispatch => {
    localStorage.removeItem(configApp.LOCAL_STORE_CURR_USER);
    localStorage.removeItem(configApp.LOCAL_STORE_SETTINGS);
    dispatch({type: 'CLEAR_SETTINGS', payload: settingsStore});
    dispatch({type: 'CLEAR_USER_DATA', payload: userStore});     
    return dispatch({ type: 'LOGOUT', payload: {...authStore, isLoginShow : true}});   
  };  