import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, Summary, ValueFormat, StateStoring, GroupPanel, GroupItem, TotalItem } from 'devextreme-react/data-grid';
import Moment from 'moment';
import SelectEnterpriseOffice from '../../selectEnterpriseOffice';
import { CSVLink } from "react-csv";

class OfficeFuelReportFuelSalesBrsm2 extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : [],
          exportDataCSV : [],
          enterprise : 0
        }

    }

    onChangeEnterprise(e) {
      this.setState({
        enterprise : e
      });
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();      
      this.onExecute();
    }

    onRefresh() {
      this.onExecute();
    }

    onExecute(term) {
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');
        let _enpIdx = this.state.enterprise;

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelSalesBrsm2?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&sel=' + _enpIdx,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(term) {
            this.setState({
              mdGrid : response.data
            });
          }
          else {
            this.setState({
              dataGrid : response.data
           });
          }
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    exportToCSV() {
      if(this.state.dataGrid.length === 0) {
        return;
      }

      let _data = [];
      this.state.dataGrid.forEach(e => {
        let row = e;
        row.amount = this.replaceDotComma(row.amount);
        row.summa = this.replaceDotComma(row.summa);
        row.discountSumma = this.replaceDotComma(row.discountSumma);
        row.summaWithDiscount = this.replaceDotComma(row.summaWithDiscount);
        _data.push(row);
      });

      this.setState({
        exportDataCSV : _data
      });

      this.csvExport.link.click();
    }

    replaceDotComma(e) {
      return e.toString().replace('.',',');
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onRefresh.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
            location: 'after',
            widget: 'dxButton',
            options: {
              text: "В CSV",  
              icon: "export",           
              onClick: this.exportToCSV.bind(this),
              hint: 'Експорт в CSV'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />     
              <div id="datFromdatTo" style={{marginLeft: "10px"}}>
                <SelectEnterpriseOffice defaultValue={this.state.enterprise} onChangeEnterprise={this.onChangeEnterprise.bind(this)} />
              </div>                   
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelSalesBrsm2"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}              
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelSalesBrsm2_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelSalesBrsm2" />
              <Paging defaultPageSize={50} />
              <GroupPanel visible={true} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} /> 
              <Column dataField="terminalName" caption="Назва АЗК" groupIndex={1} />    
              <Column dataField="fuelName" caption="Паливо" /> 
              <Column dataField="amount" caption="К-ть, л." dataType="number" />   
              <Column dataField="summa" caption="Сума, грн." dataType="number" />
              <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" />
              <Column dataField="summaWithDiscount" caption="Сума зі знижк., грн." dataType="number" /> 

              <Summary>     
                <GroupItem column="amount" displayFormat="{0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem>   
                <GroupItem column="summa" displayFormat="{0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem>      
                <GroupItem column="discountSumma" displayFormat="{0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem> 
                <GroupItem column="summaWithDiscount" displayFormat="{0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem>
                <TotalItem column="amount" displayFormat="{0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>    
                <TotalItem column="summa" displayFormat="{0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discountSumma" displayFormat="{0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summaWithDiscount" displayFormat="{0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>
            <CSVLink
              ref={(ref) => this.csvExport = ref}
              data={this.state.exportDataCSV}
              separator=";"
              filename={'ReportFuelSalesBrsm2_' + _dateFrom + '_' + _dateTo + '.csv'}
              target="_blank" />
          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelSalesBrsm2);