const prod = {
    API_AUTH: 'https://rp1.brsm-nafta.com/api/v1',
    API_CORE: 'https://rp2.brsm-nafta.com/api/v1',
    LOCAL_STORE_CURR_USER : 'currentUser',
    LOCAL_STORE_SETTINGS : 'userSettings',
    GOOGLE_RECAPTCHA_V3_KEY : '6LeXRXcbAAAAAEII9loj38ofuOca0FdMOEeGf4az',
    USE_GOOGLE_RECAPTCHA : false
};

const dev = { 
    API_AUTH: 'https://rp1-dev.brsm-nafta.com/api/v1',
    API_CORE: 'https://rp2-dev.brsm-nafta.com/api/v1',
    //API_CORE: 'https://localhost:55011/api/v1',
    LOCAL_STORE_CURR_USER : 'currentUser',
    LOCAL_STORE_SETTINGS : 'userSettings',
    GOOGLE_RECAPTCHA_V3_KEY : '6LeXRXcbAAAAAEII9loj38ofuOca0FdMOEeGf4az',
    USE_GOOGLE_RECAPTCHA : false
  };

function isDev() {
  if(process.env.REACT_APP_MODE) {
    if(process.env.REACT_APP_MODE === 'development') {
      return true;
    }
  }
  else {
    if(process.env.NODE_ENV === 'development') {
      return true;
    }
  }
  return false;
}

export const configApp = isDev() ? dev : prod;
