import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, Editing, Popup, Form, MasterDetail } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import Moment from 'moment';
import { SelectBox, TagBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class OfficeGoodsReportSalesGoodsFilter extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : [],
          setList : [],
          setId : 0,
          isShowSetFilters : false,
          setListFull: [],
          setListFullView : [],
          setListFullDetView : []
        }
    }

    onChangeTypeSelect(e) {
      this.setState({
        setId : e.value
      });
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    componentDidMount() {
      this.onLoadSetList();
    }

    onLoadSetList() {
      axios.get(configApp.API_CORE + '/GoodsFilter',
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
     .then((response) => {
       this.setState({
         setList : response.data
       });            
    })
    .catch((error) => {
       //  if (error.response && error.response.data.message) {   
       //      notify(error.response.data.message, 'error');
       //     }
       //  else {
       //      notify('Не вдалося завантажити дані', 'error');               
       //  }
      });
    }

    onLoadSetListFull() {
      this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/GoodsFilter/detailed',
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
            setListFull: response.data
          });
          this.setListFullViewData(response.data);            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    setListFullViewData(e) {
      let _data = [];

      e.forEach(r => {
        let _r = _data.find(x => {return x.setId === r.setId});        
        if(_r !== undefined) {
          _r.articleIds.push(r.articleId);
        }
        else {
          _r = {};
          _r.setId = r.setId;
          _r.setName = r.setName;
          _r.articleIds = [];
          _r.articleIds.push(r.articleId);
          _data.push(_r);
        }
      });

      this.setState({
        setListFullView : _data
      });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {         
        let _datFrom = Moment(this.state.dateFrom).format('yyyy-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('yyyy-MM-DD');
        let _setId = this.state.setId;

        if(!_setId || _setId === 0) {
          notify('Виберіть тип вибірки', 'error');
          return;
        }


        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGoods/ReportSalesGoodsFilter?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&setId=' + _setId,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onShowSetFilters() {
      this.setState({
        isShowSetFilters : true
      });
      this.onLoadSetListFull();
    }

    onCloseSetFilters() {
      this.setState({
        isShowSetFilters : false
      });
      this.onLoadSetList();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }}, {
            location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'edit',
              text: 'Редагувати набори',            
              onClick: this.onShowSetFilters.bind(this),
              hint: 'Редагувати набори'
            }});
      }

      onCellRenderArticleIds(e){
        return(
          <TagBox items={e.data.articleIds} value={e.data.articleIds} showClearButton={false} readOnly={true} disabled={true} />
        );  
      }

      onCustomItemCreating(e) { 
        
        if(!e.customItem) { 
          if(!isNaN(parseInt(e.text))) {
            e.customItem = parseInt(e.text);
          }
          else {
            //e.component.option("isValid", false);
            e.customItem = null;            
          }          
        }        
    }

    SetArticleIdsDet(e) {

      let _items = this.state.setListFull.filter(f => {return f.setId === e.data.data.setId});

      return (
        <div>
        <div style={{marginBottom: '10px'}}><b>Інформація по набору</b></div>
        <DataGrid
              ref={(ref) => this.dataGridDet = ref}
              id="gridSetArticleIdsDet"              
              dataSource={_items}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}
              rowAlternationEnabled={true}
              filterRow={{applyFilter : true, visible : true}}>                            
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20]}
                showInfo={true} />                
              <Column dataField="articleId" caption="ID артикула" dataType="number" />                           
              <Column dataField="name" caption="Найменування" />                           
            </DataGrid> 
        </div>
      );
    }  

    toggleModal() {
      if(this.state.isShowSetFilters) {
        this.onCloseSetFilters();
      }
    }

    onRowUpdating(e) {

      let data = {};

      if(e.data && (!e.data.setId || e.data.setId === 0)) {
        data = e.data;
      }
      else {
        data = e.oldData;
        if(e.newData.setName) { data.setName = e.newData.setName; }
        if(e.newData.articleIds) { data.articleIds = e.newData.articleIds; }
      }

     data.articleId = data.articleIds;
 
      this.props.onLoading(true);
    
      const isCanceled = new Promise((resolve, reject) => {
        axios.post(configApp.API_CORE + '/GoodsFilter', data,
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.onLoadSetListFull();
        resolve(false);
        })
      .catch((error) => {
        this.props.onLoading(false);
          if (error.response && error.response.data.err_descr) {   
              notify(error.response.data.err_descr, 'error');
            }
          else {
              notify('Не вдалося зберегти дані', 'error');               
          }
          resolve(true);
        });
      });
    
      e.cancel = isCanceled;

    }

    onRowRemoving(e) {
      this.props.onLoading(true);

      const isCanceled = new Promise((resolve) => {
        axios.delete(configApp.API_CORE + '/GoodsFilter?setId=' + e.data.setId,    
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.props.onLoading(false);
        resolve(false);
        })
      .catch((error) => {
        this.props.onLoading(false);
          if (error.response && error.response.data.err_descr) {   
              notify(error.response.data.err_descr, 'error');
            }
          else {
              notify('Не вдалося видалити набір', 'error');               
          }
          resolve(true);
        });
      });
      e.cancel = isCanceled;
    }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
              <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>тип вибірки</span>                                      
                      <SelectBox
                        ref={item => this.selbox = item}        
                        value={this.state.setId}                        
                        valueExpr="id"                                        
                        deferRendering={false}     
                        width="14rem"               
                        displayExpr="setName"
                        dataSource={this.state.setList}
                        onValueChanged={this.onChangeTypeSelect.bind(this)} />      
                  </div>
                </div>  
            </div>
            
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportSalesGoodsFilter"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportSalesGoodsFilter_' + _dateFrom + '_' + _datTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportSalesGoodsFilter" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />      
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="terminalName" caption="Назва АЗК" />   
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="articleId" caption="Код" dataType="number" />
              <Column dataField="articleName" caption="Найменування" />   
              <Column dataField="amount" caption="Кількість" dataType="number" />   
              <Column dataField="price" caption="Ціна, грн." dataType="number" />
              <Column dataField="summa" caption="Сума, грн." dataType="number" />  
              <Column dataField="discount" caption="Знижка, грн." dataType="number" />        
              <Column dataField="checkNum" caption="Чек №" dataType="number" />
              <Column dataField="userName" caption="Оператор" />
              <Column dataField="taxId" caption="Код податку" />
              <Column dataField="taxName" caption="Податок" />              
              
              <Summary>
                <TotalItem column="amount" displayFormat="Всього: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discount" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

            <Modal isOpen={this.state.isShowSetFilters} size="lg" style={{maxWidth: '1000px', width: '100%'}}>
              <ModalHeader toggle={this.toggleModal.bind(this)}>Редагування списку наборів</ModalHeader>
              <ModalBody>

              <DataGrid
                ref={(ref) => this.dataGridSetList = ref}
                id="gridSalesGoodsFilterSetsList"
                dataSource={this.state.setListFullView}
                allowColumnReordering={true}
                allowColumnResizing={true}
                keyExpr="setId"
                onRowUpdating={this.onRowUpdating.bind(this)} 
                onRowRemoving={this.onRowRemoving.bind(this)} 
                onRowInserting={this.onRowUpdating.bind(this)}
                columnAutoWidth={true}                
                showBorders={true}        
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
                filterRow={{applyFilter : true, visible : true}} >
                <Paging defaultPageSize={10} />
                <Editing
                mode="popup"
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}>
                <Popup 
                  title="Редагування" 
                  showTitle={true}
                  showCloseButton={false} 
                  width={500} 
                  height={300} />
                <Form>
                  <Item itemType="group" colCount={1} colSpan={2}>
                    <Item dataField="setName" isRequired={true} />
                    <Item dataField="articleIds" isRequired={true} editorType="dxTagBox" editorOptions={{acceptCustomValue: true, openOnFieldClick: false, onCustomItemCreating: this.onCustomItemCreating.bind(this) }} />
                  </Item>  
                </Form>
              </Editing> 
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 15, 25]}
                  showInfo={true} />
                <StateStoring enabled={true} type="localStorage" storageKey="SalesGoodsFilterSetsList" />
                <Column dataField="setId" caption="ID" width={60} allowEditing={false} visible={false} />                  
                <Column dataField="setName" caption="Назва набору" width={200} />
                <Column dataField="articleIds" dataType="string" caption="Артикули" cellRender={this.onCellRenderArticleIds.bind(this)} />
                <Column type="buttons" caption="Дії" buttons={[{
                    hint: 'Редагувати',
                    name: 'edit',
                    icon: 'edit',
                    visible: true},
                    {
                      hint: 'Видалити',
                      name: 'delete',
                      icon: 'trash',
                      visible: true}]} />
                <MasterDetail
                  enabled={true}
                  component={this.SetArticleIdsDet.bind(this)} />           
              </DataGrid>

              <div style={{width: '100%', textAlign: 'right', marginTop: '10px'}}>
                        <Button                            
                            text="Відміна"
                            type="normal"
                            style={{marginRight: '0px'}}
                            stylingMode="contained" 
                            onClick={this.onCloseSetFilters.bind(this)}
                        />
               </div>      
              </ModalBody>                    
              </Modal>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeGoodsReportSalesGoodsFilter);