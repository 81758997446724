import React, { Component } from 'react';
import { loadReCaptcha , ReCaptcha } from 'react-recaptcha-v3';
import { configApp } from '../../config';

class GoogleReCaptcha extends Component {

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token");
    this.props.setParentToken(recaptchaToken);
  }

  componentDidMount() {
    loadReCaptcha(configApp.GOOGLE_RECAPTCHA_V3_KEY, this.callbackkCaptchaLoad);
  }

  callbackkCaptchaLoad() {
    console.log("loadCaptchaGoogle true");
  }

    componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    //logErrorToMyService(error, errorInfo);
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }
  render() {
    return (
      <div>
        <ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey={configApp.GOOGLE_RECAPTCHA_V3_KEY}
            action='login'
            verifyCallback={this.verifyCallback}
        />
      </div>
    );
  };
};

export default GoogleReCaptcha;