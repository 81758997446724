export const _reportsStore = [

    // отчеты Smart Manager    
    {
        actionName : 'Reports.ReportCheckFuelsWithZReport',
        routeId : 6,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportSalesFuelsChecks',
        routeId : 7,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsDiscounts',
        routeId : 8,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsPayTypes',
        routeId : 9,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsAmountBalance',
        routeId : 10,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsCounters',
        routeId : 11,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsNonFiscalSpill',
        routeId : 12,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsSalesByHour',
        routeId : 13,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportMoneyMovement',
        routeId : 14,
        type: 'Manager',
        subType: 'Other'
    },
    {
        actionName : 'Reports.ReportFuelsSalesByTalonNumbers',
        routeId : 15,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportCashDocs',
        routeId : 16,
        type: 'Manager',
        subType: 'Other'
    },
    {
        actionName : 'Reports.Report17NP',
        routeId : 17,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFounder',
        routeId : 18,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsCardJournal',
        routeId : 19,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsPrices',
        routeId : 20,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsIncome',
        routeId : 21,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFuelsFullTanks',
        routeId : 22,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportSalesGoods',
        routeId : 23,
        type: 'Manager',
        subType: 'Goods'
    },
    {
        actionName : 'Reports.ReportGoodsChecks',
        routeId : 24,
        type: 'Manager',
        subType: 'Goods'
    },
    {
        actionName : 'Reports.ReportGoodsCancelChecks',
        routeId : 25,
        type: 'Manager',
        subType: 'Goods'
    },
    {
        actionName : 'Reports.ReportSalesGoodsDocMove',
        routeId : 26,
        type: 'Manager',
        subType: 'Goods'
    },
    {
        actionName : 'Reports.ReportMonoTransactions',
        routeId : 27,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportTaxiTransactions',
        routeId : 28,
        type: 'Manager',
        subType: 'Fuel'
    },
    // {
    //     actionName : 'Reports.ReportNPList',
    //     routeId : 29,
    //     type: 'Manager',
    //     subType: 'Fuel'
    // },
    {
        actionName : 'Reports.ReportNP',
        routeId : 29,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportCashbackTransactions',
        routeId : 30,
        type: 'Manager',
        subType: 'Other'
    },

    // Отчеты SmartReports Office

    {
        actionName : 'ReportsGeneral.CashDocs',
        routeId : 34,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.CashDocForDay',
        routeId : 35,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.TotalSales',
        routeId : 37,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.CashDocsDiff',
        routeId : 82,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.NotActivityStation',
        routeId : 83,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsTalons.ReportAbuseTalons',
        routeId : 54,
        type: 'Office',
        subType: 'Talons',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.ReportFuelsGoodsPaytypes',
        routeId : 59,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.ReportShiftsChange',
        routeId : 84,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.ReportTotalSales',
        routeId : 85,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    // {
    //     actionName : 'ReportsGeneral.TerminalsTransfer', ///// ЧИ ПОТРІБНО?
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'General',
    //     visible : false
    // },
    // {
    //     actionName : 'ReportsGeneral.TerminalsTransferSetNew',
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'General',
    //     visible : false
    // },
    {
        actionName : 'ReportsGeneral.MoneyMovement',
        routeId : 33,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.DocTaxes',
        routeId : 86,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.CashbackTransaction',
        routeId : 58,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.CheckCopy',
        routeId : 87,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportComplex',
        routeId : 36,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsChecks',
        routeId : 42,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsPaytypes',
        routeId : 43,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelSalesCash',
        routeId : 44,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsTankSaldos',
        routeId : 45,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsDiscounts',
        routeId : 46,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsPrices',
        routeId : 52,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelFeedRate',
        routeId : 88,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsSalesByHours',
        routeId : 47,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.Report17NP',
        routeId : 41,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFiscalRegister',
        routeId : 48,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsRealRest',
        routeId : 49,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelSalesGrid',
        routeId : 50,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelCompareSales',
        routeId : 51,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelIncomes',
        routeId : 53,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelIncomesSales',
        routeId : 89,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelSalesAmountChecks',
        routeId : 90,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsCheckCounters',
        routeId : 91,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsChecksCount',
        routeId : 92,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsTalons.FuelsChecksTalons',
        routeId : 55,
        type: 'Office',
        subType: 'Talons',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsFullTanks',
        routeId : 93,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsPaytypes2',
        routeId : 94,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.LoyaltyFuelsChecks',
        routeId : 95,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelInventory',
        routeId : 96,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelInventoryMonth',
        routeId : 97,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelSalesBrsm2',
        routeId : 98,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportRestManualEdit',
        routeId : 99,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortEngine',
        routeId : 77,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortEngineFuels',
        routeId : 78,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    // {
    //     actionName : 'ReportsFuel.InitTechSpillFuelTable', ||| Стара лабуда не потрібна
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'Fuel',
    //     visible : false
    // },
    // {
    //     actionName : 'ReportsFuel.AddTechSpillFuelTable',
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'Fuel',
    //     visible : false
    // },
    // {
    //     actionName : 'ReportsFuel.ListOrders',
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'Fuel',
    //     visible : false
    // },
    // {
    //     actionName : 'ReportsFuel.ReportFuelTechnicalSpill',  |||| звіт до ручних тех.проливів
    //     routeId : 30,
    //     type: 'Office',
    //     subType: 'Fuel',
    //     visible : false
    // },
    {
        actionName : 'ReportsGoods.GoodsChecks',
        routeId : 38,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsGoods.GoodsSalesTaxes',
        routeId : 39,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsGoods.ReportSalesGoodsFilter',
        routeId : 100,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsGoods.ReportSalesWine',
        routeId : 101,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsGoods.ReportSalesWasher',
        routeId : 102,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsGoods.ReportGoodsSalesByHours',
        routeId : 40,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsCards.EngineTransactions',
        routeId : 74,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportEngineBalance',
        routeId : 76,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportTNPPortEngine',
        routeId : 75,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportFuelWallet',
        routeId : 79,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsTalons.ReportCheckUseTalons',
        routeId : 56,
        type: 'Office',
        subType: 'Talons',
        visible : true
    }, 
    {
        actionName : 'ReportsGeneral.LottoPayTransactions',
        routeId : 60,
        type: 'Office',
        subType: 'General', 
        visible : true
    },
    {
        actionName : 'ReportsGeneral.BankTransactionsDiff',
        routeId : 61,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelRestGauges',
        routeId : 62,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsTalons.ReportTalonsActive',
        routeId : 63,
        type: 'Office',
        subType: 'Talons',
        visible : true
    },
    {
        actionName : 'ReportsTalons.ReportTalonsPayOff',
        routeId : 64,
        type: 'Office',
        subType: 'Talons',
        visible : true
    },
    {
        actionName : 'ReportsTalons.ReportTalonsInfo',
        routeId : 65,
        type: 'Office',
        subType: 'Talons',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.TerminalsInfo',
        routeId : 66,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'Reports.ReportGoodsAutoReturn',
        routeId : 67,
        type: 'Manager',
        subType: 'Goods'
    },
    {
        actionName : 'ReportsCards.ReportTaxiTransactions',
        routeId : 69,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportSpillPrice',
        routeId : 70,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportTaxiAvgSpill',
        routeId : 71,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportTaxiRests',
        routeId : 72,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelSalesDailyGrid',
        routeId : 73,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortTaxi',
        routeId : 80,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortTaxiFuels',
        routeId : 81,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpills',
        routeId : 103,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsTerminalDetailed',
        routeId : 104,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsWithSum',
        routeId : 105,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsWithSumDiscounts',
        routeId : 106,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsDifferences',
        routeId : 107,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsNormed',
        routeId : 108,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'Reports.ReportTechSpillsNormed',
        routeId : 109,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'Reports.ReportFiscalRegister',
        routeId : 110,
        type: 'Manager',
        subType: 'Fuel'
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsTerminalDetailedRepair',
        routeId : 111,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.FuelsTankAlarm',
        routeId : 113,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortEngineVS',
        routeId : 114,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportRevisePortEngineFuelsVS',
        routeId : 115,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportFuelsDiscountsSummary',
        routeId : 116,
        type: 'Office',
        subType: 'Fuel',
        visible : true
    },
    {
        actionName : 'ReportsGoods.ReportSalesGlovo',
        routeId : 117,
        type: 'Office',
        subType: 'Goods',
        visible : true
    },
    {
        actionName : 'ReportsCards.ProblemBackgroundTrans',
        routeId : 118,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsCards.SalesFuelsDiffCardsTrans',
        routeId : 119,
        type: 'Office',
        subType: 'Cards',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.DeductionZSUByClients',
        routeId : 120,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.DeductionZSUTotalStations',
        routeId : 121,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportWsSpills',
        routeId : 123,
        type: 'Office',
        subType: 'WSSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportWsSpillsDetailed',
        routeId : 124,
        type: 'Office',
        subType: 'WSSpill',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportWsSpillsDifferences',
        routeId : 125,
        type: 'Office',
        subType: 'WSSpill',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.BankTransactionsCancelPayment',
        routeId : 126,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsGeneral.VisitsUsersStations',
        routeId : 127,
        type: 'Office',
        subType: 'General',
        visible : true
    },
    {
        actionName : 'ReportsFuel.ReportTechSpillsHistoryLog',
        routeId : 128,
        type: 'Office',
        subType: 'TSpill',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportEngineWSBalance',
        routeId : 129,
        type: 'Office',
        subType: 'WSSpill',
        visible : true
    },
    {
        actionName : 'ReportsCards.ReportEngineWSAutoWithdraw',
        routeId : 130,
        type: 'Office',
        subType: 'WSSpill',
        visible : true
    },
    {
        actionName : 'ReportsWallet.WalletBalances',
        routeId : 132,
        type: 'Office',
        subType: 'Wallet',
        visible : true
    },
    {
        actionName : 'ReportsWallet.WalletReplenish',
        routeId : 133,
        type: 'Office',
        subType: 'Wallet',
        visible : true
    }
]