import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';

export default class DatOneWithTime extends Component {

    constructor(props) {
        super(props)
        this.state = {          
             date : props.defaultDate ? props.defaultDate : new Date()              
        }
    }

    onChangedDate(e) {
        this.setState({
            date : e.value
        });
        this.props.onChangeDate(this.state);
    }

    render() {        
        return(
            <div id="datOne">
                <div className="label-date">
                    <span>дата</span>                
                    <DateBox defaultValue={this.state.date} type="datetime" onValueChanged={this.onChangedDate.bind(this)} />
                </div>
          </div>         
        )
    }
}