import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import PivotGrid, { Export, FieldChooser, FieldPanel, StateStoring, Scrolling } from 'devextreme-react/pivot-grid';
import Moment from 'moment';
import { Button } from 'devextreme-react/button';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import DatFromDatTo from '../../datFromDatTo';

class OfficeFuelReportFuelSalesDailyGrid extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            pivotGrid : []
        }
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onExecute() {           
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelSalesDailyGrid?dateFrom=' + _datFrom + '&dateTo=' + _datTo,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
                pivotGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    render() {

      const dataSource = new PivotGridDataSource({
        retrieveFields: false,
        filter: [['fuelName', '<>', null], "and", ['paytypeName','<>',null]],
        fields: [{
          caption: 'ID АЗК',
          dataField: 'terminalId',
          area: 'filter',
          width: 50,
          expanded: true
        }, {
          caption: 'Назва АЗК',
          dataField: 'terminal',
          area: 'row',
          width: 100,
          expanded: true
        }, {
          caption: 'Вид оплати',
          dataField: 'paytypeName',
          area: 'filter',
          width: 100,
          expanded: true
        }, {
          caption: 'День реалізації',
          dataField: 'day',
          dataType: 'date',
          format: 'dd MMM yyyy',
          area: 'row',
          width: 100,
          expanded: true
        }, {
          caption: 'Паливо',
          dataField: 'fuelName',
          area: 'column',
          expanded: true
        }, {
          caption: 'Актуал.даних',
          dataField: 'actualData',
          dataType: 'date',
          format: 'dd.MM.yyyy HH:mm:ss',
          area: 'filter',
          expanded: true
        }, {
          caption: 'Відпущено, л.',
          dataField: 'give',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Сума, грн.',
          dataField: 'summa',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Знижка, грн.',
          dataField: 'discountSumma',
          dataType: 'number',
          summaryType: 'sum',    
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }],
        store: this.state.pivotGrid
      });

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD_HHmmss');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD_HHmmss');

      return (

        <div id="pivot-grid" style={{marginTop : "10px"}}>
            <div style={{float: 'left'}}>
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} /> 
            </div>
            <br style={{clear: 'both'}} />                      
            <Button                            
                text="Завантажити"
                style={{marginTop: '10px'}}
                type="normal"
                icon="download"
                stylingMode="outlined" 
                onClick={this.onExecute.bind(this)}                        
            />
            <div style={{marginTop: "10px"}}>
            <PivotGrid
                id="pivotgrid"
                dataSource={dataSource}
                allowFiltering={true}
                height={window.innerHeight - 250}
                allowSorting={true}
                allowSortingBySummary={true}
                showBorders={true}
                allowExpandAll={true}                
                showColumnTotals={true}
                showColumnGrandTotals={true}
                showRowTotals={true}
                showRowGrandTotals={true}
                ref={(ref) => this._pivotGrid = ref} >
          <Export enabled={true} fileName={'ReportFuelSalesDailyGrid_' + _dateFrom + '_' + _dateTo} />
          <StateStoring enabled={true} type="localStorage" storageKey="pvGridFuelSalesDailyGrid" />
          <FieldPanel
            showColumnFields={true}
            showDataFields={true}
            showFilterFields={true}
            showRowFields={true}
            allowFieldDragging={true}
            visible={true}
          />     
          <FieldChooser enabled={true} height={400} />
          <Scrolling mode="virtual" />
        </PivotGrid>
            </div>

          </div>
        )
    }
    
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelSalesDailyGrid);