import { configApp } from '../config';
import axios from 'axios';
import { getSettings } from '../actions/settings';
import cloneDeep from 'lodash/cloneDeep';

export const getUserData = (token) => dispatch => {
    axios.get(configApp.API_CORE + '/Users/owned', {headers : { Authorization: 'Bearer ' + token }})
    .then(function(response) {      
        var _data = cloneDeep(response.data.user);
        _data.userTerminals = response.data.terminals;
        dispatch({ type: 'GET_USER_DATA', payload : _data });
        return dispatch(getSettings());
    })
    .catch(function (error) {        
      });    
  };  
