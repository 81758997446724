import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectTerminalOffice from '../../selectTerminalOffice';
import SelectShiftsOffice from '../../selectShiftsOffice';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';

class OfficeFuelReport17NP extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            terminalId : 0,
            shiftId : 0
        }              
    }

    onTerminalSelect(e) {
        this.setState({
            terminalId : e
        });
    }

    onSelectShift(e) {
        this.setState({
            shiftId : e
        });
    }

    onExecute() {   

        let _term = this.state.terminalId;
        let _shiftId = this.state.shiftId;

        if(!_term || _term === 0) {
            notify('Виберіть термінал зі списку','error');
            return;
        }

        if(!_shiftId || _shiftId === 0) {
            notify('Виберіть зміну зі списку','error');
            return;
        }

        let _fileName = '17NP_' + _term + '_' + _shiftId + '.xlsx';

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/Report17NP?shiftId=' + _shiftId + '&terminalId=' + _term,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {            
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    
    render() { 

      const {terminalId} = this.state;
      
      return (
        <div style={{paddingTop : "10px"}}>
             <div style={{maxWidth: '350px', minWidth: '350px'}}>
                <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} />
            </div>
            <div style={{marginTop: '10px'}}>
                <SelectShiftsOffice selectedTerminal={terminalId} onShiftSelect={this.onSelectShift.bind(this)} />
            </div> 
            <div style={{marginTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
              </div>
        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeFuelReport17NP);