import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatToWithTime from '../../datFromDatToWithTime';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';

class ManagerReportsFuelsFullTanks extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).add(1,'day').set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : [],
            prevTerm : 0
        }
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {           
        let _term = this.props.settings.params.currTerminal;

        if(!_term || _term === 0) {
          notify('Виберіть термінал','error');
          return;
        }
        
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DDTHH:mm:ss');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DDTHH:mm:ss');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Reports/ReportFuelsFullTanks?terminalId=' + _term + '&dateFrom=' + _datFrom + '&dateTo=' + _datTo,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    componentDidUpdate() {
      if(this.state.prevTerm !== this.props.settings.params.currTerminal) {
        this.setState({
            prevTerm : this.props.settings.params.currTerminal,
            dataGrid : []
        });        
      }
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

    render() {

      let _term = this.props.settings.params.currTerminal;
      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD_HHmmss');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD_HHmmss');

      return (

        <div style={{marginTop : "10px"}}>
          <DatFromDatToWithTime defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />

            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsFullTanks"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >       
                   
              <Export enabled={true} fileName={'ReportFuelsFullTanks_' + _term + '_' + _dateFrom + '_' + _datTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsFullTanks" />
              <Paging defaultPageSize={50} />
              <ColumnChooser enabled={true} /> 
              <Column dataField="fuelId" caption="Паливо №" dataType="number"  />
              <Column dataField="fuelName" caption="Паливо" />              
              <Column dataField="fullCnt" caption="ПБ Чеки" dataType="number" />
              <Column dataField="fullGive" caption="ПБ відпущено, л." dataType="number" />
              <Column dataField="fullSumma" caption="ПБ продано, грн." dataType="number" />
              <Column dataField="fullDiscount" caption="ПБ знижка, грн." dataType="number" />
              <Column dataField="cnt" caption="Чеки" dataType="number" />
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="summa" caption="Продано, грн." dataType="number" />
              <Column dataField="discount" caption="Знижка, грн." dataType="number" />              
              <Column dataField="fraudChecks" caption="ПБ цілі чеки" dataType="number" />

            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(ManagerReportsFuelsFullTanks);