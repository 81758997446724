import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import SelectTerminalOffice from '../../selectTerminalOffice';
import DatOne from '../../datOne';

class OfficeGeneralReportCashDocForDay extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            terminalId : 0,
            dat: Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0})
        }              
    }

    onTerminalSelect(e) {
        this.setState({
            terminalId : e
        });
    }

    onChangeDate(e) {
        this.setState({
            dat : e.date             
        });
      }

    onExecute(e) {

        let _term = this.state.terminalId;

        if(e === 9999) {
            _term = e;
        }
        
        if(!_term || _term === 0) {
            notify('Виберіть термінал зі списку','error');
            return;
        }

        if(!this.state.dat) {
            notify('Вкажіть дату','error');
            return;
        }

        let _dat = Moment(this.state.dat).format('YYYY-MM-DD');
        let _fileName = _term === 9999 ? 'ReportCashDocForDay_' + _dat + '.zip' : 'ReportCashDocForDay_' + _term + _dat + '.xlsx';
        
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/CashDocForDay?terminalId=' + _term + '&date=' + _dat,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {            
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    
    render() { 
      
      return (
        <div style={{paddingTop : "10px"}}>
            <div style={{maxWidth: '350px', minWidth: '350px', float: 'left'}}>
                <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} />
            </div>
            <div style={{float: 'left', marginLeft: '15px'}}>
                <DatOne onChangeDate={this.onChangeDate.bind(this)} defaultDate={this.state.dat} />
            </div>      

            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
            </div>
            <div style={{float: 'left', paddingTop: "10px", marginLeft: '15px'}} className="container-custom-button">            
            <Button                            
                  text="Завантажити всі АЗК"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this, 9999)}                        
              />     
            </div>
        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeGeneralReportCashDocForDay);