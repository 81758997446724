import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, ColumnChooser, MasterDetail, Lookup } from 'devextreme-react/data-grid';
import { TabPanel } from 'devextreme-react/tab-panel';
import { Item } from 'devextreme-react/form';
import Moment from 'moment';

class OfficeFuelReportTechSpillsHistoryLog extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : [],
          spilCodeStatus: [
            {
              id: 0,
              value: 'Створено'
            },
            {
              id: 1,
              value: 'Очікування погодження 1'
            },
            {
              id: 2,
              value: 'Очікування погодження 2'
            },
            {
              id: 3,
              value: 'Активовано'
            },
            {
              id: 4,
              value: 'Відкликано'
            },
            {
              id: 5,
              value: 'Прострочено'
            },
            {
              id: 6,
              value: 'Відхилено'
            }
          ],
          operationType: [
            {
              id: 0,
              value: 'Створено'
            },
            {
              id: 1,
              value: 'Очікування часу активації'
            },
            {
              id: 2,
              value: 'Активовано'
            },
            {
              id: 3,
              value: 'Очікування погодження 1'
            },
            {
              id: 4,
              value: 'Погоджено 1'
            },
            {
              id: 5,
              value: 'Очікування погодження 2'
            },
            {
              id: 6,
              value: 'Погоджено 2'
            },
            {
              id: 7,
              value: 'Прострочено'
            },
            {
              id: 8,
              value: 'Відкликано'
            },
            {
              id: 9,
              value: 'Відхилено'
            },
            {
              id: 10,
              value: 'Пролив'
            },
            {
              id: 11,
              value: 'Пролив заверш.'
            },
            {
              id: 12,
              value: 'Пролив відмін.'
            }
        ]
        }
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {     
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportTechSpillsHistoryLog?dateFrom=' + _datFrom + '&dateTo=' + _datTo,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    fullNameUser(rowData) {
      if(rowData.LastName == null) return '';
      return rowData.LastName + ' ' + rowData.FirstName + ' ' + rowData.MiddleName; 
    }

    CodeDetailed(e) {
      return (
          <div>
            <div style={{marginBottom: 10}}><b>Додаткова інформація</b></div>
            <TabPanel>
              <Item title="Операції з кодом" render={this.renderOperLogTab.bind(this, e)} />
              <Item title="Проливи" render={this.renderSpillsTab.bind(this, e)} />
          </TabPanel>           
          </div>
        );
  }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

      renderOperLogTab(e) {
        return (
          <div style={{padding: 15}}>
            <DataGrid
                  id="gridEngineTransTab"
                  ref={(ref) => this.gridOperLogTab = ref}              
                  dataSource={e.data.data.SpillCodeLogOperation}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  filterRow={{applyFilter : true, visible : true}}>                            
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 15, 20, 50]}
                    showInfo={true} />  
                  <Column dataField="Date" caption="Дата операції" dataType="datetime" />    
                  <Column dataField="OperationType" caption="Тип операції">
                    <Lookup dataSource={this.state.operationType} valueExpr="id" displayExpr="value" />                
                  </Column>  
                  <Column dataField="INN" caption="ІПН користувача" visible={false} />
                  <Column dataField="LastName" dataType="string" caption="ПІБ" calculateCellValue={this.fullNameUser}  />
                  <Column dataField="Description" caption="Опис" />
                </DataGrid>
                </div>
        )
      }
      
      renderSpillsTab(e) {
        return (
          <div style={{padding: 15}}>
            <DataGrid
                  id="gridSalesFuelsTab"
                  ref={(ref) => this.gridSpillsTab = ref}              
                  dataSource={e.data.data.SpillCodeSpills}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  filterRow={{applyFilter : true, visible : true}}>                            
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 15, 20, 50]}
                    showInfo={true} />
                  <Column dataField="DateStart" caption="Початок проливу" dataType="datetime" />
                  <Column dataField="DateEnd" caption="Завершення проливу" dataType="datetime" />
                  <Column dataField="FuelId" caption="Паливо №" dataType="number" /> 
                  <Column dataField="FuelName" caption="Паливо" />        
                  <Column dataField="TankId" caption="Резервуар №" dataType="number" />
                  <Column dataField="Prk" caption="ПРК №" dataType="number" />
                  <Column dataField="Ptrk" caption="Кран №" dataType="number" />
                  <Column dataField="ShiftId" caption="Зміна №" dataType="number" />
                  <Column dataField="CounterStartValue" caption="Почат.лічильник" dataType="number" />
                  <Column dataField="TechCounterStartValue" caption="Тех.почат.лічильник" dataType="number" />
                  <Column dataField="CounterEndValue" caption="Кінц.лічильник" dataType="number" />
                  <Column dataField="TechCounterEndValue" caption="Тех.кінц.лічильник" dataType="number" />
                  <Column dataField="AmountOrdered" caption="Замовлено, л." dataType="number" />               
                  <Column dataField="AmountSpilled" caption="Пролито, л." dataType="number" /> 
                  <Summary>
                    <TotalItem column="AmountOrdered" displayFormat="Сума: {0}" summaryType="sum">
                    <ValueFormat precision={2} />
                    </TotalItem>
                    <TotalItem column="AmountSpilled" displayFormat="Сума: {0}" summaryType="sum">
                    <ValueFormat precision={2} />
                    </TotalItem>
                  </Summary>
                </DataGrid>
                </div>
        )
      }


    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />                        
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportTechSpillsHistoryLog"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportTechSpillsHistoryLog_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportTechSpillsHistoryLog" />
              <ColumnChooser enabled={true} />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <Column dataField="TerminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="TerminalName" caption="Назва АЗК" />   
              <Column dataField="SpillCodeId" caption="ID кода тех.проливу" dataType="number" />                
              <Column dataField="SpillType" caption="Тип проливу" />
              <Column dataField="Date" caption="Дата створення" dataType="datetime" />
              <Column dataField="INN" caption="ІПН замовника" visible={false}     />
              <Column dataField="LastName" dataType="string" caption="ПІБ" calculateCellValue={this.fullNameUser}  />
              <Column dataField="Fuels" caption="Пальне" />
              <Column dataField="ActiveFrom" caption="Дата початку дії" dataType="datetime" />
              <Column dataField="ActiveTo" caption="Дата кінця дії" dataType="datetime" />
              {/* <Column dataField="StatusDescription" caption="Поточний статус" /> */}
              <Column dataField="Status" caption="Поточний статус">
                <Lookup dataSource={this.state.spilCodeStatus} valueExpr="id" displayExpr="value" />                
              </Column>
              <Column dataField="Description" caption="Причина" />
              <Column dataField="Closed" caption="Завершений" dataType="boolean" />
              <Column dataField="SpillsAmount" caption="К-ть проливів" dataType="number" />
              <Column dataField="Spilled" caption="Пролив, л." dataType="number" />
              <MasterDetail
                enabled={true}
                component={this.CodeDetailed.bind(this)} /> 

              <Summary>
                <TotalItem column="SpillCodeId" displayFormat="К-ть: {0}" summaryType="count">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="SpillsAmount" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="Spilled" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportTechSpillsHistoryLog);