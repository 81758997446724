import { configApp } from '../config';
// import { userTypeStore } from '../stores/initStores';
import axios from 'axios';
import { _reportsStore } from '../stores/reportsStore';
import { _routesStore } from '../stores/routesStore';

export const getDataProfile = () => (dispatch, getState) => { 

    // загружаем экшены
    axios.get(configApp.API_CORE + '/Profiles/GetFullActionsList', {headers : { Authorization: 'Bearer ' + getState().auth.AccessToken }})
    .then((response) => {    
        
        let items = [];                
        response.data.forEach(element => {
            let _repStore = _reportsStore.find(i => i.actionName === element);
            if(_repStore) {
                let _routeStore = _routesStore.find(i => i.id === _repStore.routeId);                
                if(_routeStore) {
                    let item = {};
                    item.actionName = element;
                    item.type = _repStore.type;
                    item.subType = _repStore.subType;
                    item.title = _routeStore.title;
                    item.path = _routeStore.path;
                    item.visible = _repStore.visible;
                    items.push(item);
                }
            }            
        });

        dispatch({type: 'SET_PROFILE_TABS_DATA', payload : {...getState().profileTabs, actions : items }});        
    })
    .catch((error) => {
        console.log(error);
    });
    
};  

export const clearDataProfile = () => (dispatch) => {    
    return dispatch({type: 'CLEAR_PROFILE_TABS_DATA', payload : null});
};