import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Column, FilterRow, Scrolling } from 'devextreme-react/data-grid';

class SelectTerminalOffice extends Component {

    constructor(props) {
        super(props)
        this.state = {            
            gridBoxValue: []
        }                
    }

    render() {        
        return(
          <div id="selTerm">
            <div className="label-term">
                <span>АЗК</span>
                <DropDownBox                  
                    ref={item => this.drpdbox = item}        
                    value={this.state.gridBoxValue}                        
                    valueExpr="terminalId"                                        
                    deferRendering={false}                    
                    displayExpr={this.gridBox_displayExpr}
                    placeholder="Виберіть АЗК ..."                    
                    dataSource={this.props.user.userTerminals}
                    onValueChanged={this.syncDataGridSelection.bind(this)}
                    contentRender={this.dataGridRender.bind(this)}
            />
            </div>
          </div>
        )
    }

    dataGridRender() {
        const { visibleFields } = this.props;
        return (             
          <DataGrid
            dataSource={this.props.user.userTerminals}   
            keyExpr="terminalId"
            hoverStateEnabled={true}   
            showBorders={true}       
            allowColumnResizing={true}
            selectedRowKeys={this.state.gridBoxValue}
            onSelectionChanged={this.dataGrid_onSelectionChanged.bind(this)}
            rowAlternationEnabled={true}
            height="100%">
            <Selection mode="single" />
            <Scrolling mode="infinite" />
            <FilterRow visible={true} />
            <Column dataField="terminalId" width="4rem" caption="ID АЗК" visible={visibleFields ? visibleFields.includes('terminalId') ? true : false : true}></Column>
            <Column dataField="name" caption="Назва АЗК" visible={visibleFields ? visibleFields.includes('name') ? true : false : true}></Column>
            <Column dataField="address" caption="Адреса" visible={visibleFields ? visibleFields.includes('address') ? true : false : true}></Column>
          </DataGrid>
        );
      }

      syncDataGridSelection(e) {
        this.setState({
          gridBoxValue: e.value
        });
      }

      dataGrid_onSelectionChanged(e) {                
        this.setState({
          gridBoxValue: e.selectedRowKeys
        });               
        if(e.selectedRowsData[0]) {
          this.props.onTerminalSelect(e.selectedRowsData[0].terminalId);
        }        
        this.drpdbox.instance.close();
      }
    
      gridBox_displayExpr(item) {
        return `${item.terminalId} ${item.name}`;
      }
}

export default connect(
    state => ({
      auth: state.auth,
      user : state.user,
      settings : state.settings
    })
  )(SelectTerminalOffice);