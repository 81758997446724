import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import PivotGrid, { Export, FieldChooser, FieldPanel, StateStoring, Scrolling, HeaderFilter } from 'devextreme-react/pivot-grid';
import { Button } from 'devextreme-react/button';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

class OfficeTalonsReportTalonsActive extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            pivotGrid : []
        }
    }

    onExecute() {           
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsTalons/ReportTalonsActive',
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
                pivotGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    render() {

      const dataSource = new PivotGridDataSource({
        fields: [{
          caption: 'Паливо №',
          dataField: 'fuelExtId',
          area: 'filter'
        }, {
          caption: 'Контрагент ID',
          dataField: 'contragentId',
          area: 'row',
          width: 100,
          expanded: true
        }, {
          caption: 'Контрагент назва',
          dataField: 'contragentName',
          area: 'row',
          expanded: true
        },  {
          caption: 'Паливо',
          dataField: 'fuelName',
          area: 'column',
          expanded: true
        },  {
          caption: 'Залишок, л.',
          dataField: 'amount',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }],
        store: this.state.pivotGrid
      });

      return (

        <div id="pivot-grid" style={{marginTop : "10px"}}>
            <Button                            
                text="Завантажити"
                style={{marginTop: '10px'}}
                type="normal"
                icon="download"
                stylingMode="outlined" 
                onClick={this.onExecute.bind(this)}                        
            />
            <div style={{marginTop: "10px"}}>
            <PivotGrid
                id="pivotgrid"
                dataSource={dataSource}
                allowFiltering={true}
                height={window.innerHeight - 200}
                allowSorting={true}
                allowSortingBySummary={true}
                showBorders={true}       
                allowExpandAll={true}                
                showColumnTotals={true}
                showColumnGrandTotals={true}
                showRowTotals={true}
                showRowGrandTotals={true}
                ref={(ref) => this._pivotGrid = ref} >
          <Export enabled={true} fileName={'ReportTalonsActive'} />
          <StateStoring enabled={true} type="localStorage" storageKey="pvGridReportTalonsActive" />
          <FieldPanel
            showColumnFields={true}
            showDataFields={true}
            showFilterFields={true}
            showRowFields={true}
            allowFieldDragging={true}
            visible={true}
          />     
          <FieldChooser enabled={true} height={400} />
          <HeaderFilter
            allowSearch={true}
            showRelevantValues={true}
            width={300}
            height={400}
          />
          <Scrolling mode="virtual" />
        </PivotGrid>
            </div>

          </div>
        )
    }
    
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeTalonsReportTalonsActive);