import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, MasterDetail } from 'devextreme-react/data-grid';
import TextArea from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';

class OfficeTalonsReportTalonsInfo extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            dataGrid : [],
            talons : []
        }
    }

    onChangeList(e) {
      let _list = e.value.split('\n');

      this.setState({
        talons: _list.filter(e => e)
      });
    }

    onCheckTalons() {
      if(!this.state.talons || this.state.talons.length === 0) {
        notify('Вкажіть список талонів', 'error');
        return;
      }

      this.setState({
        dataGrid: []
      });
      this.onExecute();

    }

    onExecute() {     

        this.props.onLoading(true);
        axios.post(configApp.API_CORE + '/ReportsTalons/ReportTalonsInfo', this.state.talons,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(response.data.length === 0) {
            notify('За даним списком талонів даних не знайдено', 'warning');
            return;
          }

          this.setState({
              dataGrid : response.data
          });            

       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
    }

    InfoTalonDet(e) {
      return (
        <div>
        <div><b>Інформація по талону</b></div>
        <DataGrid
              ref={(ref) => this.dataGridDet = ref}
              id="gridReportTalonsInfoDet"              
              dataSource={e.data.data.couponHistory}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              filterRow={{applyFilter : true, visible : true}}>                            
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50, 100]}
                showInfo={true} />                
              <Column dataField="date" caption="Дата операції" dataType="datetime" /> 
              <Column dataField="status" caption="Статус" />               
              <Column dataField="place" caption="Місце" /> 
              <Column dataField="comment" caption="Коментар" />       
              <Column dataField="doc1c" caption="Документ №" />               
            </DataGrid> 
        </div>
      );
    }

    render() {
      return (

        <div style={{marginTop : "10px"}}>          
          <div style={{marginTop: "10px"}}>
          <div id="title-area">
              <span>Список талонів:</span>
            </div>
            <div id="talons-abuse">
              <div>
              <TextArea
                height={90}
                width={200}
                onValueChanged={this.onChangeList.bind(this)} />
                <div className='small'>
                  * - кожен талон з нового рядка
                </div>
                </div>
                <div style={{marginLeft: '10px'}}>
                <Button                            
                      text="Перевірити"
                      type="normal"
                      icon="check"
                      stylingMode="outlined" 
                      onClick={this.onCheckTalons.bind(this)}                        
                  />
              </div>
            </div>           

            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportTalonsInfo"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportTalonsInfo'} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportTalonsInfo" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />                
              <Column dataField="couponNumber" caption="Талон №" />       
              <Column dataField="contragentId" caption="Контрагент ID" />   
              <Column dataField="contragentName" caption="Контрагент назва" />                
              <Column dataField="fuelExtId" caption="Паливо №" />       
              <Column dataField="fuelName" caption="Паливо назва" />         
              <Column dataField="amount" caption="Номінал, л." dataType="number" />
              <Column dataField="lastStatus" caption="Остан.статус" />               
              <Column dataField="lastPlace" caption="Остан.місце" /> 
              <MasterDetail
                enabled={true}
                component={this.InfoTalonDet.bind(this)} /> 
            </DataGrid>

            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeTalonsReportTalonsInfo);