import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import DatFromDatTo from '../../datFromDatTo';


class ManagerReportsFiscalRegister extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        }              
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onExecute(e) {

        let _allTerms = e;
        let _term = this.props.settings.params.currTerminal;

        if(!_allTerms && (!_term || _term === 0)) {
            notify('Виберіть термінал зі списку','error');
            return;
        }

        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');
        let _datFromZip = Moment(this.state.dateFrom).format('DD.MM.YYYY');
        let _datToZip = Moment(this.state.dateTo).format('DD.MM.YYYY');        

        let _fileName = 'ReportFiscalRegister_' + _datFrom + '_' + _datTo + '_';
        let _fileNameZip = 'ФіскальніЗвіти_' + _datFromZip + '_' + _datToZip + '.zip';
        
        if(_allTerms) {
            _fileName = _fileNameZip;
        }
        else {
            _fileName = _fileName + _term + '.xlsx';
        }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Reports/ReportFiscalRegister?dateFrom=' + _datFrom + '&dateTo=' + _datTo + '&selectedIndex=' + 0 + `${!_allTerms ? '&terminalId=' + _term : ''}`,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {         
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    render() { 
    
      return (
          
        <div style={{paddingTop : "10px"}}>
            <div style={{float: 'left', marginRight: '10px'}}>
                <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} /> 
            </div>      

            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this, false)}                        
              />     
            </div>
            <div style={{float: 'left', paddingTop: "10px", marginLeft: '15px'}} className="container-custom-button">            
            <Button                            
                  text="Завантажити всі АЗК"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this, true)}                        
              />     
            </div>

        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(ManagerReportsFiscalRegister);