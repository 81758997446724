import React, { Component } from 'react';
import { connect } from 'react-redux';
import { settingsStore, routesStore } from '../../stores/initStores';
import { push } from 'connected-react-router';

function NavRow(props) {

    if(props.hidden) {
        return (<></>);
    }

    // ищем путь в сторс
    let currPage = routesStore.find((item) => {
        return item.path === props.path;
    });

    if(!currPage) { // если путь не найден ничего не возращаем
        return (<></>)
    }

    // console.log(currPage);
    // console.log(props.auth);

    document.title = settingsStore.titleMain + ' | ' + currPage.title;

    let navs = [];

    if(currPage.nav[0] === 0) {
        navs.push(<span key={currPage.id}></span>);
    }
    else {
        currPage.nav.forEach((item) => {
            let _nav = routesStore.find(item2 => item2.id === item);
            if(_nav) {
               navs.push(<div className="navsRowItem" key={_nav.id}><span onClick={props.changeRoute.bind(this, _nav.path)} className="navs-link-custom">{_nav.title}</span> <span className="grey">\</span> </div>);
            }
       });
   
       navs.push(<div className="navsRowItem curr" key={currPage.id}>{currPage.title}</div>);
    }

    return (
        <div className="navsRow">
            {navs}
        </div>
    );
}

class Navs extends Component {

    onRoute(item) {
        this.props.onRoute(item);
    }

    render() {
        return (            
            <NavRow path={this.props.router.location.pathname} auth={this.props.auth} hidden={!this.props.auth.isAuth} changeRoute={(e) => {this.onRoute(e)}} />
        );
    }
}

export default connect(
    state => ({      
      auth : state.auth,  
      router : state.router
    }),
    dispatch => ({
        onRoute: (item) => {
            dispatch(push(item))
          }
    })
  )(Navs);