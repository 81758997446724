import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatToWithTime from '../../datFromDatToWithTime';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';
import SelectTerminalOffice from '../../selectTerminalOffice';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';

class OfficeTalonsReportFuelsChecksTalons extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          terminalId : 0,
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).add(1,'day').set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : []
        }
    }

    onTerminalSelect(e) {
      this.setState({
          terminalId : e
      });
  }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {     
        let _term = this.state.terminalId;      
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DDTHH:mm:ss');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DDTHH:mm:ss');

        if(!_term || _term === 0) {
          notify('Виберіть термінал зі списку','error');
          return;
        }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsTalons/FuelsChecksTalons?terminalId=' + _term + '&dateFrom=' + _datFrom + '&dateTo=' + _datTo,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          let trans = response.data.map(function(item){return item.transactionId;}).filter((v,i,a)=>a.indexOf(v)===i);
          let rows = [];
          
          trans.forEach(e => {
            let lines = response.data.filter(x => x.transactionId === e);
              let item = {};
              item.dat = lines[0].dat;
              item.dispenserId = lines[0].dispenserId;
              item.fuelName = lines[0].fuelName;
              item.give = lines[0].give;
              item.ordered = lines[0].ordered;
              item.checkNum = lines[0].checkNum;
              item.trkId = lines[0].trkId;
              item.payTypeName = lines[0].payTypeName;
              item.summa = lines[0].summa;
              item.transactionId = lines[0].transactionId;
              item.userName = lines[0].userName;
              item.talons = "";
              item.talonsExport = "";
            lines.forEach(l => {
              item.talons += l.talonNumber + " [" + l.nominal + "]<br />"; 
              item.talonsExport += l.talonNumber + " [" + l.nominal + "]\n";                            
            });
            rows.push(item);
          });

          this.setState({
              dataGrid : rows
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }});
      }

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Чеки з талонами');

      e.component.beginUpdate();
      e.component.columnOption('talonsExport', 'visible', true);

      let _term = this.state.terminalId;
      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD_HHmmss');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD_HHmmss');

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: function(options) {
          var { 
            // gridCell,
            excelCell } = options;
          excelCell.alignment = { wrapText: true };

          // if(gridCell.rowType === 'data' && gridCell.value.toString().includes('<br />')) {
          //   console.log(gridCell.value);
          //   gridCell.value = "1";
          // }
      }
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportFuelsChecksTalons_' + _term + '_' + _dateFrom + '_' + _dateTo + ".xlsx");
        });
        e.component.columnOption('talonsExport', 'visible', false);
        e.component.endUpdate();
      });
      e.cancel = true;
    }  

    render() {
      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
                <div style={{float: 'left', minWidth: '250px', marginRight: '10px'}}>
                  <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} visibleFields={['terminalId','name']} />
                </div>
                <DatFromDatToWithTime defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />                
            </div>
            <div style={{marginTop: "10px"}}>

            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsChecksTalons"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              onExporting={this.onExporting.bind(this)}
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsChecksTalons" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <ColumnChooser enabled={true} /> 
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="fuelName" caption="Паливо" />
              <Column dataField="talons" encodeHtml={false} caption="Талони" allowExporting={false} />
              <Column dataField="talonsExport" caption="Талони" visible={false} />
              <Column dataField="dispenserId" caption="ПРК №" dataType="number" /> 
              <Column dataField="trkId" caption="Кран №" dataType="number" />         
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="ordered" caption="Замовлено, л." dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="transactionId" caption="Транзак. №" dataType="number" /> 
              <Column dataField="userName" caption="Оператор" />                                       
                           
              <Summary>
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkNum" displayFormat="К-ть: {0}" summaryType="count" />
              </Summary>
            </DataGrid>

            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeTalonsReportFuelsChecksTalons);