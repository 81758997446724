import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectShifts from '../../selectShifts';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, ColumnChooser } from 'devextreme-react/data-grid';

class ManagerReportsCheckFuelsWithZReport extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            shiftId : 0,
            dataGrid : []
        }              
    }

    onSelectShift(e) {
        this.setState({
            shiftId : e
        });

        let _shift = e;
        let _term = this.props.settings.params.currTerminal;

        if(!_shift || _shift === 0 || !_term || _term === 0) {
            this.setState({
                dataGrid : []
            });
            return;
        }

        this.onLoad();
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {   
      this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Reports/ReportCheckFuelsWithZReport?shiftId=' + this.state.shiftId + '&terminalId=' + this.props.settings.params.currTerminal, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {    
          this.props.onLoading(false);        
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {

      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
      e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        });
      }

    onContentReady(e) {
       // e.element.find(".dx-datagrid-export-button").dxButton("instance").option("text", "Test");
    }

    render() { 

      let _term = this.props.settings.params.currTerminal;
      let _shift = this.state.shiftId;
      
      return (
        <div style={{paddingTop : "15px"}}>
        <SelectShifts onShiftSelect={this.onSelectShift.bind(this)} />

        <div style={{marginTop: "10px"}}>
        <DataGrid
          ref={(ref) => this.dataGrid = ref}
          id="gridReportCheckFuelsWithZReport"              
          dataSource={this.state.dataGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}         
          rowAlternationEnabled={true}
          onContentReady={this.onContentReady.bind(this)}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
          filterRow={{applyFilter : true, visible : true}} >
              
          <Export enabled={true} fileName={'ReportCheckFuelsWithZReport_' + _term + ' ' + _shift} />             
          <StateStoring enabled={true} type="localStorage" storageKey="ReportCheckFuelsWithZReport" />
          <ColumnChooser enabled={true} /> 
          <Column dataField="fuelId" visible={false} caption="ID"  />
          <Column dataField="fuelName" caption="Паливо" />              
          <Column dataField="amountR" caption="Z відпущено, л." dataType="number"  />
          <Column dataField="sumR" caption="Z сума, грн." dataType="number"  />
          <Column dataField="dSumR" caption="Z знижка, грн." dataType="number"  />              
          <Column dataField="amountF" caption="Відпущено, л." dataType="number"  />              
          <Column dataField="sumF" caption="Сума, грн." dataType="number"  />
          <Column dataField="dSumF" caption="Знижка, грн." dataType="number"  />
          <Column dataField="amountDif" caption="Різн. відпущено, л." dataType="number"  />              
          <Column dataField="sumDif" caption="Різн. сума, грн." dataType="number"  />
          <Column dataField="dSumDif" caption="Різн. знижка, грн." dataType="number"  />
        </DataGrid>
        </div>
      </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(ManagerReportsCheckFuelsWithZReport);