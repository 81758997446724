import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import './navMenu.css';
import { push } from 'connected-react-router';
import { reportsStore } from '../../stores/initStores';
import { routesStore } from '../../stores/initStores';

class MenuOffice extends Component {

  constructor (props) {
      super(props);

      this.state = {
        lastUserId : 0,
        officeReports : []
      };
  }

  componentDidUpdate() {
    this.updateMenu();  
  }

  componentDidMount() {
    this.updateMenu();
  }

  updateMenu() {
    if(this.props.user.id && this.state.lastUserId !== this.props.user.id) {
      let _reports = reportsStore.filter(i => i.type === 'Office');
      let _officeReports = [];
      _reports.forEach(element => {
          let _routes = routesStore.filter(f => f.id === element.routeId);
          element.title = _routes[0].title;
          element.path = _routes[0].path;
          _officeReports.push(element);
      });
  
      // отбираем отчеты в зависимости от типа учетки и/или профиля
      // если рут или админ - показываем все
      // если СБ - тоже все    
      if([1,10,50].includes(this.props.auth.UserType)) {
          this.setState({
              lastUserId : this.props.user.id,
              officeReports : _officeReports.filter(i => i.visible === true)
          });
          return;
      }
  
      // в ином случае, проверяем профиль пользователя
      let _userProfile = this.props.user.userProfile;
      if(_userProfile && _userProfile.isBlocked === 0) {
          if(_userProfile.profilesActions && _userProfile.profilesActions.length > 0) {
              let _filterOfficeReports = [];
              _userProfile.profilesActions.forEach(item => {
                  let item2 = _officeReports.find(f => f.actionName === item.actionName && f.visible === true);
                  if(item2) {
                      _filterOfficeReports.push(item2);
                  }
              });
  
              this.setState({
                  lastUserId : this.props.user.id,
                  officeReports : _filterOfficeReports
              });
          }
      }
    }  
  }

  onRoute(item) {
    this.props.onRoute(item);
  }

  render () {

    const locale = "uk";
    const options = { sensitivity: "base" };

    const { officeReports } = this.state;
    const reportsOfficeGeneral = officeReports.filter(i => i.subType === 'General').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeFuel = officeReports.filter(i => i.subType === 'Fuel').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeGoods = officeReports.filter(i => i.subType === 'Goods').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeTalons = officeReports.filter(i => i.subType === 'Talons').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeCards = officeReports.filter(i => i.subType === 'Cards').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeTSpill = officeReports.filter(i => i.subType === 'TSpill').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeWSSpill = officeReports.filter(i => i.subType === 'WSSpill').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsOfficeWallet = officeReports.filter(i => i.subType === 'Wallet').sort((a, b) => a.title.localeCompare(b.title, locale, options));

    return (
        <>
        {reportsOfficeGeneral.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Загальні</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeGeneral.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeFuel.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>ПММ</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeFuel.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}
        
        {reportsOfficeGoods.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Товари</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeGoods.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeTalons.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Талони</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeTalons.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeCards.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Картки</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeCards.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeTSpill.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Тех.проливи</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeTSpill.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeWSSpill.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>ВС проливи</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeWSSpill.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsOfficeWallet.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Скарбничка</DropdownToggle>
        <DropdownMenu>
          {
            reportsOfficeWallet.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

    </>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    user: state.user    
  }),
  dispatch => ({
    onRoute: (item) => {
      dispatch(push(item))
    }}
))(MenuOffice);
