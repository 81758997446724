import React, { Component } from 'react';
import Form, {
    SimpleItem,
    Label,
    EmailRule,
    RequiredRule,
    ButtonItem,
    PatternRule
  } from 'devextreme-react/form';
import { AvForm, AvField} from 'availity-reactstrap-validation-safe';
import TextArea from 'devextreme-react/text-area';
import TagBox from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'devextreme-react/autocomplete';
import notify from 'devextreme/ui/notify';
import '../settings.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { configApp } from '../../../config';

class SettingsEditUser extends Component {  

     constructor(props) {
         super(props); 
         this.form = React.createRef();
         this.formPassword = React.createRef();
         this.state = {
            customer : {
               user : {
                   id : 0,
                   userTypeId : null,
                   isBlocked : 0,
                   name : '',
                   displayName : '',
                   inn: null,
                   phone: null,
                   eMail : '',
                   userProfile : {id : 0},
                   comment : 'created by ' + this.props.auth.User,
                   lowDateTrashholdDays : 90,
                   useActiveDirectoryCheck : false,
                   activeDirectoryName : null
               },                
               terminalsId : []
            },
            profiles : [],
            modalBlockUser : false,
            modalChangePassword : false,
            isVisibleTerms : false,
            isVisibleLowDateDays : false,
            isVisibleProfile : false,
            isShowPassword: false,
            isShowActiveDirectoryName: false
        }     

         this.handleSubmit = this.handleSubmit.bind(this);
         this.handleValidSubmitPassword = this.handleValidSubmitPassword.bind(this);
         this.cancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.onLoading(true);       
        axios.get(configApp.API_CORE + '/Users/' + this.props.parentState.editUserId, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {  
            let _user = response.data;
            let _term = [];
            _user.terminals.forEach(item => {
                _term.push(item.terminalId)
            });
            this.setState({
                customer : { 
                user : {
                   id :  _user.user.id,
                   isBlocked : _user.user.isBlocked,
                   userTypeId : _user.user.userTypeId,
                   name : _user.user.name,
                   displayName : _user.user.displayName,
                   inn : _user.user.inn,
                   phone : _user.user.phone,
                   eMail : _user.user.eMail,
                   userProfile : _user.user.userProfile,
                   comment : _user.user.comment,
                   lowDateTrashholdDays : _user.user.lowDateTrashholdDays,
                   useActiveDirectoryCheck : _user.user.useActiveDirectoryCheck,
                   activeDirectoryName : _user.user.activeDirectoryName
                },
                terminalsId : _term              
            },
            profiles: _user.user.userTypeId === 20 || _user.user.userTypeId > 200 ?
                      this.props.userTabs.profiles.filter(item => item.id === 1) :
                      _user.user.userTypeId === 200 ? this.props.userTabs.profiles.filter(item => item.id === 23) :
                      this.props.userTabs.profiles.filter(item => ![1,23].includes(item.id)),
            isVisibleTerms : _user.user.userTypeId >= 150 ? true : false,
            isVisibleLowDateDays : _user.user.userTypeId >= 150 ? true : false,
            isVisibleProfile : _user.user.userTypeId <= 10 || _user.user.userTypeId === 50 ? false : true,
            isShowPassword: !_user.user.useActiveDirectoryCheck,
            isShowActiveDirectoryName: _user.user.useActiveDirectoryCheck,
            prevActiveDirectory : {
                useActiveDirectoryCheck : _user.user.useActiveDirectoryCheck,
                activeDirectoryName : _user.user.activeDirectoryName
            }  

          });

          this.props.onLoading(false);
        })
        .catch((error) => {
          this.props.onLoading(false); 
          if (error.response && error.response.data.message) {     
                   notify(error.response.data.message, 'error', 1000);     
                 } 
          else {
                 notify('Помилка при завантаженні данних користувача', 'error', 1000);
             }   
            this.onCancelClick();
        });                
    }

    handleSubmit(e) {        
        e.preventDefault(); 
        //e.target.reset();

        const { customer, prevActiveDirectory } = this.state;

        if(customer.user.userTypeId === 400 && customer.terminalsId.length > 1) {
            notify('Для даного типу користувача неможливо додати більше однієї АЗК','error');
            return;
        }

        let isNeedUpdateAD = customer.user.useActiveDirectoryCheck !== prevActiveDirectory.useActiveDirectoryCheck 
            || customer.user.activeDirectoryName !== prevActiveDirectory.activeDirectoryName;

        this.props.onLoading(true);

        let isADUpdComplete = new Promise((resolve,reject) => {
            if(isNeedUpdateAD) {
                axios.post(configApp.API_CORE + '/Users/update-active-directory', 
                {
                    id: customer.user.id,
                    useActiveDirectoryCheck : customer.user.useActiveDirectoryCheck,
                    activeDirectoryName : customer.user.activeDirectoryName
                }
                , {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
                .then((response) => {
                    resolve(true);                    
                })
                .catch((error) => {
                    this.props.onLoading(false);
                    notify('Не вдалося зберегти дані', 'error', 1000);
                    resolve(false);
                });
            }
            else {
                resolve(true);
            }
        });

        isADUpdComplete.then(value => {
            if(value) {
                axios.post(configApp.API_CORE + '/Users', this.state.customer, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
                .then((response) => {                    
                    notify('Дані збережено', 'success', 1000);   
                    this.props.onLoading(false);
                    this.form.current.reset();
                    this.props.childState({
                        mainGrid: true,
                        editUser : false
                    });        
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {     
                        notify(error.response.data.message, 'error', 1000);     
                    } 
                    else {
                        notify('Не вдалося зберегти дані', 'error', 1000);
                    }                       
                    this.props.onLoading(false);
                  });
            }
        });
      }

    onCancelClick() {
        this.form.current.reset();        
        this.props.childState({
            mainGrid: true,
            editUser : false
        }); 
    }

    displayExpr(item) {    
        return item && `${item.terminalId } ${ item.name }`;
    }

    isOpenModalBlockUser() {
        this.setState({
            modalBlockUser : true
        });
    }

    isOpenModalChangePassword() {
        this.setState({
            modalChangePassword : true
        });
    }

    onBlockUser() {
        this.onCloseModalBlockUser();
        this.props.onLoading(true);
        let type = this.state.customer.user.isBlocked;
        let url = type > 0 ? '/Users/user-unblock' : '/Users/user-block';

        axios.post(configApp.API_CORE + url, {Id : this.state.customer.user.id}, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {                    
            let _customer = this.state.customer;
            _customer.user.isBlocked = type > 0 ? 0 : 1;            
            this.setState({_customer});
            console.log(this.state)
            notify('Операція блок/разблок користувача виконана', 'success', 1000);
            this.props.onLoading(false);            
        })
        .catch((error) => {
            if (error.response && error.response.data.message) {     
                notify(error.response.data.message, 'error', 1000);     
            } 
            else {
                notify('Не вдалося виконати операцію', 'error', 1000);
            }                       
            this.props.onLoading(false);
          });
    }

    onCloseModalBlockUser() {
        this.setState({
            modalBlockUser : false
        });
    }

    onCloseModalChangePassword() {
        this.setState({
            modalChangePassword : false
        });
    }

    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();          
        }
    }

    handleValidSubmitPassword(e) {
        //e.preventDefault();
        let _form = this.formPassword.current;        
        if(_form._inputs.password.value !== _form._inputs.password2.value) {   
             notify('Паролі повинні збігатися', 'error', 1000);
             return;
        }
        this.onChangePassword();
    }

    onChangePassword() {

        let _form = this.formPassword.current;
        this.props.onLoading(true);
        axios.post(configApp.API_CORE + '/Users/password-change', 
         {
            Id: this.state.customer.user.id,
            Password: _form._inputs.password.value
        },
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {        
            notify('Пароль успішно змінено', 'success', 1000);   
            this.props.onLoading(false);
            _form._inputs.password.value = '';
            _form._inputs.password2.value = '';
            this.onCloseModalChangePassword();
        })
        .catch((error) => {
            if (error.response && error.response.data.message) {     
                notify(error.response.data.message, 'error', 1000);     
            } 
            else {
                notify('Не вдалося змінити пароль', 'error', 1000);
            }                       
            this.props.onLoading(false);
          });
      }

      onADAuthChange(e) {
        if(e.value) {
            this.setState({
                isShowPassword: false,
                isShowActiveDirectoryName: true
            });
        }
        else {
            this.setState({
                isShowPassword: true,
                isShowActiveDirectoryName: false
            });
        }
      }    

  render() {    
    const { customer, isVisibleLowDateDays, isVisibleTerms, profiles, isVisibleProfile, isShowPassword, isShowActiveDirectoryName } = this.state;
     return (      
          <div style={{marginTop: "15px", maxWidth: "750px", margin: "auto"}}>          
            <div id="editUser">
            <div className="header">Редагування користувача</div>
            <div className="header-buttons">
                <Button icon="key" type="normal" visible={isShowPassword} text="Змінити пароль" onClick={this.isOpenModalChangePassword.bind(this)}></Button> 
                <Button icon="clear" type="normal" text={this.state.customer.user.isBlocked > 0 ? 'Розблокувати' : 'Заблокувати'} onClick={this.isOpenModalBlockUser.bind(this)}></Button>
            </div>
            <form onSubmit={this.handleSubmit} ref={this.form}>
            <Form
                formData={customer}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true} 
                validationGroup="customerData" >
                
                <SimpleItem dataField="user.userTypeId" editorType="dxSelectBox" isRequired={true} editorOptions={
                  {dataSource: this.props.userTabs.userTypes,
                   valueExpr : 'id',
                   displayExpr : 'name',
                   disabled : true}
                  }>                
                    <Label text="Тип користувача"></Label>
                    <RequiredRule message="Обов'язкове поле" />
                </SimpleItem>                
                <SimpleItem dataField="user.name" editorType="dxTextBox" editorOptions={{disabled : true}}>
                    <Label text="Користувач"></Label>
                    <RequiredRule message="Користувач - обов'язкове поле" />                
                </SimpleItem>
                <SimpleItem dataField="user.useActiveDirectoryCheck" editorType="dxCheckBox" editorOptions={{onValueChanged: this.onADAuthChange.bind(this)}}>
                    <Label text="Авториз. за AD"></Label>              
                </SimpleItem>
                <SimpleItem dataField="user.activeDirectoryName" editorType="dxTextBox" visible={isShowActiveDirectoryName}>
                    <Label text="Користувач AD"></Label>
                </SimpleItem>
                <SimpleItem dataField="user.displayName" editorType="dxTextBox">
                    <Label text="Ім'я"></Label>
                    <RequiredRule message="Ім'я - обов'язкове поле" />                
                </SimpleItem>
                <SimpleItem dataField="user.eMail" editorType="dxTextBox">
                    <Label text="Email"></Label>
                    <RequiredRule message="Email - обов'язкове поле" />
                    <EmailRule message="Email вказано не вірно" />                
                </SimpleItem>
                <SimpleItem dataField="user.inn" editorType="dxTextBox" editorOptions={{mask : '9999999999'}}>
                    <Label text="ІПН"></Label>
                    <PatternRule message="ІПН повинен містити 10 цифр" pattern="^\d{10}$" />
                    <RequiredRule message="ІПН - обов'язкове поле" />
                </SimpleItem>
                <SimpleItem dataField="user.phone" editorType="dxTextBox" editorOptions={{mask : '+38 (000) 000-00-00'}}>
                    <Label text="Телефон"></Label>
                </SimpleItem>
                <SimpleItem visible={isVisibleProfile} dataField="user.userProfile.id" editorType="dxSelectBox" editorOptions={{
                  dataSource: profiles,
                  valueExpr : 'id',
                  displayExpr : 'profileName'}}>                
                    <Label text="Профіль"></Label>               
                </SimpleItem>
                <SimpleItem dataField="user.comment" editorType="dxTextArea">
                <Label text="Коментар"></Label>                
                </SimpleItem>                
                <SimpleItem visible={isVisibleLowDateDays} dataField="user.lowDateTrashholdDays" editorType="dxTextBox">
                    <Label text="К-ть днів для звітів"></Label>                    
                </SimpleItem>
                <SimpleItem visible={isVisibleTerms} dataField="terminalsId" editorType="dxTagBox" editorOptions={{
                    searchEnabled : true,
                    dataSource : this.props.userTabs.terminals,
                    valueExpr : "terminalId",
                    displayExpr : this.displayExpr                    
                }}>
                    <Label text="Доступні АЗК"></Label>                    
                </SimpleItem>

                <ButtonItem  itemType="button"             
                    buttonOptions={{
                        text: 'Зберегти',
                        type: 'success',
                        useSubmitBehavior: true
                    }}
              />
              <ButtonItem itemType="button"              
                buttonOptions={{
                    text: 'Відміна',
                    type: 'normal',
                    onClick : this.onCancelClick.bind(this)
                  }}
              />
            </Form>                    
            </form>            
            </div>

            <Modal isOpen={this.state.modalBlockUser}>
                    <ModalBody>
                    Ви дійсно бажаєте {this.state.customer.user.isBlocked > 0 ? 'розблокувати' : 'заблокувати'} користувача?
                    </ModalBody>
                    <ModalFooter>
                    <Button                            
                            text="Так"
                            type="success"
                            stylingMode="contained" 
                            onClick={this.onBlockUser.bind(this)}
                        />{' '}
                        <Button                            
                            text="Ні"
                            type="danger"
                            stylingMode="contained" 
                            onClick={this.onCloseModalBlockUser.bind(this)}
                        />
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.modalChangePassword}>
                    <ModalHeader>Змінити пароль</ModalHeader>
                    <ModalBody id="user-tabs-change-password">
                    <AvForm onKeyPress={this.onKeyPress} onValidSubmit={this.handleValidSubmitPassword} className="pl-2 form-change-password" ref={this.formPassword}>                        
                        <AvField                            
                            name="password" 
                            id="password" 
                            label="Пароль"
                            required
                            type="password"
                            validate={{
                                required: {value: true, errorMessage: 'Вкажіть новий пароль'}, 
                                pattern: {value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/, errorMessage: 'Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру'}
                            }} />
                        <AvField 
                            name="password2" 
                            id="password2" 
                            label="Повторіть пароль"
                            required
                            type="password"
                            validate={{
                                required: {value: true, errorMessage: 'Повторіть свій новий пароль'}, 
                                pattern: {value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/, errorMessage: 'Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру'}
                            }} />                          
                    <div style={{display : "flex", flexDirection: "row", alignItems : "center"}}>
                    {/* <Button color="primary" type="submit" style={{marginRight : "15px"}}>Змінити</Button>                     */}
                        {/* <Button
                            width={120}
                            text="Змінити"
                            type="default"
                            stylingMode="contained" 
                            useSubmitBehavior={true}                           
                        /> */}
                        <Button                            
                            text="Змінити"
                            type="success"
                            stylingMode="contained" 
                            useSubmitBehavior={true}
                        />{' '}
                        <Button                            
                            text="Відміна"
                            type="normal"
                            stylingMode="contained" 
                            onClick={this.onCloseModalChangePassword.bind(this)}
                        />
                    </div>
                </AvForm>
                </ModalBody>                    
                </Modal>    

            {/* просто чтобы VS Code не давал предупреждения */}
            <TextArea visible={false} />
            <TagBox visible={false} />

          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      userTabs : state.userTabs      
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(SettingsEditUser);