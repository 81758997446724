import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, Summary, TotalItem, ValueFormat, MasterDetail, Selection } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeFuelReportFuelSalesGrid extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : [],
            mdGrid : []
        }

        this.selectionChanged = this.selectionChanged.bind(this);
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.dataGrid.instance.collapseAll(-1);
      this.dataGrid.instance.deselectAll();
      this.clearFilterDataGrid();      
      this.onExecute();
    }

    onRefresh() {
      this.dataGrid.instance.collapseAll(-1);
      this.dataGrid.instance.deselectAll();
      this.onExecute();
    }

    onExecute(term) {
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelSalesGrid?dateFrom=' + _datFrom + '&dateTo=' + _datTo + (term > 0 ? '&terminalId=' + term : ''),
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(term) {
            this.setState({
              mdGrid : response.data
            });
          }
          else {
            this.setState({
              dataGrid : response.data
           });
          }
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onRefresh.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }


      onCellPrepared(e) {
        if(e.rowType === 'data') {
          if(e.columnIndex === 3 || e.columnIndex === 4) {
            if(!e.value || e.value === null) {
              e.cellElement.classList.add('data-grid-error');
            }
          }
          else if(e.columnIndex === 6) {
            let _dateNow = Moment(new Date());
            let _dateActual = Moment(e.value);

            if(_dateNow.add(-60,'minutes') > _dateActual) {
              e.cellElement.classList.add('data-grid-error');
            }
            else if(_dateNow.add(-20,'minutes') > _dateActual) {
              e.cellElement.classList.add('data-grid-warning');
            }          
          }
        }        
      }

    masterDetRender() {
      return (
        <DataGrid
                dataSource={this.state.mdGrid}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}  
                columnAutoWidth={true}>
                <Paging defaultPageSize={100} />    
                <Column dataField="fuelName" caption="Паливо" />
                <Column dataField="payType" caption="Вид оплати" />    
                <Column dataField="give" caption="Продано, л." dataType="number" />                
                <Column dataField="summa" caption="Продано, грн." dataType="number" />
                <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" />
        </DataGrid>
        );
    } 
    
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
      this.setState({
        mdGrid : []
      });

      this.onExecute(e.selectedRowKeys[0]);

    }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />                        
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelSalesGrid"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              keyExpr="terminalId"
              onSelectionChanged={this.selectionChanged}
              columnAutoWidth={true}
              showBorders={true}              
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}
              onCellPrepared={this.onCellPrepared.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelSalesGrid_' + _dateFrom + '_' + _dateTo} />           
              {/* <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelSalesGrid" /> */}
              <Selection mode="single" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} /> 
              <Column dataField="rank" caption="№" dataType="number" />                 
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="terminal" caption="Назва АЗК" />    
              <Column dataField="give" caption="Продано, л." dataType="number" />   
              <Column dataField="summa" caption="Продано, грн." dataType="number" /> 
              <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" /> 
              <Column dataField="actualData" caption="Актуал.даних" dataType="datetime" />
              <MasterDetail enabled={false} component={this.masterDetRender.bind(this)} />                          
              <Summary>                
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discountSumma" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelSalesGrid);