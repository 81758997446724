import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectShiftsMulti from '../../selectShiftsMulti';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Summary, TotalItem, Paging, ValueFormat, Pager, ColumnChooser} from 'devextreme-react/data-grid';

class ManagerReportsGoodsAutoReturn extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            shiftIds : [],
            dataGrid : [],
            prevTerm : 0
        }
    }

    onSelectShift(e) {
        this.setState({
            shiftIds : e
        });

        let _shifts = e;
        let _term = this.props.settings.params.currTerminal;

        if(!_shifts || _shifts === 0 || _shifts.length === 0 || !_term || _term === 0) {
            this.setState({
                dataGrid : []
            });
            return;
        }
        this.onLoad();        
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }
    
    onExecute() {         
      let _shiftIds = this.state.shiftIds.join(';');
      this.props.onLoading(true);    
        axios.get(configApp.API_CORE + '/Reports/ReportGoodsAutoReturn?shiftId=' + _shiftIds + '&terminalId=' + this.props.settings.params.currTerminal, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {    
          this.props.onLoading(false);        
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        });
      }

      getPosName(rowData) {
        return rowData.posId + 1;
      }

    render() {

      let _term = this.props.settings.params.currTerminal;
      let _shift = this.state.shiftId;

      return (
        
        <div style={{paddingTop : "15px"}}>
         <SelectShiftsMulti onShiftSelect={this.onSelectShift.bind(this)} />

         <div style={{marginTop: "10px"}}>
         <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportGoodsAutoReturn"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportGoodsAutoReturn_' + _term + ' ' + _shift} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportGoodsAutoReturn" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[25, 50, 100]}
                showInfo={true} />     
              <ColumnChooser enabled={true} /> 
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="articleId" caption="Код" dataType="string" />
              <Column dataField="articleName" caption="Найменування" />              
              <Column dataField="barcode" caption="Штрих-код" />              
              <Column dataField="amount" caption="Кіл-ть" dataType="number" />
              <Column dataField="price" caption="Ціна, грн." dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="discount" caption="Знижка" dataType="number" />
              <Column dataField="payTypeName" caption="Вид оплати" />
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="originalCheckNum" caption="Повер. за чеком №" />             
              <Column dataField="posId" calculateCellValue={this.getPosName} caption="Роб. місце" />
              <Column dataField="userName" caption="Оператор" />
              <Column dataField="taxId" caption="Код податку" />
              <Column dataField="taxName" caption="Податок" />              
              
              <Summary>
                <TotalItem column="amount" displayFormat="К-ть: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(ManagerReportsGoodsAutoReturn);