import { configApp } from '../config';
import { userTypeStore } from '../stores/initStores';
import axios from 'axios';

export const getDataUser = () => (dispatch, getState) => { 

    //показываем тип пользователей только нижнего уровня    
    let userTypes = userTypeStore.filter(item => item.id > getState().auth.UserType);

    // для кассовиков отдельно еще запрещаем видить сотрудников ЦО
    if(getState().auth.UserType === 20) {
        userTypes = userTypes.filter(item => item.id >= 200);
    }

    dispatch({type: 'SET_USER_TABS_DATA', payload : {...getState().userTabs, userTypes }});

    //Object.assign(payload, )
    
    // загружаем профили
    axios.get(configApp.API_CORE + '/Profiles/all', {headers : { Authorization: 'Bearer ' + getState().auth.AccessToken }})
    .then((response) => {            
        dispatch({type: 'SET_USER_TABS_DATA', payload : {...getState().userTabs, profiles : response.data }});        
    })
    .catch((error) => {});

    // загружаем терминалы
    axios.get(configApp.API_CORE + '/Users/GetListOfTerminals', {headers : { Authorization: 'Bearer ' + getState().auth.AccessToken }})
    .then((response) => {            
        dispatch({type: 'SET_USER_TABS_DATA', payload : {...getState().userTabs, terminals : response.data }});
    })
    .catch((error) => {});     
};  

export const clearDataUser = () => (dispatch) => {    
    return dispatch({type: 'CLEAR_USER_TABS_DATA', payload : null});
};