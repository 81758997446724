import React, { Component } from 'react';
import { configApp } from '../../config';
import axios from 'axios';
import { connect } from 'react-redux';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Column, FilterRow, Scrolling } from 'devextreme-react/data-grid';

class SelectShiftsMulti extends Component {

    constructor(props) {
        super(props)
        this.state = {            
            shifts : [],
            prevTerm : 0,
            gridBoxValue: []
        }                
        this.loadShifts = this.loadShifts.bind(this);
    }

    componentDidMount() {        
        this.loadShifts();
    }

    componentDidUpdate() {        
         this.loadShifts();       
    }

    loadShifts() {
      let _term = this.props.settings.params.currTerminal;
      if(_term && _term !== 0 && this.state.prevTerm !== _term) {
          this.getShifts(_term);
          this.setState({
              prevTerm : _term
          })
      }
    }

    getShifts(term) {
        this.setState({
          gridBoxValue : []          
        });
        this.props.onShiftSelect(0);
        
        var that = this;
        that.props.onLoading(true);        
        axios.get(configApp.API_CORE + '/Users/GetListOfTerminalShifts?terminalId=' + term, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})        
        .then(function(response) {
            that.setState({
                 shifts : response.data
            });            
            that.props.onLoading(false);            
        })
        .catch(function(error) {           
            that.props.onLoading(false);
        })
    }

    render() {        
        return(
          <div id="selShifts">
                <DropDownBox  
                    ref={item => this.dropdbox = item}        
                    value={this.state.gridBoxValue}                        
                    valueExpr="shiftId"                                        
                    deferRendering={false}
                    displayValueFormatter={this.valueFormatter.bind(this)}                    
                    displayExpr="shiftId"
                    placeholder="Виберіть зміну ..."                    
                    dataSource={this.state.shifts}
                    onValueChanged={this.syncDataGridSelection.bind(this)}
                    contentRender={this.dataGridRender.bind(this)}
            />
            </div>
        )
    }

    dataGridRender() {
        return (             
          <DataGrid
            dataSource={this.state.shifts}   
            keyExpr="shiftId"
            hoverStateEnabled={true}   
            showBorders={true}       
            allowColumnResizing={true}
            selectedRowKeys={this.state.gridBoxValue}
            onSelectionChanged={this.dataGrid_onSelectionChanged.bind(this)}
            rowAlternationEnabled={true}
            height="100%">
            <Selection mode="multiple" allowSelectAll={false} showCheckBoxesMode="always" />
            <Scrolling mode="infinite" />
            <FilterRow visible={true} />
            <Column dataField="terminalId" width="5rem" caption="ID АЗК"></Column>
            <Column dataField="shiftId" width="8rem" caption="№ зміни"></Column>
            <Column dataField="datOpen" caption="Дата відкр." dataType="datetime"></Column>
            <Column dataField="datClose" caption="Дата закрит." dataType="datetime"></Column>
            <Column dataField="zNumber" width="8rem" caption="№ Z-звіту"></Column>
            <Column dataField="operatorName" caption="Оператор"></Column>
          </DataGrid>
        );
      }

      syncDataGridSelection(e) {
        this.setState({
          gridBoxValue: e.value
        });
      }

      valueFormatter(e){
        if(!e || e === 0 || e.length === 0) { return null;}
        let _shifts = Array.isArray(e) ? e.join(", ") : e;
        return "Зміни № " + _shifts;
      }

      dataGrid_onSelectionChanged(e) {                
        this.setState({
          gridBoxValue: e.selectedRowKeys
        }); 
        this.props.onShiftSelect(e.selectedRowKeys);                    
        this.dropdbox.instance.close();
      }
}

export default connect(
    state => ({
      auth: state.auth,
      settings : state.settings
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(SelectShiftsMulti);