import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, ColumnChooser } from 'devextreme-react/data-grid';
import TextArea from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';

class OfficeTalonsReportCheckUseTalons extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            dataGrid : [],
            talons : []
        }
    }

    onChangeList(e) {
      let _list = e.value.split('\n');

      this.setState({
        talons: _list.filter(e => e)
      });
    }

    onCheckTalons() {
      if(!this.state.talons || this.state.talons.length === 0) {
        notify('Вкажіть список талонів', 'error');
        return;
      }

      this.setState({
        dataGrid: []
      });
      this.onExecute();

    }

    onExecute() {     

        this.props.onLoading(true);
        axios.post(configApp.API_CORE + '/ReportsTalons/ReportCheckUseTalons', this.state.talons,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(response.data.length === 0) {
            notify('За даним списком талонів продажів не знайдено', 'warning');
            return;
          }

          let trans = response.data.map(function(item){return item.transactionId;}).filter((v,i,a)=>a.indexOf(v)===i);
          let rows = [];
          
          trans.forEach(e => {
            let lines = response.data.filter(x => x.transactionId === e);
              let item = {};
              item.terminalId = lines[0].terminalId;
              item.terminalName = lines[0].terminalName;
              item.dat = lines[0].dat;
              item.dispenserId = lines[0].dispenserId;
              item.fuelName = lines[0].fuelName;
              item.give = lines[0].give;
              item.ordered = lines[0].ordered;
              item.checkNum = lines[0].checkNum;
              item.shiftId = lines[0].shiftId;
              item.trkId = lines[0].trkId;
              item.payTypeName = lines[0].payTypeName;
              item.summa = lines[0].summa;
              item.transactionId = lines[0].transactionId;
              item.userName = lines[0].userName;
              item.talons = "";
              item.talonsExport = "";
            lines.forEach(l => {
              let inList = this.state.talons.includes(l.talonNumber);
              let highlightStart = inList ? "<font color='orange'>" : "";
              let highlightEnd = inList ? "</font>" : "";
              item.talons += highlightStart + l.talonNumber + " [" + l.nominal + "]" + highlightEnd + "<br />"; 
              item.talonsExport += l.talonNumber + " [" + l.nominal + "]\n";                            
            });
            rows.push(item);
          });

          this.setState({
              dataGrid : rows
          });            

       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }});
      }

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Чеки з талонами');

      e.component.beginUpdate();
      e.component.columnOption('talonsExport', 'visible', true);

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: function(options) {
          var { 
            // gridCell,
            excelCell } = options;
          excelCell.alignment = { wrapText: true };

          // if(gridCell.rowType === 'data' && gridCell.value.toString().includes('<br />')) {
          //   console.log(gridCell.value);
          //   gridCell.value = "1";
          // }
      }
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportCheckUseTalons.xlsx');
        });
        e.component.columnOption('talonsExport', 'visible', false);
        e.component.endUpdate();
      });
      e.cancel = true;
    }  

    render() {
      return (

        <div style={{marginTop : "10px"}}>          
          <div style={{marginTop: "10px"}}>
          <div id="title-area">
              <span>Список талонів:</span>
            </div>
            <div id="talons-abuse">
              <div>
              <TextArea
                height={90}
                width={200}
                onValueChanged={this.onChangeList.bind(this)} />
                <div className='small'>
                  * - кожен талон з нового рядка
                </div>
                </div>
                <div style={{marginLeft: '10px'}}>
                <Button                            
                      text="Перевірити"
                      type="normal"
                      icon="check"
                      stylingMode="outlined" 
                      onClick={this.onCheckTalons.bind(this)}                        
                  />
              </div>
            </div>           

          <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportCheckUseTalons"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              onExporting={this.onExporting.bind(this)}
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="ReportCheckUseTalons" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <ColumnChooser enabled={true} />
              <Column dataField="terminalId" caption="ID АЗК" />
              <Column dataField="terminalName" caption="Назва АЗК" /> 
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="fuelName" caption="Паливо" />
              <Column dataField="talons" encodeHtml={false} caption="Талони" allowExporting={false} />
              <Column dataField="talonsExport" caption="Талони" visible={false} />
              <Column dataField="dispenserId" caption="ПРК №" dataType="number" /> 
              <Column dataField="trkId" caption="Кран №" dataType="number" />         
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="ordered" caption="Замовлено, л." dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="shiftId" caption="Зміна №"  dataType="number" />
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="transactionId" caption="Транзак. №" dataType="number" /> 
              <Column dataField="userName" caption="Оператор" />                                       
                           
              <Summary>
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkNum" displayFormat="К-ть: {0}" summaryType="count" />
              </Summary>
            </DataGrid>

            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeTalonsReportCheckUseTalons);