import React, { Component } from 'react';
import DataGrid, { Column, ColumnChooser, StateStoring, Export, Lookup, Paging, Pager } from 'devextreme-react/data-grid';
//import { Tooltip } from 'devextreme-react/tooltip';
import '../settings.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { configApp } from '../../../config';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import $ from "jquery";

class SettingsUserList extends Component {  

    constructor(props) {
        super(props);
        this.state = {
            userList : []
        }        
    }
    
    onAddUser() {
        this.props.childState({
            mainGrid : false,
            addUser : true
        });
    }

    onEditUser(e) { 
        this.props.childState({            
            mainGrid : false,
            editUser : true,
            editUserId : e.row.data.id
        });
        e.event.preventDefault();
    }

    componentDidMount() {
        this.getUserData();
    }

    componentDidUpdate(e) {        
        if(!e.parentState.mainGrid && this.props.parentState.mainGrid) {
            this.getUserData();
        }
        
    }

    getUserData() {
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Users', {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
       .then((response) => {    
          let _list = response.data.filter(item => item.userTypeId > this.props.auth.UserType);  
          
          // для кассовиков отдельно еще запрещаем видить сотрудников ЦО
          if(this.props.auth.UserType === 20) {
              _list = _list.filter(item => item.userTypeId >= 200)
          }

          this.setState({
              userList : _list
          });            
          this.props.onLoading(false);
       })
       .catch((error) => {
           this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error', 1000);
              }
           else {
               notify('Не вдалося завантажити дані', 'error', 1000);               
           }
         });
    }

    calculateBlock(rowData) {
        return rowData.isBlocked === 0 ? true : false;
    }

    onCellPrepared(e) {
        if (e.rowType === "data" && e.columnIndex === 2) {  
            // let tooltip = this.dxTooltip;
            let that = this;
            e.cellElement.addEventListener("mouseover", function() {  
                //console.log(e);              
                //tooltip.instance.option('contentRender',);
                //that.setState({test: 2});
                //tooltip.instance.show(e.cellElement);
                //console.log(that.state);
                if(e.data.userTerminals && e.data.userTerminals.length > 0) {
                    $(e.cellElement).attr('title', that.setTitleTerminals(e.data.userTerminals))
                }                
            });    
            // e.cellElement.addEventListener("mouseout", function() {
            //     //tooltip.instance.hide(); 
            // });
          }            
    }

    setTitleTerminals(e) {
        let _terms = "АЗК користувача:\n";        
        e.forEach(item => {            
            let term = this.props.userTabs.terminals.find(item2 => item2.terminalId === item.terminalId); 
            if(term !== undefined) {
                _terms += term.terminalId + ' ' + term.name + '\n';
            }                       
        });
        return _terms;
    }

  render() {    
     return (             
          <div style={{marginTop: "15px"}}>
            <div id="mainGrid">
            {/* <Button color="success" className="add-user" onClick={this.onAddUser.bind(this)}>Додати користувача</Button> */}
            <Button                            
                text="Додати користувача"
                type="success"
                stylingMode="contained" 
                onClick={this.onAddUser.bind(this)}                        
            />

            {/* <Tooltip ref={(ref) => this.dxTooltip = ref}>
                <>{this.state.test}</>
            </Tooltip> */}
            <DataGrid
              id="userGridContainer"              
              dataSource={this.state.userList}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}   
              rowAlternationEnabled={true}     
              filterRow={{applyFilter : true, visible : true}} 
              onCellPrepared={this.onCellPrepared.bind(this)}
              >
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[25, 50, 100]}
                showInfo={true} />    
              <ColumnChooser enabled={true} />
              <Export enabled={true} fileName="SettingsUsers" />              
              <StateStoring enabled={true} type="localStorage" storageKey="gridSettingsUser" />
              <Column dataField="id" caption="ID"  />
              <Column dataField="name" caption="Користувач"  />
              <Column dataField="useActiveDirectoryCheck" dataType='boolean' caption="Авториз.AD" />
              <Column dataField="activeDirectoryName" caption="Користувач AD" />
              <Column dataField="displayName" caption="Ім'я"  />
              <Column dataField="userTypeId" caption="Тип користувача">
                <Lookup dataSource={this.props.userTabs.userTypes} valueExpr="id" displayExpr="name" />                
              </Column>
              <Column dataField="eMail" caption="E-Mail" />
              <Column dataField="inn" caption="ІПН" />
              <Column dataField="phone" caption="Телефон" />
              {/* <Column dataField="comment" caption="Коментар"  /> */}
              <Column dataField="lowDateTrashholdDays" caption="К-ть дн.звітів" />
              <Column dataField="isBlocked" dataType="boolean" calculateCellValue={this.calculateBlock} caption="Актив."  />
              <Column dataField="userProfile.profileName" caption="Профіль"  />
              <Column type="buttons" buttons={[{
                    hint: 'Редагувати',
                    icon: 'edit',
                    visible: true,
                    onClick: this.onEditUser.bind(this) }]} />
            </DataGrid>

            </div>
          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      settings: state.settings,
      userTabs: state.userTabs      
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(SettingsUserList);