import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, StateStoring, GroupPanel, HeaderFilter, MasterDetail } from 'devextreme-react/data-grid';

class OfficeGeneralReportTerminalsInfo extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dataGrid : []
    }
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsGeneral/TerminalsInfo?type=3', {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
        this.props.onLoading(false);
        this.proccessData(response.data);            
   })
   .catch((error) => {
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

proccessData(e) {
  let _dataGrid = [];
  const LAST_SHIFT_HOURS = 25; // hours

  e.forEach(el => {
    let row = {};
    row.regionName = el.regionName;
    row.terminalId = el.terminalId;
    row.terminalName = el.terminalName;
    row.geo = el.geo;
    row.address = el.address;
    
    if(el.lastShift) {
      let _dateNow = new Date();
      let _dateOpen = new Date(el.lastShift.datOpen);
      _dateOpen = new Date(new Date(_dateOpen).setHours(_dateOpen.getHours() + LAST_SHIFT_HOURS));
      if(_dateOpen > _dateNow && !el.lastShift.datClose) {
        row.status = 'активна';
        row.statusBoolean = true;
      }
      else {
        row.status = 'не активна';
        row.statusBoolean = false;
      }

      if(el.lastSales) {
        let fuelDet = [];
        el.lastSales.forEach(sf => {
          let rowSF = {};
          rowSF.fuelId = sf.fuelId;
          rowSF.fuelName = sf.fuelName;
          rowSF.lastSaleDat = sf.lastSale;
          rowSF.price = sf.price;
          rowSF.countChecks = sf.countChecksLastTime;
          fuelDet.push(rowSF);
        });

        if(el.restGauges) {
          el.restGauges.forEach(rg => {            
            fuelDet.forEach((fd, index) => {
              if(fd.fuelId === rg.fuelId) {
                let _fd = fuelDet[index];
                _fd.fuelRestGauge = rg.fuelRest;
                _fd.gaugeDat = rg.actualDate;
                _fd.fuelNameShort = rg.fuelName;
                fuelDet[index] = _fd;
              }
            });
          });
        }
        row.fuelDet = fuelDet;
      }
    }
    _dataGrid.push(row);
  });

  this.setState({
    dataGrid: _dataGrid
  });
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    if(e.rowType === 'data') {
      if(e.columnIndex === 4) {
        e.cellElement.style["text-align"] = 'center'; 
        if(e.value === 'активна') {
          e.cellElement.classList.add('data-grid-success-text');
        }      
        else {
          e.cellElement.classList.add('data-grid-error-text');
        }          
      }
    }
  }
  
  onCellPreparedDet(e) {
    const LAST_FUEL_SALE = 3; //hours
    const LAST_GAUGE_ACTUAL = 3; //hours
    const MIN_GAUGE_REST = 1000; // liters

    if(e.rowType === 'data') {
      if(e.columnIndex === 4) { 
        let _dateNow = new Date();
        let _dateLastSale = new Date(e.value);
        _dateLastSale = new Date(new Date(_dateLastSale).setHours(_dateLastSale.getHours() + LAST_FUEL_SALE));

        if(_dateLastSale < _dateNow) {
          e.cellElement.classList.add('data-grid-error-text');
        }

      }
      else if(e.columnIndex === 6) {
        if(e.value < MIN_GAUGE_REST) {
          e.cellElement.classList.add('data-grid-error-text');
        }
      }
      else if(e.columnIndex === 7) {
        let _dateNow = new Date();
        let _dateActual = new Date(e.value);
        _dateActual = new Date(new Date(_dateActual).setHours(_dateActual.getHours() + LAST_GAUGE_ACTUAL));

        if(_dateActual < _dateNow) {
          e.cellElement.classList.add('data-grid-error-text');
        }
      }
    }
  }
  
TermInfoDet(e) {
  return (
    e.data.data.statusBoolean ? e.data.data.fuelDet ?  
    <div>
    <div><b>Інформація по АЗК</b></div>
    <DataGrid
          ref={(ref) => this.dataGridDet = ref}
          id="gridTermInfoDet"              
          dataSource={e.data.data.fuelDet}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          onCellPrepared={this.onCellPreparedDet.bind(this)}        
          rowAlternationEnabled={true}
          filterRow={{applyFilter : true, visible : true}}>                            
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20]}
            showInfo={true} />                
          <Column dataField="fuelId" caption="Паливо ID" /> 
          <Column dataField="fuelName" caption="Паливо назва" />
          <Column dataField="fuelNameShort" caption="Паливо" /> 
          <Column dataField="price" caption="Ціна" />               
          <Column dataField="lastSaleDat" caption="Остан.продаж" dataType="datetime" /> 
          <Column dataField="countChecks" caption="К-ть чеків за період" dataType="number" />
          <Column dataField="fuelRestGauge" caption="Залишок, л. (рівнемір)" />       
          <Column dataField="gaugeDat" caption="Актуал. даних (рівнемір)" dataType="datetime" />               
        </DataGrid> 
    </div> : <div style={{textAlign: 'center', color: '#CCC', fontWeight: 'bold'}}>По АЗК немає детальних даних</div> : <div style={{textAlign: 'center', color: '#CCC', fontWeight: 'bold'}}>АЗК не активна</div>
  );
}  

render() {
  return (
    <div style={{marginTop : "10px"}}>
     <div style={{marginTop: "15px"}}>
     <DataGrid
       ref={(ref) => this.dataGrid = ref}
       id="gridOfficeReportTermInfo"              
       dataSource={this.state.dataGrid}
       allowColumnReordering={true}
       allowColumnResizing={true}
       columnAutoWidth={true}
       showBorders={true}         
       rowAlternationEnabled={true}
       onToolbarPreparing={this.onToolbarPreparing.bind(this)}  
       onCellPrepared={this.onCellPrepared.bind(this)}   
       filterRow={{applyFilter : true, visible : true}} >
       <HeaderFilter visible={true} />           
       <Export enabled={true} fileName={'ReportTermInfo'} />              
       <StateStoring enabled={true} type="localStorage" storageKey="OfficeReportTermInfo" />           
       <Paging defaultPageSize={50} />
       <GroupPanel visible={true} />
       <Pager
         showInfo={true}
         showPageSizeSelector={true}
         allowedPageSizes={[50,100,200,300]}
       />
       <Column dataField="regionName" caption="Регіон" groupIndex={0} />
       <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
       <Column dataField="terminalName" caption="Назва АЗК" />
       <Column dataField="status" caption="Статус" />
       <Column dataField="geo" caption="GEO" />
       <Column dataField="address" caption="Адреса" width={500} />
       <MasterDetail
          enabled={true}
          component={this.TermInfoDet.bind(this)} />                
     </DataGrid>
     </div>
   </div>
    ) 
  }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeGeneralReportTerminalsInfo);