import React, { Component } from 'react';
import Form, {
    SimpleItem,
    Label,
    RequiredRule,
    ButtonItem
  } from 'devextreme-react/form';
  import DataGrid, { Column, Paging, Selection } from 'devextreme-react/data-grid';  
import TextArea from 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import '../settings.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { configApp } from '../../../config';
import { push } from 'connected-react-router';

class SettingsEditProfile extends Component {  

     constructor(props) {
         super(props); 
         this.form = React.createRef();         
         this.state = {
             profile : {},
             selectedRowKeys : []
         }

         this.handleSubmit = this.handleSubmit.bind(this);
         this.cancelClick = this.onCancelClick.bind(this);
    }

    handleSubmit(e) {        
        e.preventDefault(); 

        if(!this.state.profile.profileName) {
            notify('Введіть назву профіля','error',1000);
            return;
        }

        if(this.state.selectedRowKeys.length <= 0) {
            notify('Виберіть хоча б один звіт зі списку','error',1000);
            return;
        }


        const _profile = this.state.profile;
        const _actions = this.state.selectedRowKeys;
        let _profilesActions = [];

        _actions.forEach(item => {
            _profilesActions.push({actionName: item})
        });
        
        _profile.profilesActions = _profilesActions;
        _profile.isBlocked = _profile._isActive ? 0 : 1;

        this.props.onLoading(true);
        axios.put(configApp.API_CORE + '/Profiles/ChangeProfile', _profile, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {                    

            notify('Профіль успішно збережено', 'success', 1000);   
            this.props.onLoading(false);
            //e.target.reset();
            this.form.current.reset();
            this.props.childState({
                mainGrid: true,
                editProfile : false
            });        
        })
        .catch((error) => {
            if (error.response && error.response.data.message) {     
                notify(error.response.data.message, 'error', 1000);     
            } 
            else {
                notify('Не вдалося зберегти профіль', 'error', 1000);
            }                       
            this.props.onLoading(false);
          });
      }

    onCancelClick() {        
        this.form.current.reset();
        this.props.childState({
            mainGrid: true,
            editProfile : false
        }); 
    }

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
        this.setState({
          selectedRowKeys
        });
    }

    renderProfileActions() {
        return(
            <>
            <div style={{fontSize: '13px', marginBottom: '10px'}}><b>Звіти профілю</b></div>
            <DataGrid
                dataSource={this.props.profileTabs.actions.filter(i => i.type !== 'Manager')}
                showBorders={true}
                keyExpr="actionName"
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}  
                columnAutoWidth={true}
                defaultSelectedRowKeys={this.state.selectedRowKeys}
                onSelectionChanged={this.onSelectionChanged.bind(this)}>
                <Paging defaultPageSize={1000} />    
                <Selection
                    mode="multiple"
                    selectAllMode="allPages"
                    showCheckBoxesMode="always" />
                <Column dataField="type" caption="Тип" groupIndex={0} />
                <Column dataField="subType" caption="Группа" groupIndex={1} />    
                <Column dataField="actionName" caption="ID звіту" />
                <Column dataField="title" caption="Назва звіту" />
                <Column dataField="visible" caption="Видимий" dataType="boolean" />
                <Column type="buttons" buttons={[{
                    hint: 'перейти',
                    text : 'перегляд',
                    visible: true,
                    onClick: this.props.onRedirect }]} />
            </DataGrid>
            </>
        );
    }

    componentDidMount() {
        let _profile = this.props.parentState.profileEdit;
        _profile._isActive = _profile.isBlocked === 0 ? true : false;

        let _initSelRowKeys = [];
        _profile.profilesActions.forEach(item => {
            _initSelRowKeys.push(item.actionName);
        });

        this.setState({
            profile : _profile,
            selectedRowKeys : _initSelRowKeys
        });
    }

  render() {    
    const { profile } = this.state;
    
     return (             
          <div style={{marginTop: "15px", maxWidth: "80%", margin: "auto"}}>
            <div id="editProfile">
            <div className="header">Редагування профілю</div>
            <form onSubmit={this.handleSubmit} ref={this.form}>
            <Form
                formData={profile}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true} 
                validationGroup="profileData" >
                
                <SimpleItem dataField="profileName" editorType="dxTextBox">
                    <Label text="Назва"></Label>
                    <RequiredRule message="Назва - обов'язкове поле" />                
                </SimpleItem>
                <SimpleItem dataField="comment" editorType="dxTextArea">
                    <Label text="Коментар"></Label>                
                </SimpleItem>                
                <SimpleItem dataField="_isActive" editorType="dxCheckBox">
                    <Label text="Активний"></Label>                    
                </SimpleItem>

                <SimpleItem render={this.renderProfileActions.bind(this)}></SimpleItem>

                <ButtonItem  itemType="button"             
                    buttonOptions={{
                        text: 'Зберегти',
                        type: 'success',
                        useSubmitBehavior: true
                    }}
              />
              <ButtonItem itemType="button"              
                buttonOptions={{
                    text: 'Відміна',
                    type: 'normal',
                    onClick : this.onCancelClick.bind(this)
                  }}
              />
            </Form>                     
            </form>            
            </div>

            {/* просто чтобы VS Code не давал предупреждения */}
            <TextArea visible={false} />

          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      userTabs: state.userTabs,
      profileTabs: state.profileTabs,
      router: state.router       
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        },
        onRedirect(item) {
            dispatch(push(item.row.data.path))
        }
    })
  )(SettingsEditProfile);