import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatOne from '../../datOne';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeFuelReportFuelSalesAmountChecks extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          date : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : []
        }
    }

    onChangeDate(e) {
        this.setState({
            date : e.date             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {     
        let _date = Moment(this.state.date).format('yyyy-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/FuelSalesAmountChecks?date=' + _date,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

      onCalculatePercentAmount(e) {
        return Math.round(((e.amountWoKL * 100) / e.amountAll) * 100) / 100;
      }

      onCalculatePercentCount(e) {
        return Math.round(((e.checkWoKL * 100) / e.checkAll) * 100) / 100;
      }

    render() {

      let _date = Moment(this.state.dateFrom).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatOne defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />                        
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelSalesAmountChecks"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelSalesAmountChecks_' + _date} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelSalesAmountChecks" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <ColumnChooser enabled={true} />                  
              <Column dataField="fuelName" caption="Паливо" />       
              <Column dataField="amountAll" caption="Загал.пролив, л." dataType="number" />              
              <Column dataField="amountWoKL" caption="Пролив без КЛ, л." dataType="number" />
              <Column caption="Частка, %" dataType="number" calculateCellValue={this.onCalculatePercentAmount.bind(this)} format={{precision: 2}} />
              <Column dataField="checkAll" caption="Загал. к-ть чеків" dataType="number" />              
              <Column dataField="checkWoKL" caption="К-ть чеків без КЛ" dataType="number" />
              <Column caption="Частка, %" dataType="number" calculateCellValue={this.onCalculatePercentCount.bind(this)} format={{precision: 2}} />
              <Column dataField="avgPrice" caption="Середня ціна" dataType="number" format={{precision: 2}} />
              <Column dataField="avgPriceWoKL" caption="Середня ціна без КЛ" dataType="number" format={{precision: 2}} />

              <Summary>
                <TotalItem column="amountAll" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="amountWoKL" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkAll" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkWoKL" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="avgPrice" displayFormat="Сума: {0}" summaryType="avg">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="avgPriceWoKL" displayFormat="Сума: {0}" summaryType="avg">
                <ValueFormat precision={2} />
                </TotalItem>
              </Summary>
            </DataGrid>
            </div>

          <span style={{fontSize: '10px', marginTop: '10px', marginLeft: '10px'}}>* - дані без урахування продажів ТіК</span>
          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelSalesAmountChecks);