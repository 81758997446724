import { userStore } from '../stores/initStores';

export default function user(state = userStore, action) {
    if (action.type === 'GET_USER_DATA') { 
      return action.payload;
    }
    else if (action.type === 'CLEAR_USER_DATA') { 
      return action.payload;
    }     
    return state;
  }