import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, ColumnChooser, MasterDetail } from 'devextreme-react/data-grid';
import { TabPanel } from 'devextreme-react/tab-panel';
import { Item } from 'devextreme-react/form';
import Moment from 'moment';

class OfficeGeneralReportBankTransactionsCancelPayment extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}).add(-3,'days'),
        dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        dataGrid : []
    }
}

onChangeDates(e) {
  this.setState({
      dateFrom : e.dateFrom,
      dateTo : e.dateTo             
  });
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
    let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
    let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsGeneral/BankTransactionsCancelPayment?dateFrom=' + _datFrom + '&dateTo=' + _datTo, 
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
        this.props.onLoading(false);
      this.setState({
          dataGrid : response.data
      });            
   })
   .catch((error) => {
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    // if(e.rowType === 'data') {
    //   if(e.columnIndex === 11) {
    //     if(e.value !== 0) {
    //       e.cellElement.classList.add('data-grid-error');
    //     }
    //   }
    // }        
  }

  TransDetailed(e) {
    return (
        <div>
          <div style={{marginBottom: 10}}><b>Додаткова інформація</b></div>
          <TabPanel>
            <Item title="Банк. транзакція" render={this.renderBankTransTab.bind(this, e)} />
            <Item title="Чек ПММ" render={this.renderSalesFuelsTab.bind(this, e)} />
        </TabPanel>           
        </div>
      );
}

renderBankTransTab(e) {
  const ds = [];
  ds.push(e.data.data.bankTransRow);
  return (
    <div style={{padding: 15}}>
      <DataGrid
            id="gridBankTransTab"
            ref={(ref) => this.gridBankTransTab = ref}              
            dataSource={ds}
            allowColumnResizing={true}
            key="id"
            columnAutoWidth={true}
            showBorders={true}
            rowAlternationEnabled={true}
            filterRow={{applyFilter : true, visible : true}}>                            
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 15, 20, 50]}
              showInfo={true} />  
          </DataGrid>
          </div>
  )
}

renderSalesFuelsTab(e) {
  const ds = [];
  if(e.data.data.salesFuelRow !== null) {
    ds.push(e.data.data.salesFuelRow);
  }
  return (
    <div style={{padding: 15}}>
      <DataGrid
            id="gridSalesFuelsTab"
            ref={(ref) => this.gridSalesFuelsTab = ref}              
            dataSource={ds}
            allowColumnResizing={true}
            key="id"
            columnAutoWidth={true}
            showBorders={true}
            rowAlternationEnabled={true}
            filterRow={{applyFilter : true, visible : true}}>                            
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 15, 20, 50]}
              showInfo={true} />
          </DataGrid>
          </div>
  )
}

render() {

  let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
  let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

  return (

    <div style={{marginTop : "10px"}}>
     <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
     <div style={{marginTop: "10px"}}>
     <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridBankTransCancelPayment"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'BankTransCancelPayment_' + _dateFrom + '_' + _datTo} />               
           <StateStoring enabled={true} type="localStorage" storageKey="BankTransCancelPayment" />
           <Paging defaultPageSize={50} />
           <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 300]}
                showInfo={true} />
           <ColumnChooser enabled={true} />

           <Column dataField="terminalId" caption="ID АЗК" />                      
           <Column dataField="terminalName" caption="Назва АЗК" />                         
           <Column dataField="bankTransRow.dat" caption="Дата" dataType="datetime" />
           <Column dataField="bankTransRow.shiftId" caption="№ зміни" dataType="number" /> 
           <Column dataField="fuelName" caption="Назва палива" />
           <Column dataField="bankTransRow.amount" caption="Сума, грн."  dataType="number"/>
           <Column dataField="salesFuelRow.checknum" caption="№ Чека" dataType="number" />
           <Column dataField="salesFuelRow.checknumReturn" caption="№ Чека повер." dataType="number" />
           <Column dataField="bankTransRow.actionName" caption="Тип операції" />
           <Column dataField="user" caption="Оператор" />
           <MasterDetail
                enabled={true}
                component={this.TransDetailed.bind(this)} /> 
         </DataGrid>
     </div>

   </div>
 ) 
}}

export default connect(
state => ({
  auth : state.auth,  
  settings : state.settings,
  user : state.user
}),
dispatch => ({
    onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
})
  )(OfficeGeneralReportBankTransactionsCancelPayment);