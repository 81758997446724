import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, Summary, TotalItem, ValueFormat, StateStoring, ColumnChooser } from 'devextreme-react/data-grid';

class OfficeFuelReportFuelRestGauges extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dataGrid : []
    }
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {     

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsFuel/ReportFuelRestGauges',
     {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
      this.props.onLoading(false);
      this.setState({
          dataGrid : response.data
      });            
   })
   .catch((error) => {
      this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }}, {
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }
render() {

  return (

    <div style={{marginTop : "10px"}}>
        <div style={{marginTop: "15px"}}>
        <DataGrid
          ref={(ref) => this.dataGrid = ref}
          id="gridReportFuelRestGauges"              
          dataSource={this.state.dataGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}        
          rowAlternationEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
          filterRow={{applyFilter : true, visible : true}} >                            
          <Export enabled={true} fileName={'ReportFuelRestGauges'} />           
          <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelRestGauges" />
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 100, 200, 300]}
            showInfo={true} />         
          <ColumnChooser enabled={true} />         
          <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
          <Column dataField="terminalName" caption="Назва АЗК" />                       
          <Column dataField="fuelName" caption="Паливо" />         
          <Column dataField="tankId" caption="Резервуар №" dataType="number" />
          <Column dataField="fuelRest" caption="Залишок, л." dataType="number" />               
          <Column dataField="tankUnfilledVolume" caption="Незапов.об'єм, л." dataType="number" />
          <Column dataField="tankVolume" caption="Загал.об'єм, л." dataType="number" />
          <Column dataField="actualDate" caption="Дата актуал." dataType="datetime" />  
          <Summary>
            <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
            <ValueFormat precision={2} />
            </TotalItem>
            <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
            <ValueFormat precision={2} />
            </TotalItem>
            <TotalItem column="discountSumma" displayFormat="Сума: {0}" summaryType="sum">
            <ValueFormat precision={2} />
            </TotalItem>
          </Summary>
        </DataGrid>
        </div>

      </div>
    )
}
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelRestGauges);