import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import './navMenu.css';
import { push } from 'connected-react-router';
import { reportsStore } from '../../stores/initStores';
import { routesStore } from '../../stores/initStores';

class MenuManager extends Component {

  constructor (props) {
      super(props);

      this.state = {
         lastUserId : 0,
         managerReports : []
      };
  }

  componentDidUpdate() {
    this.updateMenu();  
  }

  componentDidMount() {
    this.updateMenu();
  }

  updateMenu() {
    if(this.props.user.id && this.state.lastUserId !== this.props.user.id) {
      let _reports = reportsStore.filter(i => i.type === 'Manager');
      let _managerReports = [];
      _reports.forEach(element => {
          let _route = routesStore.find(f => f.id === element.routeId);
          element.title = _route.title;
          element.path = _route.path;
          _managerReports.push(element);
      });
  
  
        // отбираем отчеты в зависимости от типа учетки и/или профиля
        // если рут или админ - показываем все
        // если СБ или рег - тоже все    
        if([1,10,50,150].includes(this.props.auth.UserType)) {
          this.setState({
            lastUserId : this.props.user.id,
            managerReports : _managerReports
          });
          return;
      }
  
      // в ином случае, проверяем профиль пользователя
      let _userProfile = this.props.user.userProfile;
      if(_userProfile && _userProfile.isBlocked === 0) {
          if(_userProfile.profilesActions && _userProfile.profilesActions.length > 0) {
              let _filterManagerReports = [];
              _userProfile.profilesActions.forEach(item => {
                  let item2 = _managerReports.find(f => f.actionName === item.actionName);
                  if(item2) {
                    _filterManagerReports.push(item2);
                  }
              });
  
              this.setState({
                lastUserId : this.props.user.id,
                managerReports : _filterManagerReports
              });
          }
      }
    }    
  }

  onRoute(item) {
    this.props.onRoute(item);
  }

  render () {
    
    const locale = "uk";
    const options = { sensitivity: "base" };

    const { managerReports } = this.state;
    const reportsManagerFuel = managerReports.filter(i => i.subType === 'Fuel').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsManagerGoods = managerReports.filter(i => i.subType === 'Goods').sort((a, b) => a.title.localeCompare(b.title, locale, options));
    const reportsManagerOther = managerReports.filter(i => i.subType === 'Other').sort((a, b) => a.title.localeCompare(b.title, locale, options));

    return (
        <>
        {reportsManagerFuel.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>ПММ</DropdownToggle>
        <DropdownMenu>
          {
            reportsManagerFuel.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}
        
        {reportsManagerGoods.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Товари</DropdownToggle>
        <DropdownMenu>
          {
            reportsManagerGoods.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

        {reportsManagerOther.length > 0 ? 
        
        <UncontrolledDropdown nav inNavbar active>
        <DropdownToggle nav caret>Інше</DropdownToggle>
        <DropdownMenu>
          {
            reportsManagerOther.map((item) => <DropdownItem key={item.actionName} onClick={this.onRoute.bind(this, item.path)}>{item.title}</DropdownItem>)
          }
        </DropdownMenu>
        </UncontrolledDropdown>
    
        : <></>}

    </>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    user: state.user    
  }),
  dispatch => ({
    onRoute: (item) => {
      dispatch(push(item))
    }}
))(MenuManager);
