import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsUserList from './user-list';
import SettingsAddUser from './add-user';
import SettingsEditUser from './edit-user';
import { getDataUser, clearDataUser } from '../../../actions/user-tabs';
import '../settings.css';

class SettingsUsersTab extends Component {  

    constructor(props) {  
         super(props);         
         this.state = {
           mainGrid : true,
           addUser : false,
           editUser : false           
         }         
    }

    changeState(e) {
      this.setState(e);
    }

    componentDidMount() {
      this.props.onGetDataUserTabs();      
    }

    componentWillUnmount() {
      this.props.onClearUserTabsData();
    }

  render() {    
     return (             
          <div style={{marginTop: "15px"}}>
            {this.state.mainGrid ? <SettingsUserList parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}
            {this.state.addUser ? <SettingsAddUser parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}
            {this.state.editUser ? <SettingsEditUser parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}                        
            
          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      router : state.router,
      settings : state.settings
    }),
    dispatch => ({
         onGetDataUserTabs: () => {
           dispatch(getDataUser());
        },
        onClearUserTabsData: () => {          
           dispatch(clearDataUser());
        }
    })
  )(SettingsUsersTab);