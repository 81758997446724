import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import TextArea from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';
import fileDownload from 'js-file-download';

class OfficeTalonsReportAbuseTalons extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            talons : []
        }
    }

    onChangeList(e) {
      let _list = e.value.split('\n');

      this.setState({
        talons: _list.filter(e => e)
      });
    }

    onCheckTalons() {
      if(!this.state.talons || this.state.talons.length === 0) {
        notify('Вкажіть список талонів', 'error');
        return;
      }

      this.onExecute();

    }

    onExecute() {             

      let _fileName = 'ReportAbuseTalons.xlsx';

        this.props.onLoading(true);
        axios.post(configApp.API_CORE + '/ReportsTalons/ReportAbuseTalons', this.state.talons, {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);
       })
       .catch((error) => {
            this.props.onLoading(false);
            console.log(error.response);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
           }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    render() {

      return (

        <div style={{marginTop : "15px"}}>
        <div id="title-area">
          <span>Список талонів:</span>
        </div>
        <div id="talons-abuse">
         <div>
         <TextArea
            height={300}
            width={200}
            onValueChanged={this.onChangeList.bind(this)} />
            <div className='small'>
              * - кожен талон з нового рядка
            </div>
            </div>
            <div style={{marginLeft: '10px'}}>
            <Button                            
                  text="Перевірити"
                  type="normal"
                  icon="check"
                  stylingMode="outlined" 
                  onClick={this.onCheckTalons.bind(this)}                        
              />
            </div>
        </div>
       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(OfficeTalonsReportAbuseTalons);