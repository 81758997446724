import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatOne from '../../datOne';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Summary, TotalItem, ValueFormat } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeCardsReportEngineWSBalance extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        date : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        dataGrid : []
    }
}

onChangeDate(e) {
  this.setState({
      date : e.date             
  });
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
    let _date = Moment(this.state.date).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsCards/ReportEngineWSBalance?dat=' + _date, 
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
        this.props.onLoading(false);
      this.setState({
          dataGrid : response.data
      });      
      
      this.dataGrid.instance.columnOption("balanceEnd", "caption", "Баланс на " + Moment(this.state.date).format('DD.MM.YYYY 23:59'));
      this.dataGrid.instance.columnOption("currentBalance", "caption", "Поточний баланс на " + Moment(new Date()).format('DD.MM.YYYY HH:mm')); 
           
   })
   .catch((error) => {
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    // if(e.rowType === 'data') {
    //   if(e.columnIndex === 11) {
    //     if(e.value !== 0) {
    //       e.cellElement.classList.add('data-grid-error');
    //     }
    //   }
    // }        
  }

render() {

  let _date = Moment(this.state.date).format('yyyyMMDD');

  return (

    <div style={{marginTop : "10px"}}>
     <DatOne defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />
     <div style={{marginTop: "10px"}}>
     <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridReportEngineWSBalance"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'EngineWSBalance_' + _date} />               
           <StateStoring enabled={true} type="localStorage" storageKey="EngineWSBalance" />
           <Paging defaultPageSize={100} />

           <Column dataField="terminalId" caption="ID АЗК" dataType="number" /> 
           <Column dataField="terminalName" caption="АЗК назва" /> 
           <Column dataField="clientId" caption="Код клієнта" dataType="number" />                        
           <Column dataField="clientName" caption="Клієнт назва" /> 
           <Column dataField="cardId" caption="ID картки" dataType="number" />
           <Column dataField="cardVisible" caption="Номер картки" />
           <Column dataField="accountId" caption="ID рахунку" dataType="number" />
           {/* <Column dataField="accountName" caption="Рахунок" />  */}
           <Column dataField="accountType" caption="Тип рахунку" />
           <Column dataField="fuelId" caption="ID палива" dataType="number" />
           <Column dataField="fuelName" caption="Назва палива" />
           <Column dataField="balanceEnd" dataType="number" />
           <Column dataField="currentBalance" dataType="number" />           

           <Summary>
             <TotalItem column="balanceEnd" displayFormat="Сума: {0}" summaryType="sum">
               <ValueFormat precision={2} />
             </TotalItem>
             <TotalItem column="currentBalance" displayFormat="Сума: {0}" summaryType="sum">
                 <ValueFormat precision={2} />
             </TotalItem>
           </Summary>

         </DataGrid>
     </div>

   </div>
 ) 
}}

export default connect(
state => ({
  auth : state.auth,  
  settings : state.settings,
  user : state.user
}),
dispatch => ({
    onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
})
  )(OfficeCardsReportEngineWSBalance);