import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import DatOne from '../../datOne';
import { CheckBox } from 'devextreme-react/check-box';


class OfficeCardsReportRevisePortEngineFuels extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          date : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          onlyT2: 0
        }              
    }

    onChangeDate(e) {
      this.setState({
          date : e.date            
      });
    }

    onB2ValueChanged(e) {
      this.setState({
        onlyT2: e.value ? 1 : 0,
      });
    }

    onExecute() {

        let _date = Moment(this.state.date).format('YYYY-MM-DD');
        let _fileName = 'ReportRevisePortEngineFuels_' + _date + '.xlsx';

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsCards/ReportRevisePortEngineFuels?date=' + _date + '&onlyT2=' + this.state.onlyT2,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {         
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    render() { 
    
      return (
          
        <div style={{paddingTop : "10px"}}>
            <div className="container-with-date" >
              <DatOne defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />
              <div id="datFromdatTo" style={{marginBottom: "5px", marginLeft: "10px"}}>
                <CheckBox defaultValue={false} onValueChanged={this.onB2ValueChanged.bind(this)} text="тільки Б2" />
              </div> 
            </div>
            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
            </div>

        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeCardsReportRevisePortEngineFuels);