import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkAccessRoute } from '../../actions/auth';
import Tabs from 'devextreme-react/tabs';
import './settings.css';
import SettingsUsersTab from './users-tab/users-tabs';
import SettingsProfilesTab from './profiles-tab/profiles-tabs';
import { tab3 } from './tab3';

const tabs = [
  {
    id: 0,
    text: 'Користувачі',
    icon: 'group'
  },
  {
    id: 1,
    text: 'Профілі',
    icon: 'bulletlist'
  },
  {
    id: 2,
    text: 'Інше',
    icon: 'overflow'
  }
];

function selectedContent(item) {
  switch (item) {
    case 0:
      return <SettingsUsersTab />;
    case 1:
      return <SettingsProfilesTab />;
    case 2:
      return tab3();
    default:
      return (<></>);
  }  
}


class Settings extends Component {  

    constructor(props) {  
        super(props);        
        this.state = {
          selectedIndex : 0,
          isAccess : false
        }
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        this.selectedContent = selectedContent.bind(this);
    }

    componentDidMount() {
      this.setState({
        isAccess : checkAccessRoute(this.props)
      });
    }

    onTabsSelectionChanged(args) {
      if(args.name === 'selectedIndex') {
        this.setState({
          selectedIndex: args.value
        });
      }
    }
  
  render() {
    const { selectedIndex } = this.state;
     return (
          <>             
          {this.state.isAccess ? 
          <div id="tabs-settings">
            <Tabs
              dataSource={tabs}
              selectedIndex={selectedIndex}
              onOptionChanged={this.onTabsSelectionChanged} />
            {this.selectedContent(selectedIndex)}
          </div> :
          <></>}
          </>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      settings : state.settings
    }),
    dispatch => ({
      
    })
  )(Settings);