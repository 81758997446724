import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsProfileList from './profile-list';
import SettingsAddProfile from './add-profile';
import SettingsEditProfile from './edit-profile';
import { getDataProfile, clearDataProfile } from '../../../actions/profile-tabs';
import '../settings.css';

class SettingsProfilesTab extends Component {  

    constructor(props) {  
         super(props);         
         this.state = {
           mainGrid : true,
           addProfile : false,
           editProfile : false           
         }         
    }

    changeState(e) {
      this.setState(e);
    }

    componentDidMount() {
      this.props.onGetDataProfileTabs();      
    }

    componentWillUnmount() {
      this.props.onClearProfileTabsData();
    }

  render() {    
     const isAllow = this.props.user.userTypeId <= 10 ? true : false; // разрешаем доступ только для Root или Admin
     return (

      <>{isAllow ? 
      
      <div style={{marginTop: "15px"}}>
            {this.state.mainGrid && this.props.profileTabs.actions.length > 0 ? <SettingsProfileList parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}
            {this.state.addProfile && this.props.profileTabs.actions.length > 0 ? <SettingsAddProfile parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}
            {this.state.editProfile && this.props.profileTabs.actions.length > 0 ? <SettingsEditProfile parentState={this.state} childState={this.changeState.bind(this)} /> : <></>}           
      </div>
      
      : 
      
      <div style={{display : "flex", alignContent: "center", justifyContent: "center", marginTop: "20px"}}>
        Розділ не доступний
      </div>

      }</>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      router : state.router,
      settings : state.settings,
      profileTabs: state.profileTabs
    }),
    dispatch => ({
        onGetDataProfileTabs: () => {
           dispatch(getDataProfile());
        },
        onClearProfileTabsData: () => {          
           dispatch(clearDataProfile());
        }
    })
  )(SettingsProfilesTab);