import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging, Summary, TotalItem, ValueFormat, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';
// import TextBox from 'devextreme-react/text-box';
// import fileDownload from 'js-file-download';
import SelectTerminalOffice from '../../selectTerminalOffice';

class OfficeFuelReportFuelsChecks extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          terminalId : -1,
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : []
        }
    }

    componentDidMount() {
      let _v = this.props.router.location.query.v;
      if(_v) {
        this.setState({
          v : _v
        });
      }
    }

    onTerminalSelect(e) {
      this.setState({
          terminalId : e
      });
  }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {
        const {v, terminalId, dateFrom, dateTo} = this.state;           
        let _dateFrom = Moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss');
        let _dateTo = Moment(dateTo).format('YYYY-MM-DDTHH:mm:ss');

        if(!terminalId || terminalId <= 0) {
          if(v && v === 'Shirma') {
            notify('Виберіть термінал зі списку','error');
            return;
          }
      }

      let _url = '/ReportsFuel/FuelsChecks?dateFrom=' + _dateFrom + '&dateTo=' + _dateTo;

      if(!v || v === 'Shirma') {
        _url = _url + '&terminalId=' + terminalId;
      }

      if(v) {
        _url = _url + '&v=' + v;
      }

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + _url,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          console.log(response.data);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }}
        );
    }

    posNumber(e) {
      return e.posId + 1;
    }

    gridDefault(_term, _dateFrom, _dateTo) {
      return(
        <>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsChecks"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelsChecks_' + _term + '_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsChecks" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <ColumnChooser enabled={true} />  
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
              <Column dataField="terminalName" caption="Назва АЗК" dataType="string" />                                 
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="fuel" caption="Паливо" />
              <Column dataField="payType" caption="Вид оплати" />
              <Column dataField="shiftId" caption="Зміна №" dataType="number" />      
              <Column dataField="dispenserId" caption="ПРК №" dataType="number" /> 
              <Column dataField="trkId" caption="Кран №" dataType="number" />         
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="ordered" caption="Замовлено, л." dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" />
              <Column dataField="zsu" caption="ЗСУ, грн/л" dataType="number"  /> 
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="checkNumReturn" caption="Чек повер. №" />  
              <Column dataField="zNumber" caption="Z-звіт №" dataType="number" /> 
              <Column dataField="operatorName" caption="Оператор" />  
              <Column dataField="visibleCardNumber" caption="Карта лоял. №" />         
              <Column dataField="nameEnterprise" caption="Юр.особа" />
                           
              <Summary>
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discountSumma" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkNum" displayFormat="К-ть: {0}" summaryType="count" />
              </Summary>
            </DataGrid>
        </>
      );
    }

    gridShirma(_term, _dateFrom, _dateTo) {
      return(
        <>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsChecksShirma"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelsChecks_Shirma_' + _term + '_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsChecksShirma" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <Column dataField="transStart" caption="Дата поч.наливу" dataType="datetime" format="dd.MM.yyyy, HH:mm:ss" />                
              <Column dataField="transEnd" caption="Дата кін.наливу" dataType="datetime" format="dd.MM.yyyy, HH:mm:ss" />
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="visibleCardNumber" caption="Карта лоял. №" />
              <Column dataField="fuel" caption="Паливо" />
              <Column dataField="payType" caption="Вид оплати" />
              <Column dataField="shiftId" caption="Зміна №" dataType="number" />      
              <Column dataField="dispenserId" caption="ПРК №" dataType="number" /> 
              <Column dataField="trkId" caption="Кран №" dataType="number" />         
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="ordered" caption="Замовлено, л." dataType="number" />
              <Column dataField="price" caption="Ціна, грн/л"  dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="summaWithDiscount" caption="Сума зі зниж., грн."  dataType="number" />
              <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" />
              <Column dataField="discountUahLit" caption="Знижка, грн/л" dataType="number" />
              <Column dataField="zsu" caption="ЗСУ, грн/л" dataType="number"  /> 
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="checkNumReturn" caption="Чек повер. №" />  
              <Column dataField="zNumber" caption="Z-звіт №" dataType="number" /> 
              <Column dataField="transactionId" caption="Транзак. №" dataType="number" /> 
              <Column dataField="posId" caption="Роб.місце" dataType="number" calculateCellValue={this.posNumber} />
              <Column dataField="operatorName" caption="Оператор" />                         
              <Column dataField="nameEnterprise" caption="Юр.особа" />
                           
              <Summary>
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discountSumma" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkNum" displayFormat="К-ть: {0}" summaryType="count" />
              </Summary>
            </DataGrid>
        </>
      );
    }

    gridAll(_dateFrom, _dateTo) {
      return(
        <>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsChecksAll"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelsChecks_all_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsChecksAll" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />
              <Column dataField="terminalId" caption="ID АЗК" />
              <Column dataField="terminalName" caption="Назва АЗК" />                  
              <Column dataField="dat" caption="Дата" dataType="datetime" />
              <Column dataField="sec" caption="Запр, сек." dataType="number" />               
              <Column dataField="fuelName" caption="Паливо" />
              <Column dataField="payType" caption="Вид оплати" />
              <Column dataField="shiftId" caption="Зміна №" dataType="number" />      
              <Column dataField="dispenserId" caption="ПРК №" dataType="number" /> 
              <Column dataField="trkId" caption="Кран №" dataType="number" />         
              <Column dataField="give" caption="Відпущено, л." dataType="number" />
              <Column dataField="ordered" caption="Замовлено, л." dataType="number" />
              <Column dataField="price" caption="Ціна, грн/л"  dataType="number" />
              <Column dataField="summa" caption="Сума, грн."  dataType="number" />
              <Column dataField="summaWithDiscount" caption="Сума зі зниж., грн."  dataType="number" />
              <Column dataField="discountSumma" caption="Знижка, грн." dataType="number" />
              <Column dataField="discountUahLit" caption="Знижка, грн/л" dataType="number" />
              <Column dataField="zsu" caption="ЗСУ, грн/л" dataType="number"  /> 
              <Column dataField="checkNum" caption="Чек №" /> 
              <Column dataField="checkNumReturn" caption="Чек повер. №" />  
              <Column dataField="zNumber" caption="Z-звіт №" dataType="number" /> 
              <Column dataField="transactionId" caption="Транзак. №" dataType="number" />
              <Column dataField="visibleCardNumber" caption="Карта лоял. №" /> 
              <Column dataField="posId" caption="Роб.місце" dataType="number" calculateCellValue={this.posNumber} />
              <Column dataField="operatorName" caption="Оператор" />                         
              <Column dataField="nameEnterprise" caption="Юр.особа" />
                           
              <Summary>
                <TotalItem column="give" displayFormat="Сума: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="discountSumma" displayFormat="Сума: {0}" summaryType="sum">
                <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="checkNum" displayFormat="К-ть: {0}" summaryType="count" />
              </Summary>
            </DataGrid>
        </>
      );
    }

    render() {

      const {v, terminalId, dateFrom, dateTo } = this.state;
      let _dateFrom = Moment(dateFrom).format('yyyyMMDD_HHmmss');
      let _dateTo = Moment(dateTo).format('yyyyMMDD_HHmmss');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">

                {v && v === 'Shirma' ? <>                
                <div style={{float: 'left', minWidth: '250px', marginRight: '10px'}}>
                  <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} visibleFields={['terminalId','name']} />
                </div> </> : <></> }

                <DatFromDatTo defaultDateFrom={dateFrom} defaultDateTo={dateTo} onChangeDates={this.onChangeDates.bind(this)} />
            </div>
            <div style={{marginTop: "10px"}}>
              {v && v === 'Shirma' ? this.gridShirma(terminalId, _dateFrom, _dateTo) : v && v === 'all' ? this.gridAll(_dateFrom, _dateTo) : this.gridDefault(terminalId, _dateFrom, _dateTo)}
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user,
      router: state.router
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelsChecks);