import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, Pager, Paging, Summary, ValueFormat, StateStoring, GroupPanel, GroupItem, ColumnChooser } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeFuelReportFuelsCheckCounters extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : []
        }

    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onLoad() {
      this.clearFilterDataGrid();      
      this.onExecute();
    }

    onRefresh() {
      this.onExecute();
    }

    onExecute(term) {
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/FuelsCheckCounters?dateFrom=' + _datFrom + '&dateTo=' + _datTo,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(term) {
            this.setState({
              mdGrid : response.data
            });
          }
          else {
            this.setState({
              dataGrid : response.data
           });
          }
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onRefresh.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
              <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />                        
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportFuelsCheckCounters"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              keyExpr="terminalId"
              columnAutoWidth={true}
              showBorders={true}              
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportFuelsCheckCounters_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsCheckCounters" />
              <Paging defaultPageSize={50} />
              <GroupPanel visible={true} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} /> 
              <ColumnChooser enabled={true} />  
              <Column dataField="dat" caption="Дата" dataType="datetime" format={{displayFormat: 'DD.MM.yyyy'}} groupIndex={0} />                 
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />       
              <Column dataField="terminalName" caption="Назва АЗК" groupIndex={1} />    
              <Column dataField="shiftId" caption="Зміна №" />
              <Column dataField="fuelName" caption="Паливо" /> 
              <Column dataField="dispenserId" caption="ПРК" dataType="number" />   
              <Column dataField="trkId" caption="Кран" dataType="number" />
              <Column dataField="regToAmount" caption="Каса, л." dataType="number" />
              <Column dataField="countersAmount" caption="Лічильники ПРК, л." dataType="number" /> 
              <Column dataField="diffAmount" caption="Різниця, л." dataType="number" /> 
              <Column dataField="techSpill" caption="Тех.пролив, л." dataType="number" />

              <Summary>     
                <GroupItem column="diffAmount" displayFormat="Сума: {0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem>   
                <GroupItem column="techSpill" displayFormat="Сума: {0}" summaryType="sum" showInGroupFooter={true} alignByColumn={true}>
                  <ValueFormat precision={2} />
                </GroupItem>         
                {/* <TotalItem column="diffAmount" displayFormat="Загалом: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem>
                <TotalItem column="techSpill" displayFormat="Загалом: {0}" summaryType="sum">
                  <ValueFormat precision={2} />
                </TotalItem> */}
              </Summary>
            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportFuelsCheckCounters);