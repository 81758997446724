import React, { Component } from 'react';
import DataGrid, { Column, ColumnChooser, StateStoring, Export, Paging, Pager, MasterDetail } from 'devextreme-react/data-grid';
// import { Tooltip } from 'devextreme-react/tooltip';
import '../settings.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { configApp } from '../../../config';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import { push } from 'connected-react-router';
class SettingsProfileList extends Component {  

    constructor(props) {
        super(props);
        this.state = {
            profileList : []
        }        
    }
    
    onAddProfile() {
        this.props.childState({
            mainGrid : false,
            addProfile : true
        });
    }

    onEditProfile(e) {
        e.event.preventDefault();
        
        if([1,23].includes(e.row.data.id)) {
            notify('Даний профіль недоступний для редагування','error', 1000);
            return;
        }

        this.props.childState({            
            mainGrid : false,
            editProfile : true,
            profileEdit : e.row.data
        });
    }

    componentDidMount() {
        this.getProfileData();
    }

    componentDidUpdate(e) {        
        if(!e.parentState.mainGrid && this.props.parentState.mainGrid) {
            this.getProfileData();
        }
        
    }

    getProfileData() {
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Profiles/all', {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
       .then((response) => {            
            let items = response.data;            
            items.forEach(element => {
                let items2 = element.profilesActions;
                items2.forEach(element => {
                    let _action = this.props.profileTabs.actions.find(i => i.actionName === element.actionName);
                    if(_action) {
                        element.type = _action.type;
                        element.subType = _action.subType;
                        element.title = _action.title;
                        element.path = _action.path;
                        element.visible = _action.type === 'Manager' ? true : _action.visible;
                    }
                })
            });

            this.setState({
               profileList : items
          });         
          this.props.onLoading(false);   
       })
       .catch((error) => {
           this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error', 1000);
              }
           else {
               notify('Не вдалося завантажити дані', 'error', 1000);               
           }
         });
    }

    calculateBlock(rowData) {
        return rowData.isBlocked === 0 ? true : false;
    }

    masterDetRender(e) {
        return (           
            <> 
            <div style={{fontSize: '13px', marginBottom: '10px'}}><b>Звіти профілю</b></div>
            <DataGrid
                dataSource={e.data.data.profilesActions.filter(item => item.path !== undefined)}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}  
                columnAutoWidth={true}>
                <Paging defaultPageSize={100} />    
                <Column dataField="type" caption="Тип" groupIndex={0} />
                <Column dataField="subType" caption="Группа" groupIndex={1} />    
                <Column dataField="id" caption="ID" />                
                <Column dataField="actionName" caption="ID звіту" />
                <Column dataField="title" caption="Назва звіту" />
                <Column dataField="visible" caption="Видимий" dataType="boolean" />
                <Column type="buttons" buttons={[{
                    hint: 'перейти',
                    text : 'перегляд',
                    visible: true,
                    onClick: this.props.onRedirect }]} />
            </DataGrid>
            </>
        );
    }

  render() {    
     return (             
          <div style={{marginTop: "15px"}}>
            <div id="mainGrid">
            
            <Button                            
                text="Додати профіль"
                type="success"
                stylingMode="contained" 
                onClick={this.onAddProfile.bind(this)}                        
            />

            <DataGrid
              id="profileGridContainer"              
              dataSource={this.state.profileList}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}         
              rowAlternationEnabled={true}     
              filterRow={{applyFilter : true, visible : true}} 
              >
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[25, 50, 100]}
                showInfo={true} />    
              <ColumnChooser enabled={true} />
              <Export enabled={true} fileName="SettingsProfile" />              
              <StateStoring enabled={true} type="localStorage" storageKey="gridSettingsProfile" />
              <Column dataField="id" caption="ID"  />
              <Column dataField="profileName" caption="Назва профілю"  />              
              <Column dataField="comment" caption="Коментар"  />
              <Column dataField="isBlocked" dataType="boolean" calculateCellValue={this.calculateBlock} caption="Актив."  />
              <Column type="buttons" buttons={[{
                    hint: 'Редагувати',
                    icon: 'edit',
                    visible: true,
                    onClick: this.onEditProfile.bind(this) }]} />
                <MasterDetail
                    enabled={true}
                    component={this.masterDetRender.bind(this)}
                    />                    
            </DataGrid>

            </div>
          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      settings: state.settings,
      profileTabs: state.profileTabs,
      router: state.router      
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        },
        onRedirect(item) {
            dispatch(push(item.row.data.path))
        }
    }
    )
  )(SettingsProfileList);