import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import Moment from 'moment';
import PivotGrid, { Export, FieldChooser, FieldPanel, StateStoring, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Button } from 'devextreme-react/button';
import DatOneMonth from '../../datOneMonth';

class ManagerReportsFuelsTechSpillsNormed extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          date : Moment(new Date()).set({date:1,hour:0,minute:0,second:0,millisecond:0}),
          pivotGrid : [],
          prevTerm : 0
        }
    }

    onChangeDate(e) {
      this.setState({
          date : e.date            
      });
    }

    onExecute() {     
      let _date = Moment(this.state.date).format('yyyy-MM-DD');

      this.props.onLoading(true);
      axios.get(configApp.API_CORE + '/Reports/ReportTechSpillsNormed?dat=' + _date + '&terminalId=' + this.props.settings.params.currTerminal,
       {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
      .then((response) => {
        this.props.onLoading(false);
        this.setState({
          pivotGrid : response.data
        });            
     })
     .catch((error) => {
        this.props.onLoading(false);
         if (error.response && error.response.data.message) {   
             notify(error.response.data.message, 'error');
            }
         else {
             notify('Не вдалося завантажити дані', 'error');               
         }
       });
  }
    componentDidUpdate() {
      if(this.state.prevTerm !== this.props.settings.params.currTerminal) {
        this.setState({
            prevTerm : this.props.settings.params.currTerminal,
            pivotGrid : []
        });        
      }
    }

    render() {

      const dataSource = new PivotGridDataSource({
        fields: [{
          caption: 'Дата',
          dataField: 'Date',
          area: 'row'
        }, {
          caption: 'Паливо',
          dataField: 'FuelName',
          area: 'column'
        }, {
          caption: 'Сер.пролив/доба, л.',
          dataField: 'TotalSpilled',
          dataType: 'number',
          summaryType: 'avg',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'План ТП, л.',
          dataField: 'PlanTechSpilled',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Факт ТП, грн/л',
          dataField: 'FactTechSpilled',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Виконання, %',
          dataField: 'Percent',
          dataType: 'number',
          summaryType: 'avg',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }],
        store: this.state.pivotGrid
      });

      let _date = Moment(this.state.date).format('yyyyMMDD');

      return (

        <div id="pivot-grid" style={{marginTop : "10px"}}>
        <div style={{float: 'left'}}>
          <DatOneMonth defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />
        </div>
        <br style={{clear: 'both'}} />                      
        <Button                            
            text="Завантажити"
            style={{marginTop: '10px'}}
            type="normal"
            icon="download"
            stylingMode="outlined" 
            onClick={this.onExecute.bind(this)}                        
        />
        <div style={{marginTop: "10px"}}>
        <PivotGrid
            id="pivotgrid"
            dataSource={dataSource}
            allowFiltering={true}
            height={window.innerHeight - 250}
            allowSorting={true}
            allowSortingBySummary={true}
            showBorders={true}
            allowExpandAll={true}                
            showColumnTotals={false}
            showColumnGrandTotals={true}
            showRowTotals={false}
            showRowGrandTotals={true}
            ref={(ref) => this._pivotGrid = ref} >
      <Export enabled={true} fileName={'ReportTechSpillsNormed_' + _date} />
      <StateStoring enabled={true} type="localStorage" storageKey="pvGridReportTechSpillsNormedManager" />
      <FieldPanel
        showColumnFields={true}
        showDataFields={true}
        showFilterFields={true}
        showRowFields={true}
        allowFieldDragging={true}
        visible={true}
      />     
      <FieldChooser enabled={true} height={400} />
      <Scrolling mode="virtual" />
    </PivotGrid>
        </div>

      </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(ManagerReportsFuelsTechSpillsNormed);