import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Summary, TotalItem, ValueFormat } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeWalletReportWalletReplenish extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}).add(-3,'day'),
        dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        dataGrid : []
    }
}

onChangeDates(e) {
  this.setState({
      dateFrom : e.dateFrom,
      dateTo : e.dateTo             
  });
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
  let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
  let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsWallet/WalletReplenish?dateFrom=' + _datFrom + '&dateTo=' + _datTo, 
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
        this.props.onLoading(false);
      this.setState({
          dataGrid : response.data
      });
           
   })
   .catch((error) => {
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    // if(e.rowType === 'data') {
    //   if(e.columnIndex === 11) {
    //     if(e.value !== 0) {
    //       e.cellElement.classList.add('data-grid-error');
    //     }
    //   }
    // }        
  }

render() {

  let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
  let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD');

  return (

    <div style={{marginTop : "10px"}}>
     <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} /> 
     <div style={{marginTop: "10px"}}>
     <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridReportWalletReplenish"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportWalletReplenish_' + _dateFrom + '_' + _dateTo} />               
           <StateStoring enabled={true} type="localStorage" storageKey="ReportWalletReplenish" />
           <Paging defaultPageSize={100} />

           <Column dataField="terminalId" caption="ID АЗК" dataType="number" /> 
           <Column dataField="terminalName" caption="АЗК назва" /> 
           <Column dataField="dat" caption="Дата операції" dataType="datetime" /> 
           <Column dataField="shiftId" caption="№ зміни" />
           <Column dataField="articleId" caption="Код операції" dataType="number" />                        
           <Column dataField="articleName" caption="Назва операції" /> 
           <Column dataField="amount" caption="К-ть" dataType="number" />
           <Column dataField="price" caption="Вартість за од." dataType="number" />
           <Column dataField="summa" caption="Сума, грн." dataType="number" />
           <Column dataField="checknum" caption="№ чека" dataType="number" />
           {/* <Column dataField="posId" caption="Роб.місце" /> */}


           <Summary>
             {/* <TotalItem column="amount" displayFormat="Сума: {0}" summaryType="sum">
               <ValueFormat precision={2} />
             </TotalItem> */}
             <TotalItem column="summa" displayFormat="Сума: {0}" summaryType="sum">
                 <ValueFormat precision={2} />
             </TotalItem>
           </Summary>

         </DataGrid>
     </div>

   </div>
 ) 
}}

export default connect(
state => ({
  auth : state.auth,  
  settings : state.settings,
  user : state.user
}),
dispatch => ({
    onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
})
  )(OfficeWalletReportWalletReplenish);