import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Lookup } from 'devextreme-react/data-grid';
import Moment from 'moment';
import { taxiTransTypesStore } from '../../../../stores/initStores';

class OfficeCardsReportFuelWallet extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}).add(-1, 'days'),
        dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        dataGrid : []
    }
}

onChangeDates(e) {
  this.setState({
      dateFrom : e.dateFrom,
      dateTo : e.dateTo             
  });
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
    let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
    let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsCards/ReportFuelWallet?dateFrom=' + _datFrom + '&dateTo=' + _datTo, 
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
        this.props.onLoading(false);
      this.setState({
          dataGrid : response.data
      });            
   })
   .catch((error) => {
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    // if(e.rowType === 'data') {
    //   if(e.columnIndex === 11) {
    //     if(e.value !== 0) {
    //       e.cellElement.classList.add('data-grid-error');
    //     }
    //   }
    // }        
  }

render() {

  let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
  let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

  return (

    <div style={{marginTop : "10px"}}>
     <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
     <div style={{marginTop: "10px"}}>
     <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridReportFuelWallet"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportFuelWallet_' + _dateFrom + '_' + _datTo} />               
           <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelWallet" />
           <Paging defaultPageSize={100} />

           <Column dataField="contragentTransaction.id" caption="№ тразакції" dataType="number" />
           <Column dataField="contragent.id" caption="ID клієнта" dataType="number" />
           <Column dataField="date" caption="Дата" dataType="datetime" /> 
           <Column dataField="transactionType" caption="Тип транзакції">
                <Lookup dataSource={taxiTransTypesStore} valueExpr="id" displayExpr="name" />                
           </Column>  
           <Column dataField="station.extId" caption="ID АЗК" />
           <Column dataField="station.name" caption="Назва АЗК" />
           <Column dataField="fuel.extId" caption="Код палива" dataType="number" />
           <Column dataField="fuel.name" caption="Назва палива" />
           <Column dataField="amount" caption="К-ть, л." dataType="number" />           

         </DataGrid>
     </div>

   </div>
 ) 
}
}

export default connect(
state => ({
  auth : state.auth,  
  settings : state.settings,
  user : state.user
}),
dispatch => ({
    onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
})
  )(OfficeCardsReportFuelWallet);