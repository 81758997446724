import { profileTabsStore } from '../stores/initStores';

export default function userTabs(state = profileTabsStore, action) {
    if (action.type === 'SET_PROFILE_TABS_DATA') { 
      return action.payload;
    }     
    else if (action.type === 'CLEAR_PROFILE_TABS_DATA') {
      return state = profileTabsStore;
    }
    return state;
  }