import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, CardHeader, CardBody, CardText, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import { AvForm, AvField} from 'availity-reactstrap-validation-safe';
import { userTypeStore } from '../../stores/initStores';
import './profile.css';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../config';
import $ from "jquery";

class Profile extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            modal : false};
        this.form = React.createRef();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);          
      }

      onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
          event.preventDefault();          
        }
    }

    handleValidSubmit() {

        let _form = this.form.current;

        if(_form._inputs.password.value !== _form._inputs.password2.value) {   
            notify('Паролі повинні збігатися', 'error', 1000);
            return;
        }

        this.setState({
            modal : true
        });

    }

    onCloseModal() {
        this.setState({
            modal : false
        });
    }

    onChangePassword() {

        this.onCloseModal();

        let _form = this.form.current;

        this.props.onLoading(true);

        axios.post(configApp.API_CORE + '/Users/password-change', 
         {
            Id: this.props.user.id,
            Password: _form._inputs.password.value
        },
        {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {        
            notify('Пароль успішно змінено', 'success', 1000);   
            this.props.onLoading(false);
            _form._inputs.password.value = '';
            _form._inputs.password2.value = '';
        })
        .catch((error) => {
            if (error.response && error.response.data.message) {     
                notify(error.response.data.message, 'error', 1000);     
            } 
            else {
                notify('Не вдалося змінити пароль', 'error', 1000);
            }           
            
            this.props.onLoading(false);

          });
      }

    findUserType(item) {
        return (userTypeStore.find((item2) => {
            return item2.id === item;
        })).name;
    }

    getActiveAZK(item) {
        let _term = [];        
        //const item = this.props.user.userTerminals;
        item.forEach((item2) => {
            _term.push(<span key={item2.terminalId}>{item2.terminalId} {item2.name}<br /></span>);
        })

        return(
            <>{_term}</>
        );
    }

    onShowAll() {
        $('#button-show-all-term-id').hide();
        $('#term-list').addClass('showContent');
        // $('#term-list').removeClass('hideContent');
    }

    onTest() {
        // $('#profile-terminals').hide();
    }

    render() {
        const terms = this.props.user.userTerminals;
        return (
            <div className="row" id="profileContainer">     
                <Col className="col-12 col-xl-6 col-md-6 col-sm-12">
                    <Card className="mt-3">
                    <CardHeader tag="h4">Профіль</CardHeader>
                    <CardBody>                    
                        <CardText className="profile-text"><b>Користувач:</b> {this.props.user.name}</CardText>                        
                        {this.props.user.useActiveDirectoryCheck && this.props.user.activeDirectoryName ? <CardText className="profile-text"><b>Користувач AD :</b> {this.props.user.activeDirectoryName}</CardText> : <></>}
                        <CardText className="profile-text"><b>Ім'я:</b> {this.props.user.displayName}</CardText>                    
                        <CardText className="profile-text"><b>E-mail:</b> {this.props.user.eMail}</CardText>                    
                        <CardText className="profile-text"><b>Посада:</b> {this.findUserType(this.props.auth.UserType)}</CardText>
                        <CardText className="profile-text"><b>Профіль:</b> {this.props.user.userProfile ? this.props.user.userProfile.profileName : 'не заданий'}</CardText>
                        { this.props.user.userTerminals.length > 0 ? 
                        
                        <div className="profile-text" id="profile-terminals">
                        <b>Активні АЗК:</b>
                        <div id="term-list" className={terms.length > 10 ? 'hideContent' : ''}>
                            {this.getActiveAZK(terms)}
                        </div>
                        {terms.length > 10 ? <span id="button-show-all-term-id" className="button-show-all-term" onClick={this.onShowAll.bind(this)}>показати всі ...</span> : <></>}
                    </div>
                    
                        : <></>}                                            
                    </CardBody>                    
                </Card>
                    </Col>
                    <Col className="col-12 col-xl-6 col-md-6 col-sm-12">
                    <Card className="mt-3">
                    <CardHeader tag="h4">Змінити пароль</CardHeader>

                        {this.props.user.useActiveDirectoryCheck ? 
                        
                        <div className="profile-text" style={{padding: '20px'}}>
                            Для зміни паролю перейдіть за посиланням: <br />
                            <a href='https://fs.evropower.com/adfs/portal/updatepassword'>https://fs.evropower.com/adfs/portal/updatepassword</a>
                        </div>

                        : 

                        <AvForm onKeyPress={this.onKeyPress} onValidSubmit={this.handleValidSubmit} className="p-4 form-change-password" ref={this.form}>                        
                        <AvField 
                            name="password" 
                            id="password" 
                            label="Пароль"
                            required
                            type="password"
                            validate={{
                                required: {value: true, errorMessage: 'Вкажіть новий пароль'}, 
                                pattern: {value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/, errorMessage: 'Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру'}
                            }} />
                        <AvField 
                            name="password2" 
                            id="password2" 
                            label="Повторіть пароль"
                            required
                            type="password"
                            validate={{
                                required: {value: true, errorMessage: 'Повторіть свій новий пароль'}, 
                                pattern: {value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/, errorMessage: 'Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру'}
                            }} />                          
                    <div style={{display : "flex", flexDirection: "row", alignItems : "center"}}>
                    {/* <Button color="primary" type="submit" style={{marginRight : "15px"}}>Змінити</Button>                     */}
                        <Button
                            width={120}
                            text="Змінити"
                            type="default"
                            stylingMode="contained" 
                            useSubmitBehavior={true}                           
                        />
                    </div>
                </AvForm> }
                    </Card>
                    </Col>

                    <Modal isOpen={this.state.modal}>
                    <ModalBody>
                        Ви дійсно бажаєте змінити пароль?
                    </ModalBody>
                    <ModalFooter>
                    <Button                            
                            text="Так"
                            type="success"
                            stylingMode="contained" 
                            onClick={this.onChangePassword.bind(this)}
                        />{' '}
                        <Button                            
                            text="Ні"
                            type="danger"
                            stylingMode="contained" 
                            onClick={this.onCloseModal.bind(this)}
                        />
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect(
    state => ({      
      router : state.router,
      user : state.user,
      auth : state.auth
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(Profile);