import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatFromDatToWithTime from '../../datFromDatToWithTime';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Pager } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeGeneralReportCashDocsDiff extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            dateFrom : Moment(new Date()).set({hour:23,minute:0,second:0,millisecond:0}).add(-1,'days'),
            dateTo : Moment(new Date()).set({hour:11,minute:0,second:0,millisecond:0}),
            dataGrid : []
        }
    }

    onChangeDates(e) {
      this.setState({
          dateFrom : e.dateFrom,
          dateTo : e.dateTo             
      });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {             
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DDTHH:mm');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DDTHH:mm');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/CashDocsDiff?dateFrom=' + _datFrom + '&dateTo=' + _datTo, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
            this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
            this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        },{
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

    render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD_HHmm');
      let _datTo = Moment(this.state.dateTo).format('yyyyMMDD_HHmm');

      return (

        <div style={{marginTop : "10px"}}>
         <DatFromDatToWithTime defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
         <div style={{marginTop: "10px"}}>
         <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridOfficeGeneralReportCashDocsDiff"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}                    
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportCashDocsDiff_' + _dateFrom + '_' + _datTo} />              
           <StateStoring enabled={true} type="localStorage" storageKey="OfficeReportCashDocsDiff" />           
           <Paging defaultPageSize={50} />
           <Pager
             showInfo={true}
             showPageSizeSelector={true}
             allowedPageSizes={[50,100]}
           />

           <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
           <Column dataField="terminalName" caption="Назва АЗК" />  
           <Column dataField="vynos" caption="Вилучення, грн." dataType="number" />           
           <Column dataField="incas" caption="Інкасація, грн." dataType="number" />           
           <Column dataField="diff" caption="Різниця, грн." dataType="number" />           

         </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(OfficeGeneralReportCashDocsDiff);