import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';

export default class DatFromDatTo extends Component {

    constructor(props) {
        super(props)
        this.state = {          
             dateFrom : props.defaultDateFrom ? props.defaultDateFrom : new Date(),
             dateTo :props.defaultDateTo ? props.defaultDateTo : new Date()              
        }
    }

    onChangedDateFrom(e) {
        this.setState({
            dateFrom : e.value
        });
        this.onSyncParent();
    }

    onChangedDateTo(e) {
        this.setState({
            dateTo : e.value
        });
        this.onSyncParent();
    }

    onSyncParent() {
        this.props.onChangeDates(this.state);
    }

    render() {        
        return(
            <div id="datFromdatTo">
                <div className="label-date">
                    <span>дата з</span>                
                    <DateBox defaultValue={this.state.dateFrom} type="date" onValueChanged={this.onChangedDateFrom.bind(this)} />
                </div>
                <div className="label-date">
                    <span>дата по</span>
                    <DateBox defaultValue={this.state.dateTo} type="date" onValueChanged={this.onChangedDateTo.bind(this)} /> 
                </div>
          </div>         
        )
    }
}