import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectBox } from 'devextreme-react';
import { enterpriseStore }  from '../../stores/initStores';

class SelectEnterpriseOffice extends Component {

    constructor(props) {
        super(props)
        this.state = {    
          enterprises: enterpriseStore,
          selBoxValue: this.props.defaultValue
        }                
    }

    onValueChanged(e) {
      this.setState({
        selBoxValue: e.value
      });
      this.props.onChangeEnterprise(e.value);
    }

    render() {        
        return(
          <div id="selEnterprise">
            <div className="label-enterprise">
                <span>юр.особа</span>
                <SelectBox
                  ref={item => this.selbox = item}        
                  value={this.state.selBoxValue}                        
                  valueExpr="enterpriseId"                                        
                  deferRendering={false}     
                  width="7rem"               
                  displayExpr="enterpriseName"
                  dataSource={this.state.enterprises}
                  onValueChanged={this.onValueChanged.bind(this)} />                
            </div>
          </div>
        )
    }
}

export default connect(
    state => ({
      auth: state.auth,
      user : state.user,
      settings : state.settings
    })
  )(SelectEnterpriseOffice);