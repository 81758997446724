import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectShifts from '../../selectShifts';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';

class ManagerReports17NP extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            shiftId : 0
        }              
    }

    onSelectShift(e) {
        this.setState({
            shiftId : e
        });
    }

    onExecute() {   

        let _shift = this.state.shiftId;
        let _term = this.props.settings.params.currTerminal;

        if(!_shift || _shift === 0) {
            notify('Виберіть зміну зі списку','error');
            return;
        }

        if(!_term || _term === 0) {
            notify('Виберіть термінал зі списку','error');
            return;
        }

        let _fileName = '17NP_' + _shift + '_' + _term + '.xlsx';
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/Reports/Report17NP?shiftId=' + this.state.shiftId + '&terminalId=' + this.props.settings.params.currTerminal,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {            
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    
    render() { 
      
      return (
        <div style={{paddingTop : "15px"}}>
            <SelectShifts onShiftSelect={this.onSelectShift.bind(this)} />   
            <div style={{marginTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
              </div>
        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(ManagerReports17NP);