import React, { Component } from 'react';

export class NotAccess extends Component {  

 render() {
     return (
       <div>
         <b>Доступ не дозволено</b><br />
         <a href='/'>повернутися на головну</a>
       </div>
     );
   }
}