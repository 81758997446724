import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectBox from 'devextreme-react/select-box';
import { setCurrTerm } from '../../actions/settings';
import $ from "jquery";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBuilding } from '@fortawesome/free-regular-svg-icons';

class TermSelectManager extends Component {  

  constructor(props) {
    super(props);

    this.state = {
      isShowClearButton : false
    }
  }

  isShow() {
    // в каких случаях показываем/скрываем выбор терминала справа
    let _path = this.props.router.location.pathname;
//    let _disallow = ['/', '/404', '/403', '/settings', '/profile'];
//    return !_disallow.some(item => item === _path);
    return _path.includes('/manager/'); 
  }

  displayExpr(item) {    
    return item && `${item.terminalId } ${ item.name }`;
  }

  onTermChanged(item) {   
    this.props.onTermSelected(item.value);     
  }

  componentDidUpdate(){    
    const { currTerminal } = this.props.settings.params; 
    
    if(this.props.user.userTerminals && currTerminal && currTerminal !== 0) {
      if(!this.props.user.userTerminals.find(item => item.terminalId === currTerminal)) {
        this.props.onTermSelected(0);
      }
    }
    
  $("#sel-term-container").off('focusin');
  $("#sel-term-container").off('focusout');

  let that = this;
  $('#sel-term-container').on('focusin', function() {
    that.setState({
      isShowClearButton : true
    })
  });

  $('#sel-term-container').on('focusout', function() {
    that.setState({
      isShowClearButton : false
    })
  });

 }

  render() {
     return (          
          <>
          {this.isShow() ? 
          <div id="sel-term-container" className="dx-field sel-term">
          <div className="dx-field-value">
          <SelectBox dataSource={this.props.user.userTerminals}
                  ref={(ref) => this.sbTerms = ref}  
                  id='selTermManager'
                  name='selTermManager'
                  inputAttr={{name: 'selTermManager', autocomplete: "off"}}                
                  placeholder="Виберіть АЗК ..."                  
                  displayExpr={this.displayExpr}
                  searchEnabled={true}      
                  searchMode="contains"                                    
                  //searchExpr="name"               
                  //searchTimeout={200}
                  minSearchLength={0}
                  wrapItemText={true}                  
                  showClearButton={this.state.isShowClearButton} 
                  valueExpr="terminalId"
                  value={this.props.settings.params.currTerminal}
                  onValueChanged={this.onTermChanged.bind(this)} />
          </div>
          </div>
          :
          <></>}
          </>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      router : state.router,
      settings : state.settings
    }),
    dispatch => ({
        onTermSelected: (item) => {          
            dispatch(setCurrTerm(item));
        }
    })
  )(TermSelectManager);