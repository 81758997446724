import React, { Component } from 'react';
import Form, {
    SimpleItem,
    Label,
    CompareRule,
    EmailRule,
    PatternRule,
    RequiredRule,
    ButtonItem
  } from 'devextreme-react/form';
import TextArea from 'devextreme-react/text-area';
import TagBox from 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import '../settings.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { configApp } from '../../../config';
import generatorPassword from 'generate-password';

class SettingsAddUser extends Component {  

     constructor(props) {
         super(props); 
         this.form = React.createRef();         
         this.state = {
             customer : {
                user : {
                    userTypeId : null,
                    name : '',
                    displayName : '',
                    eMail : '',
                    inn: null,
                    phone: null,
                    password : '',
                    userProfile : { id: 0},
                    comment : 'created by ' + this.props.auth.User,
                    lowDateTrashholdDays : 0,
                    useActiveDirectoryCheck : false,
                    activeDirectoryName : null
                },                
                terminalsId : []
             },
             profiles : [],
             isVisibleTerms : false,
             isVisibleLowDateDays : false,
             isVisibleProfile : true,
             isShowPassword: true,
             isShowActiveDirectoryName: false
         }

         this.passwordComparison = this.passwordComparison.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.cancelClick = this.onCancelClick.bind(this);
    }

    handleSubmit(e) {        
        e.preventDefault(); 
        //e.target.reset();

        if(this.state.customer.user.userTypeId === 400 && this.state.customer.terminalsId.length > 1) {
            notify('Для даного типу користувача неможливо додати більше однієї АЗК','error');
            return;
        }

        this.props.onLoading(true);
        axios.put(configApp.API_CORE + '/Users', this.state.customer, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {                    
            notify('Користувач успішно створений', 'success', 1000);   
            this.props.onLoading(false);
            //e.target.reset();
            this.form.current.reset();
            this.props.childState({
                mainGrid: true,
                addUser : false
            });        
        })
        .catch((error) => {
            if (error.response && error.response.data.message) {     
                notify(error.response.data.message, 'error', 1000);     
            } 
            else {
                notify('Не вдалося створити користувача', 'error', 1000);
            }                       
            this.props.onLoading(false);
          });
      }

    onCancelClick() {        
        this.form.current.reset();
        this.props.childState({
            mainGrid: true,
            addUser : false
        }); 
    }


    passwordComparison() {
        return this.state.customer.user.password;
      }

    displayExpr(item) {    
        return item && `${item.terminalId } ${ item.name }`;
      }

      onUserTypeChanged(e) {
        // для сотрудников АЗС ограничиваем выбор профиля. только один!
        // скрытие/показ выбор профиля, АЗС или кол-ва дней
        if(e.selectedItem.id !== this.state.customer.user.userTypeId) {
            let _customer = this.state.customer;
            _customer.user.userTypeId = e.selectedItem.id;
            _customer.user.lowDateTrashholdDays = e.selectedItem.id >= 150 ? 90 : 0;
            
             this.setState({             
                 customer : _customer,
                 profiles : e.selectedItem.id === 20 || e.selectedItem.id > 200 ? 
                            this.props.userTabs.profiles.filter(item => item.id === 1) :
                            e.selectedItem.id === 200 ? this.props.userTabs.profiles.filter(item => item.id === 23) : 
                            this.props.userTabs.profiles.filter(item => ![1,23].includes(item.id)),
                 isVisibleTerms : e.selectedItem.id >= 150 ? true : false,
                 isVisibleLowDateDays : e.selectedItem.id >= 150 ? true : false,
                 isVisibleProfile : e.selectedItem.id <= 10 || e.selectedItem.id === 50 ? false : true
             });
        }
      }

      onADAuthChange(e) {

        if(e.value) {
            let _password = generatorPassword.generate({
                length: 10,
                numbers: true
            });
            let _customer = this.state.customer;
            _customer.user.password = _password;
            this.setState({
                isShowPassword: false,
                isShowActiveDirectoryName: true,
                customer : {...this.state.customer, user : {...this.state.customer.user, password : _password}}
            });
        }
        else {
            this.setState({
                isShowPassword: true,
                isShowActiveDirectoryName: false,
                customer : {...this.state.customer, user : {...this.state.customer.user, password : ''}}
            });
        }
      }  

  render() {    
    const { customer, isVisibleLowDateDays, isVisibleTerms, profiles, isVisibleProfile, isShowPassword, isShowActiveDirectoryName } = this.state;
    
     return (             
          <div style={{marginTop: "15px", maxWidth: "750px", margin: "auto"}}>
            <div id="addUser">
            <div className="header">Додавання користувача</div>
            <form onSubmit={this.handleSubmit} ref={this.form}>
            <Form
                formData={customer}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true} 
                validationGroup="customerData" >
                
                <SimpleItem dataField="user.userTypeId" editorType="dxSelectBox" editorOptions={{dataSource: this.props.userTabs.userTypes, valueExpr : 'id', displayExpr : 'name', onSelectionChanged: this.onUserTypeChanged.bind(this)}}>                
                    <Label text="Тип користувача"></Label>
                    <RequiredRule message="Тип користувача - обов'язкове поле" />
                </SimpleItem>                
                <SimpleItem dataField="user.name" editorType="dxTextBox">
                    <Label text="Користувач"></Label>
                    <RequiredRule message="Користувач - обов'язкове поле" />                
                </SimpleItem>
                <SimpleItem dataField="user.useActiveDirectoryCheck" editorType="dxCheckBox" editorOptions={{onValueChanged: this.onADAuthChange.bind(this)}}>
                    <Label text="Авториз. за AD"></Label>              
                </SimpleItem>
                <SimpleItem dataField="user.activeDirectoryName" editorType="dxTextBox" visible={isShowActiveDirectoryName}>
                    <Label text="Користувач AD"></Label>
                </SimpleItem>
                <SimpleItem dataField="user.displayName" editorType="dxTextBox">
                    <Label text="Ім'я"></Label>
                    <RequiredRule message="Ім'я - обов'язкове поле" />                
                </SimpleItem>
                <SimpleItem dataField="user.eMail" editorType="dxTextBox">
                    <Label text="Email"></Label>
                    <RequiredRule message="Email - обов'язкове поле" />
                    <EmailRule message="Email вказано не вірно" />                
                </SimpleItem>
                <SimpleItem dataField="user.inn" editorType="dxTextBox" editorOptions={{mask : '9999999999'}}>
                    <Label text="ІПН"></Label>
                    <PatternRule message="ІПН повинен містити 10 цифр" pattern="^\d{10}$" />
                    <RequiredRule message="ІПН - обов'язкове поле" />
                </SimpleItem>
                <SimpleItem dataField="user.phone" editorType="dxTextBox" editorOptions={{mask : '+38 (000) 000-00-00'}}>
                    <Label text="Телефон"></Label>
                </SimpleItem>
                <SimpleItem visible={isVisibleProfile} dataField="user.userProfile.id" editorType="dxSelectBox" editorOptions={{dataSource: profiles, valueExpr : 'id', displayExpr : 'profileName'}}>                
                    <Label text="Профіль"></Label>
                </SimpleItem>
                <SimpleItem dataField="user.password" editorType="dxTextBox" editorOptions={{mode : "password", inputAttr: {autoComplete: "new-password"}}} visible={isShowPassword}>
                <Label text="Пароль"></Label>
                    <PatternRule message="Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$" />
                    <RequiredRule message="Пароль - обов'язкове поле" />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" editorOptions={{mode : "password", inputAttr: {autoComplete: "new-password"}}} visible={isShowPassword}>
                <Label text="Повторіть пароль"></Label>
                    <PatternRule message="Пароль повинен містити щонайменше 6 символів, і повинен містити принаймні одну велику та малу літеру та одну цифру" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$" />
                    <RequiredRule message="Повтор паролю - обов'язкове поле" />
                    <CompareRule
                    message="Паролі не збігаються"
                    comparisonTarget={this.passwordComparison}
                    />
                </SimpleItem>
                <SimpleItem dataField="user.comment" editorType="dxTextArea">
                <Label text="Коментар"></Label>                
                </SimpleItem>                
                <SimpleItem visible={isVisibleLowDateDays} dataField="user.lowDateTrashholdDays" editorType="dxTextBox">
                    <Label text="К-ть днів для звітів"></Label>                    
                </SimpleItem>
                <SimpleItem visible={isVisibleTerms} dataField="terminalsId" editorType="dxTagBox" editorOptions={{
                    searchEnabled : true,
                    dataSource :this.props.userTabs.terminals,
                    valueExpr : "terminalId",
                    displayExpr : this.displayExpr                    
                }}>
                    <Label text="Доступні АЗК"></Label>                    
                </SimpleItem>

                <ButtonItem  itemType="button"             
                    buttonOptions={{
                        text: 'Зберегти',
                        type: 'success',
                        useSubmitBehavior: true
                    }}
              />
              <ButtonItem itemType="button"              
                buttonOptions={{
                    text: 'Відміна',
                    type: 'normal',
                    onClick : this.onCancelClick.bind(this)
                  }}
              />
            </Form>                     
            </form>            
            </div>

            {/* просто чтобы VS Code не давал предупреждения */}
            <TextArea visible={false} />
            <TagBox visible={false} />

          </div>
     );
   }
}

export default connect(
    state => ({
      auth: state.auth,
      user: state.user,
      userTabs: state.userTabs      
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(SettingsAddUser);