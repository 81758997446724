import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';

export default class DatOneMonth extends Component {

    constructor(props) {
        super(props)
        this.state = {          
             date : props.defaultDate ? props.defaultDate : new Date(),
             minDate: props.minDate ? props.minDate : new Date(1,1,1),
             maxDate: props.maxDate ? props.maxDate : new Date(9999,1,1)              
        }
    }

    onChangedDate(e) {
        this.setState({
            date : e.value
        });
        this.props.onChangeDate(this.state);
    }

    render() {      
        const {date,minDate,maxDate} = this.state;  
        return(
            <div id="datOne">
                <div className="label-date">
                    <span>дата</span>                
                    <DateBox defaultValue={date} pickerType="rollers" type="date" onValueChanged={this.onChangedDate.bind(this)} min={minDate} max={maxDate} displayFormat="MMM yyyy" />
                </div>
            </div>         
        )
    }
}