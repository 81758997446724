import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Login from './auth/login';
import NavMenu from './menu/navMenu';
import TermSelectManager from './reports/termSelectManager';
import Navs from './menu/navs';
// import Loading from '../components/loading';
import LoadingFullScreen from '../components/other/loading-fullscreen';
import { connect } from 'react-redux';
import { checkAuthStart, checkTokenExpr } from '../actions/auth';
import packageJson from '../../package.json';
import { setAdminProfView } from '../actions/settings';
import { push } from 'connected-react-router';

class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);
    this.props.onCheckAuthStart();
  }

  refreshToken() {
    this.props.onCheckTokenExpr();
  }

  componentDidMount() {
    this.interval = setInterval(() => 
    this.refreshToken(), 1 * 60 * 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onChangeViewProfAdmin() {
    let _currView = this.props.settings.params.adminProfileView;
    if(!_currView || _currView === 0) {
      this.props.onChangeAdminProfView(1);
    }
    else {
      this.props.onChangeAdminProfView(0);
    }
    this.props.onRedirect('/');

  }

  render () { 
    const adminProfView = this.props.settings.params.adminProfileView && this.props.settings.params.adminProfileView === 1 ? 'Smart Manager' : 'Smart Office';   
    return (
      <>            
      <LoadingFullScreen visible={this.props.settings.isLoadingShow} />    
      <div className="content">      
        <NavMenu />          
          <Container>
            <div className="dx-viewport">      
            <div className="top-row">
              <Navs />  {this.props.auth.isAuth ? <TermSelectManager /> : <></>}
            </div>
            {this.props.auth.isAuth ? this.props.children : this.props.auth.isLoginShow ? <Login /> : <></>}            
            </div>
          </Container>                   
      </div>
      <div className="footer">
      <Container>
        <div className="float-left">&copy; {(new Date().getFullYear())} - Smart Reports</div>          
        <div className="float-right version-bottom">{this.props.auth.isAuth && [1,10,50,150].includes(this.props.user.userTypeId) ? <>перегляд: <span onClick={this.onChangeViewProfAdmin.bind(this)} title="змінити перегляд">{adminProfView}</span> | </> : <></>}ver. {packageJson.version}</div>           
      </Container>                  
      </div>
      </>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    user : state.user,
    settings : state.settings
  }),
  dispatch => ({
    onCheckAuthStart: () => {
        dispatch(checkAuthStart());
    },
    onChangeAdminProfView: (view) => {
      dispatch(setAdminProfView(view));
  },
    onCheckTokenExpr: () => {
      dispatch(checkTokenExpr());
  },
    onRedirect : (item) => {
      dispatch(push(item))
  }
  })
)(Layout);