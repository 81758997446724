import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectShifts from '../../selectShifts';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Summary, TotalItem, Paging, ValueFormat, GroupPanel } from 'devextreme-react/data-grid';

class ManagerReportsFuelsSalesByTalonNumbers extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            shiftId : 0,
            dataGrid : [],
            prevTerm : 0
        }
    }

    onSelectShift(e) {
        this.setState({
            shiftId : e
        });

        let _shift = e;
        let _term = this.props.settings.params.currTerminal;

        if(!_shift || _shift === 0 || !_term || _term === 0) {
            this.setState({
                dataGrid : []
            });
            return;
        }

        this.onLoad();
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {    
      this.props.onLoading(true);    
        axios.get(configApp.API_CORE + '/Reports/ReportFuelsSalesByTalonNumbers?shiftId=' + this.state.shiftId + '&terminalId=' + this.props.settings.params.currTerminal, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {    
          this.props.onLoading(false);        
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        });
      }

    render() {

      let _term = this.props.settings.params.currTerminal;
      let _shift = this.state.shiftId;

      return (
        
        <div style={{paddingTop : "15px"}}>
         <SelectShifts onShiftSelect={this.onSelectShift.bind(this)} />

         <div style={{marginTop: "10px"}}>
         <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridReportFuelsSalesByTalonNumbers"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportFuelsSalesByTalonNumbers_' + _term + ' ' + _shift} />               
           <StateStoring enabled={true} type="localStorage" storageKey="ReportFuelsSalesByTalonNumbers" />
           <GroupPanel visible={true} />
           <Paging defaultPageSize={100} />

           <Column dataField="dat" caption="Дата" dataType="datetime" />              
           <Column dataField="checkNum" caption="Чек №" groupIndex={0}  />
           <Column dataField="talonNumber" caption="Талон №" />
           <Column dataField="fuelName" caption="Паливо" />              
           <Column dataField="nominal" caption="Номінал" dataType="number"  />                         
           <Column dataField="userName" caption="Оператор" />

           <Summary>
             <TotalItem column="talonNumber" displayFormat="К-ть: {0}" summaryType="count">
               <ValueFormat precision={2} />
             </TotalItem>
             <TotalItem column="nominal" displayFormat="Сума: {0}" summaryType="sum">
                 <ValueFormat precision={2} />
             </TotalItem>
           </Summary>

         </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(ManagerReportsFuelsSalesByTalonNumbers);