import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatOne from '../../datOne';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, Pager } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeGeneralReportTotalSales extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            date : Moment(new Date()).add(-1,'day').set({hour:0,minute:0,second:0,millisecond:0}),
            dateLimit : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
            dataGrid : []
        }
    }

    onChangeDate(e) {
      this.setState({
          date : e.date             
      });
    }

    onLoad() {
      this.clearFilterDataGrid();
      this.onExecute();
    }

    onExecute() {             

        if(this.state.date >= this.state.dateLimit) {
          let _datLimit = Moment(this.state.dateLimit).format('DD.MM.YYYY');
          notify('Дата має бути меншою ніж ' + _datLimit, 'error');
          return;
        }

        let _dat = Moment(this.state.date).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/TotalSales?date=' + _dat, {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
            this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
            this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
           }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
      const exportBtnItem = e.toolbarOptions.items.find(  
        item => item.name === "exportButton"  
      );  
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      
      e.toolbarOptions.items[exportBtnIndex] = {  
        location: "after",  
        locateInMenu: "auto",  
        sortIndex: 30,  
        widget: "dxButton",  
        options: {  
          text: "В Excel",  
          icon: "export-excel-button",  
          hint: "Експорт в Excel",  
          elementAttr: {  
            "class": "dx-datagrid-export-button"  
          },  
          onClick: function() {  
            e.component.exportToExcel(false);  
          }  
        }  
      };  
      
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }
        },{
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }
        });
      }

    render() {

      let _date = Moment(this.state.date).format('yyyyMMDD');

      return (

        <div style={{marginTop : "10px"}}>
         <DatOne defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />
         <div style={{marginTop: "10px"}}>
         <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridOfficeReportTotalSales"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportTotalSales_' + _date} />              
           <StateStoring enabled={true} type="localStorage" storageKey="OfficeReportTotalSales" />           
           <Paging defaultPageSize={50} />
           <Pager
             showInfo={true}
             showPageSizeSelector={true}
             allowedPageSizes={[50,100,200,300]}
           />
           <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
           <Column dataField="terminal" caption="Назва АЗК" />
           <Column dataField="summa" caption="ПММ, грн." dataType="number" />
           <Column dataField="amount" caption="ПММ, л." dataType="number" />
           <Column dataField="shopSales" caption="Магазин, грн." dataType="number" />
           <Column dataField="shopKoef" caption="Магазин, грн/л" dataType="number" />
         </DataGrid>
         </div>

       </div>
     ) 
   }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
        onLoading(item) {
            dispatch({type: 'LOADING_SHOW', payload: item});
        }
    })
  )(OfficeGeneralReportTotalSales);