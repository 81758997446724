import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { Popup } from 'devextreme-react/popup';
import Calendar from 'devextreme-react/calendar';
import { configApp } from '../../../../config';
import fileDownload from 'js-file-download';
import Moment from 'moment';
import SelectTerminalOffice from '../../selectTerminalOffice';
import SelectShiftsOffice from '../../selectShiftsOffice';

class OfficeFuelReportComplex extends Component {

    constructor(props) {
        super(props);        
        this.state = {
            terminalId : 0,
            shiftId : 0,
            showExecuteAllDate: false,
            currentDate: new Date()
        }              
    }

    onTerminalSelect(e) {
        this.setState({
            terminalId : e
        });
    }

    onExecute() {

        let _term = this.state.terminalId;
        let _shiftId = this.state.shiftId;

        if(!_term || _term === 0) {
            notify('Виберіть термінал зі списку','error');
            return;
        }

        if(!_shiftId || _shiftId === 0) {
            notify('Виберіть зміну зі списку','error');
            return;
        }

        let _fileName = 'ReportComplex_' + _term + '_' + _shiftId + '.xlsx';
        
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportComplex?terminalId=' + _term + '&shiftId=' + _shiftId,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {            
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    onExecuteAll() {
        if(!this.state.currentDate) {
            notify('Виберіть дату звіту','error');
            return;
        }

        let _date = Moment(this.state.currentDate).format('YYYY-MM-DD');;
        let _fileName = 'ReportComplex_all_' + _date + '.zip';
        
        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportComplexAllAccessible?date=' + _date,        
         {responseType: 'blob', headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {            
          fileDownload(response.data, _fileName);
          this.props.onLoading(false);          
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.log(error.response.data);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не має данних для вигрузки', 'error');               
           }
         });
    }

    onSelectShift(e) {
        this.setState({
            shiftId : e
        });
    }

    onShowDateSelect() {
        this.setState({
            showExecuteAllDate: true
        });
    }

    onDateValueChanged(e) {
        if(e.value !== this.state.currentDate) {
            this.setState({
                currentDate: e.value,
                showExecuteAllDate : false
            });
        }

        this.onExecuteAll();
    }

    
    render() { 
    
      const {terminalId
            //, currentDate
            } = this.state;

      return (
          
        <div style={{paddingTop : "10px"}}>
            <div style={{maxWidth: '350px', minWidth: '350px'}}>
                <SelectTerminalOffice onTerminalSelect={this.onTerminalSelect.bind(this)} />
            </div>
            <div style={{marginTop: '10px'}}>
                <SelectShiftsOffice selectedTerminal={terminalId} onShiftSelect={this.onSelectShift.bind(this)} />
            </div>      

            <div style={{clear: 'both', float: 'left', paddingTop: "10px"}} className="container-custom-button">            
            <Button                            
                  text="Завантажити"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onExecute.bind(this)}                        
              />     
            </div>
            <div style={{float: 'left', paddingTop: "10px", marginLeft: '15px'}} className="container-custom-button">            
            <Button                            
                  text="Завантажити всі АЗК"
                  type="normal"
                  icon="download"
                  stylingMode="outlined" 
                  onClick={this.onShowDateSelect.bind(this)}                        
              />     
            </div>

            <Popup
                visible={this.state.showExecuteAllDate}
                dragEnabled={false}
                closeOnOutsideClick={false}                
                showCloseButton={false}
                showTitle={true}
                title="Виберіть дату"               
                width={300}
                height={280}>

                    <center>

                <Calendar
                    id="calendar-container"
                    //value={currentDate}
                    onValueChanged={this.onDateValueChanged.bind(this)} />

                    </center>

                {/* <Position
                    at="bottom"
                    my="center"
                    of={this.state.positionOf}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={this.emailButtonOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={this.closeButtonOptions}
                /> */}
        </Popup>


        </div>
        )


    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user: state.user
    }),
    dispatch => ({   
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }     
    })
  )(OfficeFuelReportComplex);