import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Pager, Paging } from 'devextreme-react/data-grid';
import Moment from 'moment';
import { NumberBox } from 'devextreme-react/number-box';
import { SelectBox } from 'devextreme-react';

const typeSelectStore = [
  {
    id: 1,
    name: "пальне та товари"
  },
  {
    id: 2,
    name: "тільки пальне"
  },
  {
    id: 3,
    name: "тільки товари"
  }
];

class OfficeGeneralReportNotActivityStation extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
          dataGrid : [],          
          minutesDelay: 5,
          typeSelect: 1
        }
    }

    onChangeDates(e) {
        this.setState({
            dateFrom : e.dateFrom,
            dateTo : e.dateTo             
        });
    }

    onChangeMinutesDelay(e) {
      this.setState({
        minutesDelay : e.value
      });
  }

  onChangeTypeSelect(e) {
    this.setState({
      typeSelect : e.value
    });
  }

  onLoad() {
    this.clearFilterDataGrid();
    this.onExecute();
  }

    onExecute() {     
        let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
        let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsGeneral/NotActivityStation?dateFrom=' + _datFrom + '&dateTo=' + _datTo + "&index=" + this.state.typeSelect + "&minDelay=" + this.state.minutesDelay,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);
          this.setState({
              dataGrid : response.data
          });            
       })
       .catch((error) => {
          this.props.onLoading(false);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    clearFilterDataGrid() {
      this.dataGrid.instance.clearFilter();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',            
            onClick: this.onExecute.bind(this),
            hint: 'Оновити'
          }},{
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'notequal',            
            onClick: this.clearFilterDataGrid.bind(this),
            hint: 'Очистити всі фільтри'
          }}, {
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: 'Завантажити',            
            onClick: this.onLoad.bind(this),
            hint: 'Завантажити дані'
          }});
      }

      onCalculateTimespan(e) {
        if(e.cntMinutes !== null && e.cntMinutes !== undefined) {
          return Moment(e.cntMinutes, 'HH:mm:ss').format('HH:mm:ss');
        }
      }

     render() {

      let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD_HHmmss');
      let _dateTo = Moment(this.state.dateTo).format('yyyyMMDD_HHmmss');

      return (

        <div style={{marginTop : "10px"}}>
            <div className="container-with-date">
                <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
                <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>від хвилин</span>
                      <NumberBox
                        ref={(ref) => this.tbMinutesDelay = ref} width="4rem"
                        defaultValue={this.state.minutesDelay}
                        showSpinButtons={true}
                        placeholder="вкажіть..."
                        onValueChanged={this.onChangeMinutesDelay.bind(this)} />                      
                  </div>
                </div>      
                <div id="datFromdatTo" style={{marginTop: "5px", marginLeft: "10px"}}>
                  <div className="label-date">
                      <span>тип активності</span>                                      
                      <SelectBox
                        ref={item => this.selbox = item}        
                        value={this.state.typeSelect}                        
                        valueExpr="id"                                        
                        deferRendering={false}     
                        width="10rem"               
                        displayExpr="name"
                        dataSource={typeSelectStore}
                        onValueChanged={this.onChangeTypeSelect.bind(this)} />      
                  </div>
                </div>    
            </div>
            <div style={{marginTop: "10px"}}>
            <DataGrid
              ref={(ref) => this.dataGrid = ref}
              id="gridReportNotActivityStation"              
              dataSource={this.state.dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              showBorders={true}        
              rowAlternationEnabled={true}
              onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
              filterRow={{applyFilter : true, visible : true}} >                            
              <Export enabled={true} fileName={'ReportNotActivityStation_' + _dateFrom + '_' + _dateTo} />           
              <StateStoring enabled={true} type="localStorage" storageKey="ReportNotActivityStation" />
              <Paging defaultPageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 200, 300]}
                showInfo={true} />                
              <Column dataField="terminalId" caption="ID АЗК" dataType="number" />
              <Column dataField="terminalName" caption="Назва АЗК" />  
              <Column dataField="prevData" caption="Початок бездії" dataType="datetime" />
              <Column dataField="dat" caption="Кінець бездії" dataType="datetime" />
              <Column dataField="cntMinutes" caption="Тривалість бездії" dataType="string" calculateCellValue={this.onCalculateTimespan.bind(this)}  />

            </DataGrid>
            </div>

          </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeGeneralReportNotActivityStation);