import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound } from './components/other/notFound';
import { NotAccess } from './components/other/notAccess';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Layout from './components/layout';
import { Home } from './components/home';
import Profile from './components/profile/profile';
import Settings from './components/settings/settings';
import { routesStore } from './stores/initStores';

import ManagerReports from './components/reports/manager/managerReports';
import OfficeReports from './components/reports/office/officeReports';

import uaMessages from './other/dx.messages.uk.json';
import { locale, loadMessages } from 'devextreme/localization';
import './components/custom.css';
import './components/other/loading-fullscreen.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.compact.css';
import './components/dx.custom.css';


loadMessages(uaMessages);
locale("uk");

const App = ({ store, history } ) => (  
    <Provider store={store}>   
    <ConnectedRouter history={history}>
         <Layout>
           <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path={routesStore[1].path} component={Profile} />
            <Route exact path={routesStore[2].path} component={Settings} />
            <Route path='/manager' component={ManagerReports} />  
            <Route path='/office' component={OfficeReports} />           
            <Route exact path='/404' component={NotFound} />
            <Route exact path='/403' component={NotAccess} />            
            <Redirect exact path='/fuels' push to='/'></Redirect>
            <Redirect exact path='/goods' push to='/'></Redirect>
            <Redirect exact path='/other' push to='/'></Redirect>
            <Redirect exact path='/general' push to='/'></Redirect>
            <Redirect exact path='/cards' push to='/'></Redirect>  
            <Redirect exact path='/talons' push to='/'></Redirect>           
            <Redirect exact path='/tspill' push to='/'></Redirect>           
            <Redirect push to='/404'></Redirect>
           </Switch>
         </Layout>
       </ConnectedRouter>
    </Provider>
)

App.propTypes = {
   store: PropTypes.object.isRequired
 }

export default App