import { _reportsStore } from "./reportsStore";
import { _routesStore } from "./routesStore";

export const authStore = {
    isAuth: false,
    isLoginShow: false,
    AccessToken : null,
    UserType : 0,
    RefreshToken: null,
    User : null,
    Issued : null,
    Expires : null,
}

export const userStore = {  
    id : 0,
    guid : null,
    name: null,
    displayName : null,
    userTypeId : 0,
    eMail : null,
    comment: null,
    password: null,
    isBlocked: 0,
    userProfile: undefined,
    userTerminals: [],
    lowDateTrashholdDays: 0,
    useActiveDirectoryCheck: false,
    activeDirectoryName: null   
}

export const userTabsStore = {
    profiles : [],
    userTypes : [],
    terminals : [] 
        //editCustomer : {}
}

export const profileTabsStore = {
    actions : []
    // userTypes : [],
    // terminals : [] 
}

export const reportsStore = _reportsStore;

export const userTypeStore = [
    {
        id : 400,
        name: 'Оператор АЗК'
    },
    {
        id : 300,
        name: 'Товарознавець'
    },
    {
        id : 200,
        name: 'Директор АЗК'
    },
    {
        id : 150,
        name: 'Регіональний директор'
    },
    {
        id : 100,
        name: 'Співробітник ЦО'
    },
    {
        id : 50,
        name: 'Служба безпеки'
    },
    {
        id : 20,
        name: 'Касовик'
    },
    {
        id : 10,
        name: 'Admin'
    },
    {
        id : 1,
        name: 'Root'
    }
]

export const settingsStore = {    
    titleMain: 'Smart Reports',
    isLoadingShow : false,
    params: {
        currTerminal : 0,
        adminProfileView : null
    }
}

export const routesStore = _routesStore;

export const enterpriseStore = [
    {
        enterpriseId: 0,
        enterpriseName: 'всі дані'
    },
    {
        enterpriseId: 1,
        enterpriseName: 'до EP'
    },
    {
        enterpriseId: 2,
        enterpriseName: 'тільки EP'
    },
    {
        enterpriseId: 3,
        enterpriseName: 'тільки IGK'
    },
    {
        enterpriseId: 4,
        enterpriseName: 'тільки ESP'
    },
    {
        enterpriseId: 5,
        enterpriseName: 'тільки OPT'
    }
];

export const taxiTransTypesStore = [
    {
    id: 0,
    name: "Видано в роздріб"
    },
    {
      id: -1,
      name: "Повернено з роздрібу"
    },
    {
      id: 1,
      name: "Збільшення залишку"
    },
    {
      id: 2,
      name: "Зменшення залишку"
    },
    {
      id: 5,
      name: "Розблоковано (повернено) АЗС"
    },
    {
      id: 6,
      name: "Заблоковано АЗС"
    },
    {
      id: 7,
      name: "Автоматичне списання"
    }          
];